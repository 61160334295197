import { Box, Button, IconButton as MuiIconButton } from '@common/components'
import { styled } from '@mui/material'

export const IconButton = styled(MuiIconButton)({
  position: 'absolute',
  bottom: 0,
  right: 0,
  width: '30px',
  height: '30px',
  borderRadius: '50% !important',
  backgroundColor: '#013D7B',
  border: '2px solid white',
  ':hover': {
    backgroundColor: '#013D7Baf',
  },
})

export const ImageContainer = styled(Box)({
  background: 'rgba(230,230,230,0.9)',
  width: '75px',
  height: '75px',
  borderRadius: '50% !important',
  position: 'relative',
})

export const ButtonCancel = styled(Button)({
  color: '#5556C7',
  borderColor: '#5556C7',
  borderRadius: '30px',
  width: '130px',
  ':hover': { borderColor: '#5556C7' },
})

export const ButtonSave = styled(Button)({
  margin: '5px',
  borderRadius: '30px',
  width: '130px',
  background: 'rgba(85, 86, 199, 0.5)',
  ':hover': {
    background: 'rgba(85, 86, 199, 0.6)',
  },
})

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})
