import { Container, Box, Skeleton, Grid } from '@common/components'
import { styled } from '@common/utils/styles'

const LoginPlaceholder = () => {
  return (
    <Container maxWidth='lg'>
      <Box
        sx={{
          mt: '110px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Skeleton width={300} height={60} />
        <Skeleton width={400} height={100} />
        <Skeleton width={400} height={100} />
        <Skeleton variant='text' width={200} />
        <Skeleton width={350} height={60} />
        <Skeleton variant='text' width={50} />
        <Skeleton width={350} height={60} />
      </Box>
    </Container>
  )
}

const GridItem = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export default LoginPlaceholder
