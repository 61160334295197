import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    discordCreateDiscordRole: build.mutation<
      DiscordCreateDiscordRoleApiResponse,
      DiscordCreateDiscordRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/discord/role`,
        method: 'POST',
        body: queryArg.createDiscordRoleRequest,
      }),
    }),
    discordGetDiscordRoles: build.query<
      DiscordGetDiscordRolesApiResponse,
      DiscordGetDiscordRolesApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/discord/roles` }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type DiscordCreateDiscordRoleApiResponse =
  /** status 201 Successful Response */ DiscordRoleResponse
export type DiscordCreateDiscordRoleApiArg = {
  createDiscordRoleRequest: CreateDiscordRoleRequest
}
export type DiscordGetDiscordRolesApiResponse =
  /** status 200 Successful Response */ DiscordRoleResponse[]
export type DiscordGetDiscordRolesApiArg = void
export type DiscordRoleResponse = {
  id: number
  name: string
  identifier: string
  language: string
  created_at: string
  updated_at: string
  learning: boolean
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type CreateDiscordRoleRequest = {
  name: string
  identifier: string
  language: string
  learning: boolean
}
export const {
  useDiscordCreateDiscordRoleMutation,
  useDiscordGetDiscordRolesQuery,
  useLazyDiscordGetDiscordRolesQuery,
} = injectedRtkApi
