import * as React from 'react'
const FigmaLockIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={21} fill='none' {...props}>
    <path
      fill='#E31D1C'
      d='M9.463 11.902a1.66 1.66 0 1 0-3.32 0 1.62 1.62 0 0 0 .39 1.06 1 1 0 0 1 .23 1l-.21.62a.93.93 0 0 0 .88 1.19h.74a.93.93 0 0 0 .88-1.23l-.25-.62a1 1 0 0 1 .23-1c.258-.279.41-.64.43-1.02Z'
    />
    <path
      fill='#E31D1C'
      fillRule='evenodd'
      d='M12.553 6.122v-3.12a3 3 0 0 0-3.11-3h-3.28a3 3 0 0 0-3.11 3v3.12a4.57 4.57 0 0 0-3 5l.95 5.77a4.85 4.85 0 0 0 4.86 3.88h3.88a4.85 4.85 0 0 0 4.88-3.91l.95-5.77a4.57 4.57 0 0 0-3.02-4.97Zm-8-3.12a1.55 1.55 0 0 1 1.61-1.48h3.28a1.55 1.55 0 0 1 1.61 1.48v2.77h-6.5v-2.77Zm8.25 13.56 1-5.79a2.55 2.55 0 0 0-.65-2.06 3.22 3.22 0 0 0-2.46-1.09h-5.78a3.22 3.22 0 0 0-2.46 1.07 2.55 2.55 0 0 0-.6 2.08l.95 5.79a3 3 0 0 0 3.06 2.36h3.88a3 3 0 0 0 3.06-2.36Z'
      clipRule='evenodd'
    />
  </svg>
)
export default FigmaLockIcon
