import * as Yup from 'yup'

// export const createEventNowSchema = [
//   Yup.object({}),
//   Yup.object({
//     language: Yup.mixed().required("You must choose an event language"),
//   }),
//   Yup.object({
//     title: Yup.string(),
//     description: Yup.string(),
//   }),
//   Yup.object({
//     private: Yup.boolean(),
//   }),
// ]

export const createEventNowSchema = Yup.object({
  event_language: Yup.string().required(),
  event_type: Yup.mixed().oneOf([
    'NONE',
    'GEO_GAME',
    'CAPTION_THIS',
    'HYPERBEAM',
    'PICTIONARY',
    'CHAT',
    'JKLM_FUN',
    'DISCUSSION',
    'WHITEBOARD',
  ]),
  proficiency: Yup.mixed().oneOf([0, 1, 2, 3, 4]),
  event_description: Yup.string(),
  start_time_utc: Yup.string(),
  end_time_utc: Yup.string(),
  event_title: Yup.string(),
  is_private: Yup.boolean(),
  is_revision: Yup.boolean(),
  tags: Yup.array(),
  isLive: Yup.string(),
})

export const createEventLaterSchema = [
  Yup.object({}),
  Yup.object({
    language: Yup.mixed().required('You must choose an event language'),
  }),
  Yup.object({
    date: Yup.mixed().required('Date is required'),
    time: Yup.mixed().required('Time is required'),
  }),
  Yup.object({
    title: Yup.string(),
    description: Yup.string(),
  }),
  Yup.object({
    private: Yup.boolean(),
  }),
]
