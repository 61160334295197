import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    musicboxGetMusic: build.query<MusicboxGetMusicApiResponse, MusicboxGetMusicApiArg>({
      query: () => ({ url: `/api/v1/extensions/musicbox/music` }),
    }),
    musicboxPlayMusic: build.mutation<MusicboxPlayMusicApiResponse, MusicboxPlayMusicApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/musicbox/play`,
        method: 'POST',
        body: queryArg.playMusicRequest,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type MusicboxGetMusicApiResponse = /** status 200 Successful Response */ MusicBoxDbModel[]
export type MusicboxGetMusicApiArg = void
export type MusicboxPlayMusicApiResponse = /** status 200 Successful Response */ any
export type MusicboxPlayMusicApiArg = {
  playMusicRequest: PlayMusicRequest
}
export type MusicBoxDbModel = {
  id: number
  name?: string | null
  description?: string | null
  thumbnail?: string | null
  link: string
  link_type: string
  is_public?: boolean
  creator_id?: number | null
  created_at: string
  updated_at: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type PlayMusicRequest = {
  song_id: number
}
export const {
  useMusicboxGetMusicQuery,
  useLazyMusicboxGetMusicQuery,
  useMusicboxPlayMusicMutation,
} = injectedRtkApi
