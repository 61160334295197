import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface chatState {
  readMessages: boolean
  open: boolean
}

const initialState: chatState = {
  readMessages: true,
  open: false,
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setReadMessages: (state, { payload }: PayloadAction<boolean>) => {
      state.readMessages = payload
    },
    setChatWindowOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.open = payload
      if (payload) state.readMessages = true
    },
  },
})

export const { setReadMessages, setChatWindowOpen } = chatSlice.actions

export default chatSlice.reducer
