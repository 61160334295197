import { Box, styled } from '@mui/material'

export const BackdropButton = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  transform: 'translate(-50%,-50%)',
  zIndex: 3,
})

export const BackdropButtonWithShadow = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  transform: 'translate(-50%,-50%)',
  boxShadow: theme.shadows[5],
  zIndex: -1,
}))

export const Container = styled('div')(({ theme, placement }) => {
  const placements = placement.split('-')
  return {
    position: 'relative',
    transform: `translate(${placements[1] === 'end' ? '20px' : '-20px'},${
      placements[0] === 'top' ? '20px' : '-20px'
    })`,
    paddingBottom: '2px',
    paddingTop: '20px',
    minWidth: 120,
    borderRadius: '15px',
    boxShadow: theme.shadows[5],
  }
})
