import { Container, Skeleton } from '@common/components'

const PaymentSuccessPlaceholder = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        mt: 15,
      }}
    >
      <Skeleton variant='circular' sx={{ height: '100px', width: '150px' }} />
      <Skeleton width={200} variant='text' sx={{ mt: 2, mb: 1 }} />
      <Skeleton variant='rectangular' width={400} height={80} />
      <Skeleton variant='rounded' width={150} height={30} sx={{ borderRadius: '30px', mt: 2 }} />
    </Container>
  )
}
export default PaymentSuccessPlaceholder
