import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    affiliateGetCoupons: build.query<AffiliateGetCouponsApiResponse, AffiliateGetCouponsApiArg>({
      query: () => ({ url: `/api/v1/extensions/affiliate/coupons` }),
    }),
    affiliateGetAffiliates: build.query<
      AffiliateGetAffiliatesApiResponse,
      AffiliateGetAffiliatesApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/affiliate/affiliate` }),
    }),
    affiliateCreateAffiliate: build.mutation<
      AffiliateCreateAffiliateApiResponse,
      AffiliateCreateAffiliateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/affiliate/affiliate`,
        method: 'POST',
        body: queryArg.createAffiliateRequest,
      }),
    }),
    affiliateGetAffiliate: build.query<
      AffiliateGetAffiliateApiResponse,
      AffiliateGetAffiliateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/affiliate/affiliate/${queryArg.affiliateId}`,
      }),
    }),
    affiliateDeleteAffiliate: build.mutation<
      AffiliateDeleteAffiliateApiResponse,
      AffiliateDeleteAffiliateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/affiliate/affiliate/${queryArg.affiliateId}`,
        method: 'DELETE',
      }),
    }),
    affiliateApplyPromotionCode: build.mutation<
      AffiliateApplyPromotionCodeApiResponse,
      AffiliateApplyPromotionCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/affiliate/affiliate/apply`,
        method: 'POST',
        body: queryArg.applyPromotionRequest,
      }),
    }),
    affiliateGetPromotions: build.query<
      AffiliateGetPromotionsApiResponse,
      AffiliateGetPromotionsApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/affiliate/promotions` }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type AffiliateGetCouponsApiResponse = /** status 200 Successful Response */ CouponResponse[]
export type AffiliateGetCouponsApiArg = void
export type AffiliateGetAffiliatesApiResponse =
  /** status 200 Successful Response */ GetAffiliateResponse[]
export type AffiliateGetAffiliatesApiArg = void
export type AffiliateCreateAffiliateApiResponse =
  /** status 201 Successful Response */ CreateAffiliateResponse
export type AffiliateCreateAffiliateApiArg = {
  createAffiliateRequest: CreateAffiliateRequest
}
export type AffiliateGetAffiliateApiResponse = /** status 200 Successful Response */ any
export type AffiliateGetAffiliateApiArg = {
  affiliateId: number
}
export type AffiliateDeleteAffiliateApiResponse = /** status 200 Successful Response */ any
export type AffiliateDeleteAffiliateApiArg = {
  affiliateId: number
}
export type AffiliateApplyPromotionCodeApiResponse = /** status 201 Successful Response */ any
export type AffiliateApplyPromotionCodeApiArg = {
  applyPromotionRequest: ApplyPromotionRequest
}
export type AffiliateGetPromotionsApiResponse =
  /** status 200 Successful Response */ GetUsePromotionResponse[]
export type AffiliateGetPromotionsApiArg = void
export type CouponResponse = {
  id: number
  coupon_type: string
  coupon_value: number
}
export type CouponTargetEnum = 'subscription' | 'lesson'
export type GetCouponResponse = {
  id: number
  coupon_type: string
  coupon_value: number
}
export type GetPromotionResponse = {
  id: number
  username?: string | null
}
export type GetAffiliateResponse = {
  id: number
  user_limit?: number | null
  expiration_date?: string | null
  target: CouponTargetEnum
  coupon?: GetCouponResponse | null
  promotions?: GetPromotionResponse[] | null
  use_count: number
  code: string
  created_at: string
}
export type CreateAffiliateResponse = {
  id: number
  coupon_id: number
  user_limit?: number | null
  expiration_date?: string | null
  target: CouponTargetEnum
  code: string
  company_name?: string | null
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type CreateAffiliateRequest = {
  coupon_id: number
  user_limit?: number | null
  expiration_date?: string | null
  target: CouponTargetEnum
  company_name?: string | null
}
export type ApplyPromotionRequest = {
  promotion_code: string
}
export type UserCouponResponse = {
  id: number
  coupon: GetCouponResponse
  target: CouponTargetEnum
  code: string
}
export type GetUsePromotionResponse = {
  id: number
  coupon_user: UserCouponResponse
}
export const {
  useAffiliateGetCouponsQuery,
  useLazyAffiliateGetCouponsQuery,
  useAffiliateGetAffiliatesQuery,
  useLazyAffiliateGetAffiliatesQuery,
  useAffiliateCreateAffiliateMutation,
  useAffiliateGetAffiliateQuery,
  useLazyAffiliateGetAffiliateQuery,
  useAffiliateDeleteAffiliateMutation,
  useAffiliateApplyPromotionCodeMutation,
  useAffiliateGetPromotionsQuery,
  useLazyAffiliateGetPromotionsQuery,
} = injectedRtkApi
