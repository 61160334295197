import { Button as ButtonMui, Paper as PaperMui, styled, Typography } from '@mui/material'

export const TypographyMessage = styled(Typography)({
  color: '#BFBFBF',
  flex: '1',
})

export const TypographyDate = styled(Typography)({
  paddingTop: '5px',
  width: '50px',
  minWidth: '50px',
})

export const Button = styled(ButtonMui)({
  display: 'block',
  marginLeft: 'auto',
})

export const Paper = styled(PaperMui)({
  maxWidth: '350px',
  padding: '8px',
  borderRadius: '15px',
})
