import { useEventDeleteEventMutation } from '@app/services'
import { useToggle, useTranslation } from '@common/hooks'
import { Button } from '@mui/material'
import { Modal, Typography, Stack } from '@common/components'
import { CancelBtn, ConfirmBtn, ModalContainer } from './cancel-event.styles'
import { useNavigate } from 'react-router-dom'

const CancelEvent = (props) => {
  const { eventId } = props
  const { open, handleClose: onClose, handleOpen: onOpen } = useToggle(false)
  const [cancelEvent, { isSuccess, isLoading }] = useEventDeleteEventMutation()
  const handleCancelEvent = () => {
    cancelEvent({ eventId: eventId })
  }
  const { t } = useTranslation()

  return (
    <>
      <Button onClick={onOpen} color='neutral' sx={{ color: 'black' }} size='xsmall'>
        {t('cancelEvent.cancel', 'Cancel event')}
      </Button>
      <Modal
        open={open}
        onClose={onClose}
        paperProps={{
          sx: {
            borderRadius: '20px',
            overflow: 'hidden',
            maxWidth: '95%',
            width: '500px',
          },
        }}
      >
        {isSuccess ? (
          <SuccessMsg />
        ) : (
          <ModalContainer>
            <Typography>Are you sure you want to cancel the event?</Typography>
            <Stack sx={{ my: 2 }} direction='row' spacing={2}>
              <CancelBtn variant='contained' onClick={onClose}>
                {t('cancelEvent.no', 'No')}
              </CancelBtn>
              <ConfirmBtn loading={isLoading} variant='contained' onClick={handleCancelEvent}>
                {t('cancelEvent.yes', 'Yes')}
              </ConfirmBtn>
            </Stack>
          </ModalContainer>
        )}
      </Modal>
    </>
  )
}

const SuccessMsg = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleClick = () => {
    // navigate(HOME + "?create=newEvent")
  }

  return (
    <ModalContainer>
      <Typography variant='h6'>
        {t('cancelEvent.canceledEvent', 'Your event has been cancelled!')}
      </Typography>
      <Typography sx={{ color: '#738598' }}>
        {' '}
        {t('cancelEvent.okay', 'We hope everythings is okay')}
      </Typography>
      <Stack sx={{ my: 2 }} direction='row' alignItems='center' spacing={5}>
        <Typography variant='caption'>
          {' '}
          {t('cancelEvent.canCreate', 'You can always create a new one')}
        </Typography>
        <Button disabled onClick={handleClick}>
          {t('cancelEvent.create', 'Create New event')}
        </Button>
      </Stack>
    </ModalContainer>
  )
}
export default CancelEvent
