import { Typography, styled, Box } from '@mui/material'

export const StepsWrapper = styled(Box)({
  display: 'flex',
})
export const StepsContainer = styled(Box)({
  display: 'flex',
  marginLeft: 'auto',
})
export const StepTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'active',
})((props) => ({
  background: props.active && '#0F82FF',
  fontFamily: 'Poppins',
  fontSize: '20px',
  fontWeight: 700,
  padding: '10.808px 12.352px',
  borderRadius: '7px',
  lineHeight: '26px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: props.active ? '#FFFFFF' : props.theme.palette.mode === 'dark' ? '#BBBBBB' : '#8E8E8E',
}))
