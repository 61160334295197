import { Container, Box, Skeleton } from '@common/components'

const InventoryPlaceholder = () => {
  return (
    <Container maxWidth='xs'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Skeleton width={300} height={60} />
        <Skeleton width={400} height={100} />
        <Skeleton width={400} height={100} />
        <Skeleton variant='text' width={200} />
        <Skeleton width={350} height={60} />
        <Skeleton variant='text' width={50} />
        <Skeleton width={350} height={60} />
      </Box>
    </Container>
  )
}

export default InventoryPlaceholder
