const isSupportedBrowser = () => {
  // only Chrome and Firefox are supported
  const ua = window.navigator.userAgent
  const isChrome = /Chrome/.test(ua) || /Edge/.test(ua)
  const isFirefox = /Firefox/.test(ua)
  const isSafari = /Safari/.test(ua)
  return isChrome || isFirefox || isSafari
}

export default isSupportedBrowser
