import { ArrowForwardIosSharpIcon } from '@common/icons'
import { Typography } from '@common/components'
import { Accordion, AccordionDetails, AccordionSummary } from './question-accordion.styles'

interface QuestionAccordionProps {
  question: {
    question: string | React.ReactNode
    answer: string | React.ReactNode
  }
  color?: string
}

export const QuestionAccordion = (props: QuestionAccordionProps) => {
  const { question, color = '#fff' } = props
  return (
    <Accordion disableGutters elevation={0} square sx={{ borderColor: color }}>
      <AccordionSummary
        expandIcon={
          <ArrowForwardIosSharpIcon
            sx={{
              color: color,
              fontSize: '0.9rem',
              transform: 'rotate(90deg)',
            }}
          />
        }
      >
        <Typography variant='h7' sx={{ color: color, fontWeight: 600 }}>
          {question.question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ color: `${color} !important` }}>{question.answer}</AccordionDetails>
    </Accordion>
  )
}
