import React, { Children, MouseEventHandler } from 'react'
import { Box, Typography, IconButton, CardActionArea, Stack, CardProps } from '@common/components'
import { CardWrapper, Header, StyledChip } from './event-card.styles'
import { resolveProficiency, Proficiency } from '@common/utils/resolve-proficiency'
import { ModeEditOutlineOutlinedIcon, OnlinePredictionIcon, ShareOutlinedIcon } from '@common/icons'
import { alpha } from '@common/utils/styles'

type EventCardProps = CardProps & {
  title?: string
  host: {
    name: string
    image?: string
  }
  language?: string
  level?: Proficiency
  background?: string
  color?: string
  tags?: string[]
  status: 'live' | 'upcoming'
  fullWidth?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  onDetailsClick?: MouseEventHandler<HTMLButtonElement>
  onShareClick?: MouseEventHandler<HTMLButtonElement>
  component?: any
  to?: string
  clickable?: boolean
}

const EventCard = React.forwardRef<HTMLDivElement, EventCardProps>((props, ref) => {
  const {
    host,
    status,
    color = '#fff',
    background = 'linear-gradient(#b651ff,#6E51FF)',
    title,
    language,
    level,
    tags,
    fullWidth = false,
    onClick,
    onDetailsClick,
    onShareClick,
    clickable = true,
    ...restProps
  } = props

  return (
    <CardWrapper fullWidth={fullWidth} background={background} ref={ref} {...restProps}>
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Header sx={{ position: 'absolute', top: 0, left: 0, p: 1, zIndex: 200 }}>
          {status === 'live' && (
            <Stack direction='row' gap={1} alignItems='center'>
              <OnlinePredictionIcon sx={{ color }} />
              <Typography
                sx={{
                  color,
                }}
                variant='body2'
              >
                Live
              </Typography>
            </Stack>
          )}
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            sx={{ width: '100%' }}
          >
            {/* TODO ADD DATE LAST OPENED */}
            <Stack direction={'row'} alignItems={'center'}>
              {onDetailsClick !== undefined && (
                <IconButton onClick={onDetailsClick}>
                  <ModeEditOutlineOutlinedIcon sx={{ color }} />
                </IconButton>
              )}
              {onShareClick !== undefined && (
                <IconButton onClick={onShareClick}>
                  <ShareOutlinedIcon sx={{ color }} />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Header>
        <ControlledCardActionArea
          onClick={onClick}
          sx={{ px: '20px', pb: 2, pt: 6, height: '100%' }}
          clickable={clickable}
        >
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography variant='body1' fontWeight={500} fontSize={20} color={color} align='left'>
              {title}
            </Typography>
            <Stack
              direction='row'
              justifyContent='flex-end'
              alignItems='center'
              sx={{ width: '100%' }}
            >
              <Typography
                variant='body1'
                sx={{
                  fontWeight: '550',
                  textTransform: 'capitalize',
                  maxWidth: 250,
                  color: alpha(color, 0.7),
                }}
                noWrap
              >
                {host.name}
              </Typography>
            </Stack>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', flex: 1 }}>
              <Stack direction='row' gap={0.5} sx={{ mt: 2, flexWrap: 'wrap' }}>
                <StyledChip
                  background='#604DD0'
                  label={language}
                  sx={{ border: '1px solid white' }}
                />
                {level !== undefined && (
                  <StyledChip
                    background='#313A71'
                    sx={{ color: 'white' }}
                    label={<span style={{ color: '#fff' }}>{resolveProficiency(level)} </span>}
                  />
                )}
                {Array.isArray(tags) &&
                  tags.map((elem, k) => (
                    <StyledChip
                      key={k}
                      background='rgba(255, 255, 255, 0.8)'
                      sx={{ boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.35)' }}
                      label={<span style={{ color: '#000' }}>{elem} </span>}
                    />
                  ))}
              </Stack>
            </Box>
          </Box>
        </ControlledCardActionArea>
      </Box>
    </CardWrapper>
  )
})

EventCard.displayName = 'EventCard'

export default EventCard

const ControlledCardActionArea = (props) => {
  const { clickable, children, ...restProps } = props
  if (!clickable) return <Box sx={{ px: '20px', pb: 2, pt: 6, height: '100%' }}>{children}</Box>
  else return <CardActionArea {...restProps}>{children}</CardActionArea>
}
