import { useUser } from '@features/auth/hooks'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  useEventParticipateInEventMutation,
  useEventDeleteParticipationInEventMutation,
  useEventStartNowMutation,
  useEventGetEventConfigQuery,
  eventApi,
  EventResponse,
} from '@app/services'
import {
  Avatar,
  Box,
  Button,
  Container,
  Countdown,
  Grid,
  ShareLink,
  Stack,
  Typography,
} from '@common/components'
import Banner from './banner'
import {
  HeaderContainer,
  InformationTypography,
  ModeTypography,
  ProgressBar,
} from './loading-screen.styles'
import {
  DASHBOARD_ROUTE,
  EVENT_ROUTE,
  MY_CLASSROOMS_ROUTE,
  NOTES_ROUTE,
} from '@common/constants/routes'
import { useToast } from '@features/toast/hooks'
import { useAppDispatch, useMediaQuery } from '@common/hooks'
import { lazy } from 'react'
import moment from 'moment'
import CancelEvent from './cancel-event'
import { useLanguages } from '@features/languages/hooks'
import PlayersLobby from './players-lobby'
import { handleGravatar } from '@common/utils/gravatar'
const Illustration = lazy(() => import('./illustration'))

const LoadingScreen = (props) => {
  const { type, event } = props
  const { user, isStudent } = useUser()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  //   const { event, config, statusStartEvent } = useSelector(
  //     (state) => state.currentEvent
  //   )
  //   const { statusParticipateInEvent, statusCancelParticipation } = useSelector(
  //     (state) => state.events
  //   )
  const { t } = useTranslation()
  const [participateInEvent, { isLoading: isLoadingParticipateInEvent }] =
    useEventParticipateInEventMutation()
  const [cancelParticipation, { isLoading: isLoadingCancelParticipant }] =
    useEventDeleteParticipationInEventMutation()
  const [startEvent, { isLoading: isLoadingStartEvent }] = useEventStartNowMutation()
  const { data: config } = useEventGetEventConfigQuery({ eventId: event?.event_id })
  const handleParticipate = async () => {
    const patchResult = dispatch(
      eventApi.util.updateQueryData(
        'eventGetEvents',
        {
          isDashboard: false,
        },
        (draft) => {
          draft.events
            ?.find((e) => e.event_id === event.event_id)
            ?.participants.push({ id: user.id, username: user.username, image: user.image }) // Add user to participants list
        },
      ),
    )
    try {
      await participateInEvent({ eventId: event.event_id }).unwrap()
    } catch {
      patchResult.undo()
    }
  }
  const handleCancelParticipation = async () => {
    const patchResult = dispatch(
      eventApi.util.updateQueryData(
        'eventGetEvents',
        {
          isDashboard: false,
        },
        (draft) => {
          const targetEvent = draft.events?.find((e) => e.event_id === event.event_id)
          const filtredEvent = draft.events?.filter((e) => e.event_id !== event.event_id)
          draft.events = [
            ...filtredEvent,
            {
              ...targetEvent,
              participants: targetEvent?.participants.filter(
                (participant) => participant.id !== user.id,
              ),
            },
          ]
        },
      ),
    )
    try {
      await cancelParticipation({ eventId: event.event_id }).unwrap()
    } catch {
      patchResult.undo()
    }
  }

  const handleStartEvent = () => {
    startEvent({ eventId: event.event_id })
  }

  if (type === 'instant') {
    return (
      <Layout
        headerTitle={t('game-loading-screen.mode.text', 'Multiplayer mode')}
        lobbyTitle={t('game-loading-screen.lobby.title', 'Waiting for other players')}
        eventId={event.event_id}
        users={event.users}
        minNumberUsers={config?.min_number_users}
      >
        <Stack>
          <Banner
            title={
              <Typography
                variant='h4'
                sx={{
                  fontFamily: 'ReportRegular',
                  color: '#fff',
                  fontWeight: 700,
                  lineHeight: '35px',
                  transform: 'rotate(4deg)',
                }}
              >
                {t('loadingScreen.time', "It's time for")}
                <br />
                {t('loadingScreen.immersion', 'immersion')}
              </Typography>
            }
            subtitle={t(
              'game-loading-screen.ellipse-body.info',
              ' Automatch finds the most appropriate room according to your language profile.',
            )}
            sx={{ flex: 1 }}
          />
          <InformationTypography variant='body1'>
            {t(
              'game-loading-screen.information.part1',
              'Sometimes you will be hosting in your native language,',
            )}
            <br />
            {t(
              'game-loading-screen.information.part2',
              'and sometimes you will be learning your target language.',
            )}
          </InformationTypography>
        </Stack>
      </Layout>
    )
  }

  if (type === 'scheduled') {
    return (
      <Layout
        headerTitle={
          <Button
            onClick={() => navigate(MY_CLASSROOMS_ROUTE)}
            variant='text'
            color='neutral'
            sx={{ mt: 1, ml: 'auto', width: '120px', display: 'block', color: 'black' }}
          >
            {t('loadingScreen.exit', 'Exit')}
          </Button>
        }
        lobbyTitle={'Participants'}
        eventId={event.short_event_id ? event.short_event_id : event.event_id}
        users={event.participants}
        illustrationChildren={
          <UpcomingEventDetails
            title={event.title}
            host={event.host}
            language={event.event_language}
            start_time={moment.utc(event.start_time_utc, 'YYYY-MM-DDTHH:mm:ss').toDate()}
          />
        }
        minNumberUsers={config?.min_number_users}
        maxNumberUsers={config?.max_number_users}
      >
        {event.host.id === user.id ? (
          <Stack spacing={1} sx={{ alignItems: 'center' }}>
            <Typography variant='h5' color='primary'>
              {t('loadingScreen.hosting', 'You are hosting this event')}
            </Typography>
            <Typography variant='body1' color='black'>
              {t('loadingScreen.scheduledTime', 'Please return at the sheduled time')}
            </Typography>
            {/* TODO EDIT EVENT */}
            {/* TODO MODAL TO CANCEL EVENT */}
            <CancelEvent eventId={event.event_id} />
            <Button variant='contained' onClick={handleStartEvent} loading={isLoadingStartEvent}>
              {t('loadingScreen.startNow', 'Start event now')}
            </Button>
          </Stack>
        ) : event.participants.find((participant) => participant.id === user.id) ? (
          <Stack spacing={1} sx={{ alignItems: 'center' }}>
            <Typography variant='h5' color='primary'>
              {t('loadingScreen.going', 'You are going to this event')}
            </Typography>
            <Typography variant='body1' color='black'>
              {t('loadingScreen.return', 'Please return at the sheduled time')}
            </Typography>
            <Button
              onClick={handleCancelParticipation}
              loading={isLoadingCancelParticipant}
              color='neutral'
              sx={{ color: 'initial' }}
            >
              {t('loadingScreen.cancel', 'Cancel participation')}
            </Button>
          </Stack>
        ) : (
          <Button
            loading={isLoadingParticipateInEvent}
            variant='contained'
            onClick={handleParticipate}
            sx={{ textTransform: 'initial' }}
          >
            {t('loadingScreen.participate', 'I want to participate')}
          </Button>
        )}
      </Layout>
    )
  }
  return null
}

const Layout = (props) => {
  const {
    children,
    illustrationChildren,
    headerTitle,
    lobbyTitle,
    eventId,
    users,
    minNumberUsers,
    maxNumberUsers,
  } = props
  const matches = useMediaQuery(900)
  const toast = useToast()
  const { t } = useTranslation()
  const handleCopySuccess = () => {
    toast(t('game-loading-screen.alert.success', " The game's code has been copied "), 'success')
  }
  return (
    <Grid container height='100vh'>
      {!matches && (
        <Grid
          item
          xs={5}
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Illustration />
          {illustrationChildren}
        </Grid>
      )}
      <Grid
        item
        xs={matches ? 12 : 7}
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', background: '#fff' }}
      >
        {/* <ProgressBar /> */}
        <Container maxWidth='md' sx={{ flex: 1 }}>
          <Stack sx={{ height: '100%' }}>
            {/* <HeaderContainer> */}
            <ModeTypography variant='body1' noWrap>
              {headerTitle}
            </ModeTypography>
            {/* <ShareLink
                label='Copy'
                link={`${window.location.origin}/classroom/${eventId}`}
                onCopy={handleCopySuccess}
                sx={{ maxWidth: 400 }}
              /> */}
            {/* </HeaderContainer> */}
            <Stack
              sx={{
                flex: 1,
                py: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {matches && <Box sx={{ mb: 2 }}>{illustrationChildren}</Box>}
              {children}
            </Stack>
            {/* <PlayersLobby
              title={lobbyTitle}
              users={users}
              minNumberUsers={minNumberUsers}
              maxNumberUsers={maxNumberUsers}
            /> */}
          </Stack>
        </Container>
      </Grid>
    </Grid>
  )
}

const UpcomingEventDetails = (props) => {
  const { title, host, language, start_time } = props
  const diffDays = moment(start_time).diff(moment(), 'days', true)
  const { getLanguageFromCode } = useLanguages()

  return (
    <Stack
      sx={{
        alignItems: 'center',
        background: '#EAEAEA',
        color: 'black',
        borderRadius: '20px',
        p: 3,
      }}
    >
      <Typography
        sx={{
          width: '100%',
          textDecoration: 'underline',
          textUnderlineOffset: '10px',
          mb: '17px',
        }}
      >
        {getLanguageFromCode(language)?.name} room
      </Typography>
      <Stack
        direction='row'
        sx={{ justifyContent: 'center', alignItems: 'center', mb: 1 }}
        spacing={1}
      >
        <Avatar src={handleGravatar(host?.image)} size={'large'} variant='circular' />
        <Typography>{host?.username}</Typography>
      </Stack>
      {title && (
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '32.2746px',
            lineHeight: '48px',
          }}
        >
          “{title}”
        </Typography>
      )}
      {diffDays < 1 && diffDays > 0 && (
        <Box mb={1}>
          <Typography color='#738598' textAlign='center' sx={{ mb: 0.5 }}>
            Starting In
          </Typography>
          <Countdown startTime={start_time} sx={{ mx: 'auto' }} />
        </Box>
      )}
      <Typography fontWeight='bold' color='#738598' align='center'>
        Scheduled for {moment(start_time).local().format('DD/MM/YYYY HH:mm')}
      </Typography>
    </Stack>
  )
}

// LoadingScreen.propTypes = {
//   type: PropTypes.oneOf(["instant", "scheduled"]),
// }

export default LoadingScreen
