import { defer } from '@lib/router'
import { store } from '@app/store'
import { modulesApi } from '@app/services'

const notificationLoader = async () => {
  const emailModule = await promise()
  return defer({ emailModule })
}

const promise = async () => {
  const state = store.getState()
  await new Promise<void>((resolve) => {
    if (state.auth.tokens) {
      resolve()
    }
    const unsubscribe = store.subscribe(() => {
      const state = store.getState()
      console.log(state.auth.tokens)
      if (state.auth.tokens) {
        unsubscribe()
        resolve()
      }
    })
  })

  const { data: emailModule } = await store.dispatch(
    modulesApi.endpoints.modulesGetUserModuleResults.initiate({
      moduleId: 'EmailPreferenceModule',
      limit: 1,
      orderBy: 'createdAt_DESC',
    }),
  )
  return emailModule
}

export default notificationLoader
