import * as React from 'react'
import { SVGProps } from 'react'
const VocabIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={26} height={24} fill='none' {...props}>
    <path
      stroke={props.color ? props.color : '#fff'}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2.313}
      d='m24.403 10.295-1.104 4.56c-.947 3.938-2.817 5.53-6.332 5.203a12.198 12.198 0 0 1-1.825-.294l-1.893-.437C8.551 18.247 7.097 16 8.201 11.44l1.105-4.57c.225-.928.495-1.735.833-2.4 1.319-2.64 3.56-3.35 7.324-2.488l1.881.425c4.721 1.07 6.163 3.328 5.059 7.888Z'
    />
    <path
      stroke={props.color ? props.color : '#fff'}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2.313}
      d='M16.968 20.065c-.698.458-1.577.84-2.647 1.178l-1.78.568c-4.473 1.396-6.828.229-8.281-4.102l-1.443-4.31C1.375 9.07 2.57 6.779 7.042 5.383l1.78-.568c.462-.142.902-.262 1.319-.338-.338.666-.609 1.473-.834 2.4l-1.104 4.571c-1.104 4.56.35 6.807 5.047 7.887l1.893.437c.654.152 1.262.25 1.825.294ZM14.24 8.219l5.464 1.342M13.132 12.437l3.267.807'
    />
  </svg>
)
export default VocabIcon
