import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    translateTranslateString: build.mutation<
      TranslateTranslateStringApiResponse,
      TranslateTranslateStringApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/translation/translate`,
        method: 'POST',
        body: queryArg.translateTextRequest,
      }),
    }),
    translateGetTranslateLanguages: build.query<
      TranslateGetTranslateLanguagesApiResponse,
      TranslateGetTranslateLanguagesApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/translation/languages` }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type TranslateTranslateStringApiResponse =
  /** status 200 Successful Response */ TranslateResponse
export type TranslateTranslateStringApiArg = {
  translateTextRequest: TranslateTextRequest
}
export type TranslateGetTranslateLanguagesApiResponse =
  /** status 200 Successful Response */ TranslateLanguagesResponse[]
export type TranslateGetTranslateLanguagesApiArg = void
export type TranslateResponse = {
  translated_text: string
  detected_source_language?: string | null
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type TranslateTextRequest = {
  source_language?: string | null
  destination_language: string
  text: string
}
export type TranslateLanguagesResponse = {
  language_code: string
  display_name: string
  supported_source: boolean
  supported_target: boolean
}
export const {
  useTranslateTranslateStringMutation,
  useTranslateGetTranslateLanguagesQuery,
  useLazyTranslateGetTranslateLanguagesQuery,
} = injectedRtkApi
