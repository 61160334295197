import { Controller } from '@lib/form'
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers'
import React from 'react'

type ControllerTimePickerProps = TimePickerProps & {
  control?: any
}

const ControllerTimePicker: React.FC<ControllerTimePickerProps> = React.forwardRef((props, ref) => {
  const { name, control, ...restProps } = props
  if (Boolean(control) && typeof name === 'string') {
    return (
      <Controller
        name={name}
        control={control}
        render={(args) => {
          return <TimePicker {...restProps} {...args.field} ref={ref} />
        }}
      />
    )
  }
  return <TimePicker name={name} {...restProps} ref={ref} />
})

ControllerTimePicker.displayName = 'ControllerTimePicker'

export default ControllerTimePicker
export * from '@mui/x-date-pickers/TimePicker'
