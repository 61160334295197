import { Box, Button as ButtonMui } from '@common/components'
import { styled } from '@common/utils/styles'

export const VideoContainer = styled(Box)({
  width: '480px',
  maxWidth: '100%',
  height: '270px',
  borderRadius: '11px',
  background: 'rgba(230,230,230,0.7)',
  overflow: 'hidden',
  position: 'relative',
})

export const FlotingButton = styled(ButtonMui, {
  shouldForwardProp: (prop) => prop !== 'isPremium',
})(({ isPremium }) => ({
  textTransform: 'none',
  margin: '0 auto',
  display: 'flex',
  width: '200px',
  maxWidth: '100%',
  fontWeight: 'bold',
  padding: '10px 18px',
  fontSize: '16px',
  borderRadius: '30px',
  color: 'black',
  background: isPremium ? '#FED363' : 'white',
  transition: 'all .2s ease-in-out',
  transform: 'translate(5px, -5px)',
  border: 'none',
  boxShadow: isPremium
    ? 'inset 0px 0px 0px 0px #665428, -4px 4px 0 0 #665428'
    : 'inset 0px 0px 0px 1px #0c0f13, -4px 4px 0 0 #0c0f13',
  ':hover ': {
    transform: 'translate(0px, 0px)',
    boxShadow: 'inset 0px 0px 0px 0px #0c0f13, 0px 0px 3px 0 #0c0f13',
    background: isPremium ? '#FED363' : 'white',
  },
}))
