import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    modulesSubmitModuleResult: build.mutation<
      ModulesSubmitModuleResultApiResponse,
      ModulesSubmitModuleResultApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/modules/module/submit`,
        method: 'POST',
        body: queryArg.moduleResult,
      }),
    }),
    modulesSubmitModuleResultUnauth: build.mutation<
      ModulesSubmitModuleResultUnauthApiResponse,
      ModulesSubmitModuleResultUnauthApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/modules/module/unauth/submit`,
        method: 'POST',
        body: queryArg.moduleResult,
      }),
    }),
    modulesGetUserModules: build.query<
      ModulesGetUserModulesApiResponse,
      ModulesGetUserModulesApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/modules/user` }),
    }),
    modulesGetUserModuleResults: build.query<
      ModulesGetUserModuleResultsApiResponse,
      ModulesGetUserModuleResultsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/modules/module/results`,
        params: {
          module_id: queryArg.moduleId,
          limit: queryArg.limit,
          offset: queryArg.offset,
          order_by: queryArg.orderBy,
          order: queryArg.order,
        },
      }),
    }),
    modulesGetModuleByModuleId: build.query<
      ModulesGetModuleByModuleIdApiResponse,
      ModulesGetModuleByModuleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/modules/module/${queryArg.moduleId}`,
        params: { language: queryArg.language },
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type ModulesSubmitModuleResultApiResponse =
  /** status 201 Successful Response */ SubmitModuleResultResponse
export type ModulesSubmitModuleResultApiArg = {
  moduleResult: ModuleResult
}
export type ModulesSubmitModuleResultUnauthApiResponse =
  /** status 201 Successful Response */ SubmitModuleResultResponse
export type ModulesSubmitModuleResultUnauthApiArg = {
  moduleResult: ModuleResult
}
export type ModulesGetUserModulesApiResponse =
  /** status 200 Successful Response */ UserModulesResponse
export type ModulesGetUserModulesApiArg = void
export type ModulesGetUserModuleResultsApiResponse =
  /** status 200 Successful Response */ ModuleResultDbModel[]
export type ModulesGetUserModuleResultsApiArg = {
  moduleId?: string | null
  limit?: number | null
  offset?: number | null
  orderBy?: string | null
  order?: string | null
}
export type ModulesGetModuleByModuleIdApiResponse =
  /** status 200 Successful Response */ ModuleInDb | null
export type ModulesGetModuleByModuleIdApiArg = {
  moduleId: string
  language?: any
}
export type SubmitModuleResultResponse = {
  id: number
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type ModuleResult = {
  id: string
  data: object
}
export type UserModulesResponse = {
  required: string[]
  submitted: string[]
  optional: string[]
}
export type ModuleResultDbModel = {
  id: number
  module_id: number
  module_result?: object | null
  validator_user_id?: number | null
  created_at?: string
  updated_at?: string
}
export type ModuleInDb = {
  id: number
  module_id: string
  config: object
  config_validator: object
  module_validator: object
}
export const {
  useModulesSubmitModuleResultMutation,
  useModulesSubmitModuleResultUnauthMutation,
  useModulesGetUserModulesQuery,
  useLazyModulesGetUserModulesQuery,
  useModulesGetUserModuleResultsQuery,
  useLazyModulesGetUserModuleResultsQuery,
  useModulesGetModuleByModuleIdQuery,
  useLazyModulesGetModuleByModuleIdQuery,
} = injectedRtkApi
