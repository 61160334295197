export const unpaidFakeFeedback = {
  id: 100,
  audio_url:
    'https://recordings-pengguin-prod.s3.amazonaws.com//tmp/tmpgzl2dw91.wav_chunk_907689.wav_correct_pronunciation.mp3',
  processed_audio_url:
    'https://recordings-pengguin-prod.s3.amazonaws.com//tmp/tmpgzl2dw91.wav_chunk_907689.wav_correct_pronunciation.mp3',
  transcript_url:
    'https://recordings-pengguin-prod.s3.amazonaws.com/95a44a6a1e101e3b13a5f191450d40f4de56a494aec8e8c3c0bae2cc539adf40.srt',
  event_id: 100,
  lesson_exist: false,
  language: null,
  duration: 100,
  processed: true,
  chunks: [
    {
      id: 10,
      start_time: 0.92,
      end_time: 1.24,
      comments: [
        {
          id: 985014,
          comment: 'Unlock premium features by upgrading your account.',
          audio_comment_url: null,
          is_ai: true,
          user: null,
          likes: {
            count: 0,
            user_ids: [],
          },
          dislikes: {
            count: 0,
            user_ids: [],
          },
          created_at: '2024-07-28T14:30:00.320261',
          updated_at: '2024-07-28T14:30:00.320266',
          feedback: null,
          corrected_sentence: null,
          ext: {
            word: 'test',
            word_idx: 3,
            cefr_level: 'A1',
            error_type: 'MISPRONUNCIATION',
            accuracy_score: 41,
            user_pronunciation: {
              url: 'https://recordings-pengguin-prod.s3.amazonaws.com//tmp/tmpgzl2dw91.wav_chunk_907688.wav_correct_pronunciation.mp3',
              image: 'https://www.gravatar.com/avatar/fde86509c2cf9cb833dbbc6c2439aa97',
            },
            correct_pronunciation: {
              url: 'https://recordings-pengguin-prod.s3.amazonaws.com//tmp/tmpgzl2dw91.wav_chunk_907688.wav_correct_pronunciation.mp3',
              accent: 'en-US',
            },
          },
          comment_type: null,
          video_url: null,
        },
      ],
      created_at: '2024-07-28T14:29:59.102491',
      updated_at: '2024-07-28T14:29:59.102496',
      error_type: 'MISPRONUNCIATION',
    },
    {
      id: 101,
      start_time: 0.04,
      end_time: 0.33,
      comments: [
        {
          id: 985015,
          comment: 'Unlock premium features by upgrading your account.',
          audio_comment_url: null,
          is_ai: true,
          user: null,
          likes: {
            count: 0,
            user_ids: [],
          },
          dislikes: {
            count: 0,
            user_ids: [],
          },
          created_at: '2024-07-28T14:30:02.838000',
          updated_at: '2024-07-28T14:30:02.838006',
          feedback: null,
          corrected_sentence: null,
          ext: {
            word: 'test',
            word_idx: 0,
            cefr_level: 'A1',
            error_type: 'GRAMMAR',
            accuracy_score: 45,
            user_pronunciation: {
              url: 'https://recordings-pengguin-prod.s3.amazonaws.com//tmp/tmpgzl2dw91.wav_chunk_907689.wav_correct_pronunciation.mp3',
              image: 'https://www.gravatar.com/avatar/fde86509c2cf9cb833dbbc6c2439aa97',
            },
            correct_pronunciation: {
              url: 'https://recordings-pengguin-prod.s3.amazonaws.com//tmp/tmpgzl2dw91.wav_chunk_907689.wav_correct_pronunciation.mp3',
              accent: 'en-US',
            },
          },
          comment_type: null,
          video_url: null,
        },
      ],
      created_at: '2024-07-28T14:30:02.158083',
      updated_at: '2024-07-28T14:30:02.158089',
      error_type: 'GRAMMAR',
    },
    {
      id: 102,
      start_time: 0.52,
      end_time: 0.6,
      comments: [
        {
          id: 985015,
          comment: 'Unlock premium features by upgrading your account.',
          audio_comment_url: null,
          is_ai: true,
          user: null,
          likes: {
            count: 0,
            user_ids: [],
          },
          dislikes: {
            count: 0,
            user_ids: [],
          },
          created_at: '2024-07-28T14:30:02.838000',
          updated_at: '2024-07-28T14:30:02.838006',
          feedback: null,
          corrected_sentence: null,
          ext: {
            word: 'test',
            word_idx: 0,
            cefr_level: 'A1',
            error_type: 'VOCAB',
            accuracy_score: 45,
            user_pronunciation: {
              url: 'https://recordings-pengguin-prod.s3.amazonaws.com//tmp/tmpgzl2dw91.wav_chunk_907689.wav_correct_pronunciation.mp3',
              image: 'https://www.gravatar.com/avatar/fde86509c2cf9cb833dbbc6c2439aa97',
            },
            correct_pronunciation: {
              url: 'https://recordings-pengguin-prod.s3.amazonaws.com//tmp/tmpgzl2dw91.wav_chunk_907689.wav_correct_pronunciation.mp3',
              accent: 'en-US',
            },
          },
          comment_type: null,
          video_url: null,
        },
      ],
      created_at: '2024-07-28T14:30:02.158083',
      updated_at: '2024-07-28T14:30:02.158089',
      error_type: 'VOCAB',
    },
    {
      id: 103,
      start_time: 0.44,
      end_time: 0.83,
      comments: [
        {
          id: 985015,
          comment: 'Unlock premium features by upgrading your account.',
          audio_comment_url: null,
          is_ai: true,
          user: null,
          likes: {
            count: 0,
            user_ids: [],
          },
          dislikes: {
            count: 0,
            user_ids: [],
          },
          created_at: '2024-07-28T14:30:02.838000',
          updated_at: '2024-07-28T14:30:02.838006',
          feedback: null,
          corrected_sentence: null,
          ext: {
            word: 'test',
            word_idx: 0,
            cefr_level: 'A1',
            error_type: 'MISPRONUNCIATION',
            accuracy_score: 45,
            user_pronunciation: {
              url: 'https://recordings-pengguin-prod.s3.amazonaws.com//tmp/tmpgzl2dw91.wav_chunk_907689.wav_correct_pronunciation.mp3',
              image: 'https://www.gravatar.com/avatar/fde86509c2cf9cb833dbbc6c2439aa97',
            },
            correct_pronunciation: {
              url: 'https://recordings-pengguin-prod.s3.amazonaws.com//tmp/tmpgzl2dw91.wav_chunk_907689.wav_correct_pronunciation.mp3',
              accent: 'en-US',
            },
          },
          comment_type: null,
          video_url: null,
        },
      ],
      created_at: '2024-07-28T14:30:02.158083',
      updated_at: '2024-07-28T14:30:02.158089',
      error_type: 'MISPRONUNCIATION',
    },
  ],
  created_at: '2024-07-28T14:29:48.773150',
  updated_at: '2024-07-28T14:29:57.461471',
  ext: null,
  processing_status: 'PROCESSED',
}

export const unpaidFakeTranscription = Array(7)
  .fill({
    id: 0,
    startTime: '00:00:00,000',
    startSeconds: 0,
    endTime: '00:00:00,000',
    endSeconds: 10,
    text: 'Unlock premium features by upgrading your account.',
  })
  .map((item, index) => ({
    ...item,
    id: index + 1,
    startSeconds: index * 10,
    endSeconds: (index + 1) * 10,
    startTime: `${index * 10}:00:00,000`,
    endTime: `${(index + 1) * 10}:00:00,000`,
  }))
