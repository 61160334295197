import React from 'react'
import { Box, Button, ClickAwayListener, Popper } from '@mui/material'
import { HexColorPicker } from 'react-colorful'
import ColorLensIcon from '@mui/icons-material/ColorLens'

interface ColorPickerProps {
  color: string
  onChangeColor: () => void
}
const ColorPicker: React.FC<ColorPickerProps> = (props) => {
  const { color, onChangeColor } = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box sx={{ positon: 'relative' }}>
        <Button
          onClick={handleClick}
          variant='text'
          sx={{
            border: '1px solid rgba(0,0,0,0.1)',
            ml: 1,
            width: '40px',
            height: '31.6px',
            minWidth: 'unset',
            borderRadius: '10px',
            backgroundColor: 'white',
            ':hover': {
              backgroundColor: 'white',
            },
          }}
        >
          <ColorLensIcon sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />
        </Button>
        <Popper id={id} open={open} anchorEl={anchorEl} sx={{ positon: 'relative', zIndex: 2000 }}>
          <HexColorPicker color={color} onChange={onChangeColor} />
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}

export default ColorPicker
