import { Grid, IconButton, Paper, Typography } from '@common/components'
import { MenuIcon } from '@common/icons'

interface ContainerProps {
  children: React.ReactNode
  handleOpen: () => void
}

const Container: React.FC<ContainerProps> = (props) => {
  const { children, handleOpen } = props
  return (
    <Grid item xs={12} md={8}>
      <Paper
        sx={{
          borderRadius: '20px',
          p: 3,
          height: '100%',
        }}
      >
        <IconButton onClick={handleOpen} sx={{ display: { xs: 'inline', md: 'none' } }}>
          <MenuIcon />
        </IconButton>
        {children}
      </Paper>
    </Grid>
  )
}

export default Container
