import styled from '@emotion/styled'
import { Box, Button } from '@common/components'

export const ModalContainer = styled(Box)({
  padding: '8px',
  height: '150px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})

export const ConfirmBtn = styled(Button)(({ theme }) => ({
  borderRadius: '5px',
  width: '100px',
  background: theme.palette.mode === 'dark' ? '#00B3BD' : '#00BA00',
  ':hover': {
    background: theme.palette.mode === 'dark' ? '#00b3bdb3' : '#00BA00b3',
  },
}))
export const CancelBtn = styled(Button)({
  borderRadius: '5px',
  width: '100px',
  background: '#738598',
  ':hover': { background: '#738598b3' },
})
