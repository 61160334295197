import { useEventGetEventByIdQuery } from '@app/services'
import { useLocation, useParams } from '@lib/router'
import { useUser } from '@features/auth/hooks'
import React from 'react'

const useCurrentEvent = () => {
  const { event_id: shortEventId } = useParams()
  const { user } = useUser()
  const location = useLocation()

  const { data: event } = useEventGetEventByIdQuery(
    {
      eventId: shortEventId,
    },
    { skip: !shortEventId },
  )

  const { data: fetchedEvent } = useEventGetEventByIdQuery(
    {
      eventId: event?.event_id,
    },
    { skip: !shortEventId },
  )

  const [currentEvent, setCurrentEvent] = React.useState(fetchedEvent)
  React.useEffect(() => {
    setCurrentEvent(fetchedEvent)
  }, [fetchedEvent])

  const isHost = location.pathname === '/demo' ? true : user.id === event?.host?.id

  return { event: currentEvent, isHost, setCurrentEvent }
}

export default useCurrentEvent
