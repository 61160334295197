import { defer } from '@lib/router'
import { baseApi as api } from '@app/services/api'
import { store } from '@app/store'

const teacherHomeLoader = async ({ params }) => {
  const homeTeacher = promise(params.teacher_url)
  return defer({ homeTeacher })
}

const promise = async (homeTeacher: string) => {
  const result = await store.dispatch(
    api.endpoints.teacherGetTeacherHomepageByUrl.initiate(
      { url: homeTeacher },
      { forceRefetch: true },
    ),
  )

  if (result.isError) {
    throw new Error('Page not found')
  }
  return result
}

export default teacherHomeLoader
