import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    notesCreateStickyNote: build.mutation<
      NotesCreateStickyNoteApiResponse,
      NotesCreateStickyNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/sticky_notes/note`,
        method: 'POST',
        body: queryArg.stickyNoteCreateRequest,
      }),
    }),
    notesGetStickyNotes: build.query<NotesGetStickyNotesApiResponse, NotesGetStickyNotesApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/sticky_notes/note`,
        params: {
          query: queryArg.query,
          event_id: queryArg.eventId,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    notesGetStickyNoteById: build.query<
      NotesGetStickyNoteByIdApiResponse,
      NotesGetStickyNoteByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/extensions/sticky_notes/note/${queryArg.noteId}` }),
    }),
    notesDeleteStickyNote: build.mutation<
      NotesDeleteStickyNoteApiResponse,
      NotesDeleteStickyNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/sticky_notes/note/${queryArg.noteId}`,
        method: 'DELETE',
      }),
    }),
    notesUpdateStickyNote: build.mutation<
      NotesUpdateStickyNoteApiResponse,
      NotesUpdateStickyNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/sticky_notes/note/${queryArg.noteId}`,
        method: 'PUT',
        body: queryArg.stickyNoteUpdateRequest,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type NotesCreateStickyNoteApiResponse =
  /** status 201 Successful Response */ StickyNoteCreateResponse
export type NotesCreateStickyNoteApiArg = {
  stickyNoteCreateRequest: StickyNoteCreateRequest
}
export type NotesGetStickyNotesApiResponse =
  /** status 200 Successful Response */ StickyNoteGetNotesResponse[]
export type NotesGetStickyNotesApiArg = {
  query?: string | null
  eventId?: string | null
  page?: number
  limit?: number
}
export type NotesGetStickyNoteByIdApiResponse =
  /** status 200 Successful Response */ StickyNoteGetNotesResponse
export type NotesGetStickyNoteByIdApiArg = {
  noteId: number
}
export type NotesDeleteStickyNoteApiResponse = /** status 204 Successful Response */ void
export type NotesDeleteStickyNoteApiArg = {
  noteId: number
}
export type NotesUpdateStickyNoteApiResponse = /** status 201 Successful Response */ any
export type NotesUpdateStickyNoteApiArg = {
  noteId: number
  stickyNoteUpdateRequest: StickyNoteUpdateRequest
}
export type StickyNoteCreateResponse = {
  id: number
  note: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type StickyNoteCreateRequest = {
  note: string
  color?: string
  event_id?: string | null
}
export type StickyNoteGetNotesResponse = {
  id: number
  note: string
  color: string
}
export type StickyNoteUpdateRequest = {
  note?: string | null
  color?: string | null
}
export const {
  useNotesCreateStickyNoteMutation,
  useNotesGetStickyNotesQuery,
  useLazyNotesGetStickyNotesQuery,
  useNotesGetStickyNoteByIdQuery,
  useLazyNotesGetStickyNoteByIdQuery,
  useNotesDeleteStickyNoteMutation,
  useNotesUpdateStickyNoteMutation,
} = injectedRtkApi
