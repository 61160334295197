import {
  Button,
  Card as MuiCard,
  LinearProgress,
  linearProgressClasses,
  Paper as PaperMui,
  styled,
} from '@mui/material'

export const Card = styled(MuiCard)({
  padding: '10px',
  width: '100%',
  maxWidth: '320px',
  height: '400px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '12px',
  boxShadow: '0px 0px 0px 3px #5556C7, 0px 8px 24px 8px rgba(12, 15, 19, 0.1)',
})
export const Img = styled('img')({
  height: '120px',
  width: '120px',
  objectFit: 'cover',
})
export const Paper = styled(PaperMui)({
  padding: '10px',
  maxWidth: '400px',
  width: '100%',
  height: '70px',
  borderRadius: '16px',
})
export const ButtonReward = styled(Button)({
  margin: '5px',
  width: '100%',
  background: 'rgba(85, 86, 199, 0.5)',
  ':hover': {
    background: 'rgba(85, 86, 199, 0.6)',
  },
})
export const ButtonCopy = styled(Button)({
  color: '#5556C7',
  borderColor: '#5556C7',
  ':hover': { borderColor: '#5556C7' },
})

export const BorderLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'rgba(230,230,230,0.5)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#5556C780',
  },
})
