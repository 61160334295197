import { createTheme, responsiveFontSizes } from '@common/utils/styles'
import globalTheme from './unsw-global-theme'

const lightTheme = createTheme({
  ...globalTheme,
  palette: {
    mode: 'light',
    primary: {
      main: '#000',
      contrastText: '#fff',
    },
    secondary: { main: '#3f61c4', contrastText: '#000' },
    tertiary: { main: '#ff635d', contrastText: '#fff' },
    dark: { main: '#010101', dark: 'rgb(40,40,40)', contrastText: '#fff' },
    light: {
      main: '#EBECED',
      light: '#EBECED',
      dark: 'rgb(150,150,150)',
      contrastText: '#010101',
    },
    neutral: {
      main: 'rgb(70,70,70)',
      contrastText: '#fff',
    },
    background: {
      default: '#ffdc00',
    },
    icons: {
      main: '#2A2F41',
      dark: '#1d202d',
      light: '#404761',
      contrastText: '#ffffff',
    },
  },
  components: {
    ...globalTheme.components,
  },
})

export default responsiveFontSizes(lightTheme)
