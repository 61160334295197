import React, { useCallback, useEffect, useState } from 'react'
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { flushSync } from 'react-dom'
// import imageByIndex from './imageByIndex'
import './styles.css'
import IconButton from '../icon-button'
import { ArrowBackIosNewIcon, ArrowForwardIosIcon } from '@common/icons'
import Box from '../box'
const TWEEN_FACTOR = 4.2

const numberWithinRange = (number: number, min: number, max: number): number =>
  Math.min(Math.max(number, min), max)

type PropType = {
  options?: EmblaOptionsType
  children: React.ReactNode
  sliders: any
}

const EmblaCarousel: React.FC<PropType> = (props) => {
  const { sliders, options = { loop: true }, children } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  const [tweenValues, setTweenValues] = useState<number[]>([])
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)
  const onScroll = useCallback(() => {
    if (!emblaApi) return

    const engine = emblaApi.internalEngine()
    const scrollProgress = emblaApi.scrollProgress()

    const styles = emblaApi.scrollSnapList().map((scrollSnap, index) => {
      let diffToTarget = scrollSnap - scrollProgress

      if (engine.options.loop) {
        engine.slideLooper.loopPoints.forEach((loopItem) => {
          const target = loopItem.target()
          if (index === loopItem.index && target !== 0) {
            const sign = Math.sign(target)
            if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress)
            if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress)
          }
        })
      }
      const tweenValue = 1 - Math.abs(diffToTarget * TWEEN_FACTOR)
      return numberWithinRange(tweenValue, 0, 1)
    })
    setTweenValues(styles)
  }, [emblaApi, setTweenValues])

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi])
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi])

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onScroll()
    onSelect(emblaApi)
    emblaApi.on('scroll', () => {
      flushSync(() => onScroll())
    })
    emblaApi.on('select', onSelect)
    emblaApi.on('reInit', onScroll)
  }, [emblaApi, onScroll])

  return (
    <Box sx={{}}>
      <div className='embla'>
        <div className='embla__viewport' ref={emblaRef}>
          <div className='embla__container'>{children}</div>
        </div>
        {sliders?.length > 1 && (
          <div className='embla__buttons'>
            <IconButton
              color='neutral'
              sx={{
                display: prevBtnDisabled ? 'none' : 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                left: -40,
                transform: 'translate(0, -50%)',
              }}
              onClick={scrollPrev}
              disabled={prevBtnDisabled}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton
              color='neutral'
              sx={{
                display: nextBtnDisabled ? 'none' : 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                right: -40,
                transform: 'translate(0, -50%)',
              }}
              onClick={scrollNext}
              disabled={nextBtnDisabled}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
        )}
      </div>
    </Box>
  )
}

export const EmblaSlider = (props: { children: React.ReactNode }) => {
  const { children } = props
  return (
    <div className='embla__slide' {...props}>
      {children}
    </div>
  )
}
export default EmblaCarousel
