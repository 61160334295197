import { InputBase, InputBaseProps } from '@mui/material'
import { Controller } from '@lib/form'

type ControllerInputBaseProps = InputBaseProps & {
  control?: any
}

const ControllerInputBase: React.FC<ControllerInputBaseProps> = (props) => {
  const { name, control, ...restProps } = props
  if (Boolean(control) && typeof name === 'string') {
    return (
      <Controller
        name={name}
        control={control}
        render={(args) => {
          return <InputBase {...restProps} {...args.field} />
        }}
      />
    )
  }
  return <InputBase name={name} {...restProps} />
}

export default ControllerInputBase
export * from '@mui/material/InputBase'
