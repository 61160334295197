import { Box, Container, Grid, Skeleton, Stack, Typography } from '@common/components'

const PricingPlaceholder = () => {
  return (
    <Box sx={{ mt: 3, pb: 15 }}>
      <Box sx={{ width: '88vw', maxWidth: '1392px', margin: '0 auto' }}>
        <Stack alignItems={'center'} spacing={1}>
          <Skeleton variant='text'>
            <Typography textAlign={'center'} fontWeight={600} variant='h4'>
              Pricing
            </Typography>
          </Skeleton>
          <Skeleton variant='rectangular' width={300} height={50} />
          <Grid container spacing={1} sx={{ alignItems: 'stretch', pt: 2 }}>
            {Array(4)
              .fill(null)
              .map((_, index) => (
                <Grid key={index} item xs={12} md={3}>
                  <Skeleton variant='rectangular' width={300} height={400} />
                </Grid>
              ))}
          </Grid>
        </Stack>
      </Box>
    </Box>
  )
}
export default PricingPlaceholder
