import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    geoguesserGetGeoGuesserGamesConfig: build.query<
      GeoguesserGetGeoGuesserGamesConfigApiResponse,
      GeoguesserGetGeoGuesserGamesConfigApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/geogame/config` }),
    }),
    geoguesserJoinGame: build.mutation<GeoguesserJoinGameApiResponse, GeoguesserJoinGameApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/geogame/game/${queryArg.eventId}/start`,
        method: 'POST',
        params: { exit_: queryArg.exit },
      }),
    }),
    geoguesserGetGame: build.query<GeoguesserGetGameApiResponse, GeoguesserGetGameApiArg>({
      query: (queryArg) => ({ url: `/api/v1/extensions/geogame/game/${queryArg.eventId}` }),
    }),
    geoguesserMakeGuess: build.mutation<GeoguesserMakeGuessApiResponse, GeoguesserMakeGuessApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/geogame/game/${queryArg.eventId}/guess`,
        method: 'POST',
        body: queryArg.coordinatesModel,
      }),
    }),
    geoguesserGetGameCoordinates: build.query<
      GeoguesserGetGameCoordinatesApiResponse,
      GeoguesserGetGameCoordinatesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/geogame/game/${queryArg.eventId}/coordinates`,
      }),
    }),
    geoguesserExitGame: build.mutation<GeoguesserExitGameApiResponse, GeoguesserExitGameApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/geogame/game/${queryArg.eventId}/exit`,
        method: 'POST',
      }),
    }),
    geoguesserGetAllGameResult: build.query<
      GeoguesserGetAllGameResultApiResponse,
      GeoguesserGetAllGameResultApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/extensions/geogame/game/${queryArg.eventId}/results` }),
    }),
    geoguesserGetRoundsData: build.query<
      GeoguesserGetRoundsDataApiResponse,
      GeoguesserGetRoundsDataApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/extensions/geogame/game/${queryArg.eventId}/rounds` }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type GeoguesserGetGeoGuesserGamesConfigApiResponse =
  /** status 200 Successful Response */ GeoGameConfig
export type GeoguesserGetGeoGuesserGamesConfigApiArg = void
export type GeoguesserJoinGameApiResponse = /** status 200 Successful Response */ any
export type GeoguesserJoinGameApiArg = {
  eventId: string
  exit?: boolean
}
export type GeoguesserGetGameApiResponse = /** status 200 Successful Response */ GeoGuesserGame
export type GeoguesserGetGameApiArg = {
  eventId: string
}
export type GeoguesserMakeGuessApiResponse = /** status 200 Successful Response */ any
export type GeoguesserMakeGuessApiArg = {
  eventId: string
  coordinatesModel: CoordinatesModel
}
export type GeoguesserGetGameCoordinatesApiResponse =
  /** status 200 Successful Response */ CoordinatesModel
export type GeoguesserGetGameCoordinatesApiArg = {
  eventId: string
}
export type GeoguesserExitGameApiResponse = /** status 201 Successful Response */ any
export type GeoguesserExitGameApiArg = {
  eventId: string
}
export type GeoguesserGetAllGameResultApiResponse =
  /** status 200 Successful Response */ GameGetCorrectResponse[]
export type GeoguesserGetAllGameResultApiArg = {
  eventId: string
}
export type GeoguesserGetRoundsDataApiResponse =
  /** status 200 Successful Response */ RoundsDataResponse
export type GeoguesserGetRoundsDataApiArg = {
  eventId: string
}
export type GeoGameConfig = {
  min_number_users: number
  max_number_users: number
  time_to_guess: number
  max_number_of_rounds: number
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type GameStatus = 'PENDING' | 'STARTED' | 'FINISHED' | 'CANCELED'
export type GeoGuesserGame = {
  created_time: string
  status: GameStatus
  end_time?: string | null
  started_time?: string | null
}
export type CoordinatesModel = {
  lon: number
  lat: number
}
export type DistanceUnit = 'KM' | 'MI'
export type GameGetCorrectResponse = {
  lon: number
  lat: number
  guess_lat: number
  guess_lon: number
  distance: number
  distance_unit?: DistanceUnit | null
  score: number
  round_number: number
  description: string
}
export type RoundsDataResponse = {
  current_round: number
  score: number
}
export const {
  useGeoguesserGetGeoGuesserGamesConfigQuery,
  useLazyGeoguesserGetGeoGuesserGamesConfigQuery,
  useGeoguesserJoinGameMutation,
  useGeoguesserGetGameQuery,
  useLazyGeoguesserGetGameQuery,
  useGeoguesserMakeGuessMutation,
  useGeoguesserGetGameCoordinatesQuery,
  useLazyGeoguesserGetGameCoordinatesQuery,
  useGeoguesserExitGameMutation,
  useGeoguesserGetAllGameResultQuery,
  useLazyGeoguesserGetAllGameResultQuery,
  useGeoguesserGetRoundsDataQuery,
  useLazyGeoguesserGetRoundsDataQuery,
} = injectedRtkApi
