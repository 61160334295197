import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ambassadorsGetAmbassadors: build.query<
      AmbassadorsGetAmbassadorsApiResponse,
      AmbassadorsGetAmbassadorsApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/ambassadors/ambassadors` }),
    }),
    ambassadorsCreateAmbassador: build.mutation<
      AmbassadorsCreateAmbassadorApiResponse,
      AmbassadorsCreateAmbassadorApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/ambassadors/ambassador`,
        method: 'POST',
        body: queryArg.createAmbassadorsRequest,
      }),
    }),
    ambassadorsDeleteAmbassador: build.mutation<
      AmbassadorsDeleteAmbassadorApiResponse,
      AmbassadorsDeleteAmbassadorApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/ambassadors/ambassador/${queryArg.ambassadorId}`,
        method: 'DELETE',
      }),
    }),
    ambassadorsUpdateAmbassador: build.mutation<
      AmbassadorsUpdateAmbassadorApiResponse,
      AmbassadorsUpdateAmbassadorApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/ambassadors/ambassador/${queryArg.ambassadorId}`,
        method: 'PUT',
        body: queryArg.updateAmbassadorRequest,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type AmbassadorsGetAmbassadorsApiResponse =
  /** status 200 Successful Response */ GetAmbassadorsResponse[]
export type AmbassadorsGetAmbassadorsApiArg = void
export type AmbassadorsCreateAmbassadorApiResponse =
  /** status 201 Successful Response */ GetAmbassadorsResponse
export type AmbassadorsCreateAmbassadorApiArg = {
  createAmbassadorsRequest: CreateAmbassadorsRequest
}
export type AmbassadorsDeleteAmbassadorApiResponse = /** status 200 Successful Response */ any
export type AmbassadorsDeleteAmbassadorApiArg = {
  ambassadorId: number
}
export type AmbassadorsUpdateAmbassadorApiResponse = /** status 200 Successful Response */ any
export type AmbassadorsUpdateAmbassadorApiArg = {
  ambassadorId: number
  updateAmbassadorRequest: UpdateAmbassadorRequest
}
export type GetAmbassadorsResponse = {
  id: number
  name: string
  title: string
  image?: string | null
  interests?: string[] | null
  languages?: string[] | null
  external_link?: string | null
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type CreateAmbassadorsRequest = {
  name: string
  title: string
  image?: string | null
  external_link?: string | null
  languages?: string[] | null
  interests?: string[] | null
}
export type UpdateAmbassadorRequest = {
  name?: string | null
  title?: string | null
  image?: string | null
  external_link?: string | null
  languages?: string[] | null
  interests?: string[] | null
}
export const {
  useAmbassadorsGetAmbassadorsQuery,
  useLazyAmbassadorsGetAmbassadorsQuery,
  useAmbassadorsCreateAmbassadorMutation,
  useAmbassadorsDeleteAmbassadorMutation,
  useAmbassadorsUpdateAmbassadorMutation,
} = injectedRtkApi
