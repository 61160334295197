import {
  Button as ButtonMui,
  Container as ContainerMui,
  styled,
  Typography as MuiTypography,
  Box,
} from '@mui/material'

export const Button = styled(ButtonMui)({
  marginTop: '50px',
  marginBottom: '100px',
  background: '#99E2FF',
  color: '#000',
  ':hover': { background: '#75AFC6' },
})

export const Container = styled(ContainerMui)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
export const ContainerBottom = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginRight: 'auto',
  marginLeft: 'auto',
  paddingRight: ' 2rem' /* 32px */,
  paddingLeft: '2rem' /* 32px */,
  maxWidth: '1400px',
})

export const Typography = styled(MuiTypography)({
  fontFamily: ' sans-serif',
  color: '#c5c6c9',
  fontSize: '14px',
})
