import { authApi } from '@app/services'
import { store } from '@app/store'
import { isTokenExpired } from '@common/utils/tokens'
import { logout, setAccessToken } from '../auth-actions'

export const refreshTokenIfExpired = async () => {
  const tokens = store.getState().auth.tokens
  if (tokens?.refresh_token && isTokenExpired(tokens.refresh_token.expiration_time)) {
    store.dispatch(logout())
  }
  if (tokens?.access_token && isTokenExpired(tokens.access_token.expiration_time)) {
    const { data } = await store.dispatch(
      authApi.endpoints.authRefreshJwtAccessToken.initiate({
        authorization: `Bearer ${tokens?.refresh_token.token}`,
      }),
    )
    store.dispatch(setAccessToken(data.access_token))
  }
  return store.getState().auth.tokens?.access_token?.token
}
