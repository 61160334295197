import React from 'react'
import {
  Stack,
  TextField,
  Button,
  InputAdornment,
  inputBaseClasses,
  Tooltip,
  Fab,
} from '@common/components'
import { useToggle, useTranslation } from '@common/hooks'
import { EditOutlinedIcon, SearchIcon } from '@common/icons'
import { useTheme } from '@features/theme/theme.hooks'
import WeblinkModal from '../weblink-modal'

const SearchBar = (props) => {
  const { value, onChange, ...restProps } = props
  const { t } = useTranslation('weblinks-feature')
  const searchInputRef = React.useRef(null)
  const { theme } = useTheme()

  const handleChange = (e) => {
    onChange(e.target.value)
  }

  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === 'k') {
      event.preventDefault()
      searchInputRef.current.focus()
    }
  }

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <Stack
      direction='row'
      justifyContent='space-around'
      alignItems='center'
      spacing={2}
      {...restProps}
    >
      <TextField
        fullWidth
        value={value}
        onChange={handleChange}
        inputRef={searchInputRef}
        placeholder='Search...'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: <InputAdornment position='start'>⌘+K</InputAdornment>,
        }}
        sx={{
          [`& .${inputBaseClasses.root}`]: {
            borderRadius: '20px',
            boxShadow: theme.shadows[2],
          },
        }}
      />
    </Stack>
  )
}

export default SearchBar
