import { forwardRef, useState } from 'react'
import {
  Box,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
  useTheme,
} from '@mui/material'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

type Language = {
  code: string
  name: string
  flag_image: string
  level: number
}

interface SelectedOptionProps {
  language: Language
  onRemove: () => void
  onChange: () => void
  autoLevelOpen: boolean
}

const SelectedOption: React.FC<SelectedOptionProps> = (props) => {
  const { language, onRemove, onChange, autoLevelOpen, ...restProps } = props
  const { code, name: label, level, flag_image: flagImage } = language
  return (
    <Container {...restProps}>
      <Field>
        <Label variant='body1'>{label}</Label>
        <LanguageLevel
          level={level}
          // defaultOpen={true}
          autoLevelOpen={autoLevelOpen}
          onChange={onChange}
          // code={code}
        />
        <FlagImg src={flagImage} alt={label} />
      </Field>
      <CloseButton size='small' onClick={onRemove}>
        <CloseIcon />
      </CloseButton>
    </Container>
  )
}

const Container = styled(Box)({
  position: 'relative',
})

const Field = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: `1px solid ${theme.palette.primary.main}`,
  padding: '12px 10px 12px 21px',
  borderRadius: '5px',
  minWidth: '100px',
}))

const Label = styled(Typography)({
  flex: 1,
  marginRight: '20px',
})

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: '50%',
  right: 0,
  transform: 'translate(calc(100% + 15px),-50%)',
  p: 0,
})

const FlagImg = styled('img')({
  width: '25px',
  borderRadius: '2px',
})

interface LanguageLevelProps {
  level: number
  onChange: (arg: number) => void
  autoLevelOpen: boolean
}
const LanguageLevel: React.FC<LanguageLevelProps> = (props) => {
  const { level, onChange, autoLevelOpen, ...restProps } = props
  const [open, setOpen] = useState(autoLevelOpen)
  const handleTooltipClose = () => {
    setOpen(false)
  }
  const handleTooltipOpen = () => {
    setOpen((prevState) => !prevState)
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Box sx={{ mr: 1.5 }} {...restProps}>
          <Tooltip
            sx={{
              [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: '#E7F4FB',
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: 2,
                fontSize: 11,
              },
            }}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            placement='top'
            title={
              <FormGroup>
                {[
                  { label: 'Beginner', level: 1 },
                  { label: 'Intermediate', level: 2 },
                  { label: 'Advanced', level: 3 },
                ].map((elem) => (
                  <FormControlLabel
                    key={elem.level}
                    control={
                      <Checkbox
                        checked={elem.level === level}
                        onChange={() => {
                          onChange(elem.level)
                          setTimeout(handleTooltipClose, 200)
                        }}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography sx={{ minWidth: '120px' }} align='left'>
                          {elem.label}
                        </Typography>
                        <LevelDisplay level={elem.level} />
                      </Box>
                    }
                  />
                ))}
              </FormGroup>
            }
          >
            <LevelDisplay level={level} onClick={handleTooltipOpen} />
          </Tooltip>
        </Box>
      </ClickAwayListener>
    </>
  )
}

interface LevelDisplayProps {
  level: number
  onClick?: () => void
}

const LevelDisplay = forwardRef<HTMLDivElement, LevelDisplayProps>(
  ({ level, onClick, ...rest }, ref) => {
    const { theme } = useTheme()
    return (
      <Stack
        ref={ref}
        spacing='2px'
        direction='row'
        sx={{ height: '22px' }}
        onClick={onClick}
        {...rest}
      >
        {[0, 1, 2].map((elem, k) => (
          <Box
            key={k}
            sx={{
              cursor: 'pointer',
              width: '6px',
              height: '20px',
              backgroundColor: level > k ? theme.palette.primary.main : '#C4C4C4',
              borderRadius: '3px',
            }}
          ></Box>
        ))}
      </Stack>
    )
  },
)

LevelDisplay.displayName = 'LevelDisplay'

export { SelectedOption }
