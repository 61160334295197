import { Container, Grid, Skeleton } from '@common/components'

const ProfilePlaceholder = () => {
  return (
    <Container maxWidth='lg'>
      <Grid
        container
        spacing={5}
        sx={{
          minHeight: '600px',
          my: 2,
        }}
      >
        <Grid item xs={4}>
          <Skeleton
            variant='rectangular'
            width='100%'
            height='100%'
            sx={{ borderRadius: '20px' }}
          />
        </Grid>
        <Grid item xs={8}>
          <Skeleton
            variant='rectangular'
            width='100%'
            height='100%'
            sx={{ borderRadius: '20px' }}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default ProfilePlaceholder
