import { RootState } from '@app/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define a serializable device type
interface SerializableDevice {
  deviceId: string
  label: string
}

// Update the state interface
interface EventState {
  audioDevices: SerializableDevice[]
  videoDevices: SerializableDevice[]
  selectedVideoDeviceId: string | null
  selectedAudioDeviceId: string | null
  selectedSpeakerDeviceId: string | null
}

// Update the initial state
const initialState: EventState = {
  audioDevices: [],
  videoDevices: [],
  selectedVideoDeviceId: null,
  selectedAudioDeviceId: null,
  selectedSpeakerDeviceId: null,
}

// Update the reducer
const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setAudioDevices: (state, action: PayloadAction<SerializableDevice[]>) => {
      state.audioDevices = action.payload
    },
    setVideoDevices: (state, action: PayloadAction<SerializableDevice[]>) => {
      state.videoDevices = action.payload
    },
    setSelectedVideoDeviceId: (state, action: PayloadAction<string>) => {
      state.selectedVideoDeviceId = action.payload
    },
    setSelectedAudioDeviceId: (state, action: PayloadAction<string>) => {
      state.selectedAudioDeviceId = action.payload
    },
    setSelectedSpeakerDeviceId: (state, action: PayloadAction<string>) => {
      state.selectedSpeakerDeviceId = action.payload
    },
  },
})

export default eventSlice.reducer

export const {
  setAudioDevices,
  setVideoDevices,
  setSelectedVideoDeviceId,
  setSelectedAudioDeviceId,
  setSelectedSpeakerDeviceId,
} = eventSlice.actions

export const selectAudioDevices = (state: RootState) => state.event.audioDevices
export const selectedVideoDevice = (state: RootState) => state.event.devicesVideo
export const selectSelectedVideoDevice = (state: RootState) => state.event.selectedVideoDevice
export const selectSelectedAudioDevice = (state: RootState) => state.event.selectedAudioDevice
export const selectSelectedSpeakerDevice = (state: RootState) => state.event.selectedSpeakerDeviceId
