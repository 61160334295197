import { GRAPHQL_BASE_URL } from '@config'
import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

// initialize an empty api service that we'll inject endpoints into later as needed
const graphqlApi = createApi({
  reducerPath: 'graphqlApi',
  baseQuery: graphqlRequestBaseQuery({
    url: GRAPHQL_BASE_URL,
  }),
  endpoints: () => ({}),
})

export { graphqlApi as api }
