import { Box, Button, Modal, Switch, Typography } from '@common/components'
import { GreenTicketIcon } from '@common/icons'
import React from 'react'

const FreeTrialModal = (props) => {
  const { open, onClose, teacher_name, onClick, getPlanId, recordingsNumber } = props
  const [checked, setChecked] = React.useState(true)

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ padding: '20px' }}>
        <GreenTicketIcon />
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
            Apply 7 day free trial
          </Typography>
          <Switch
            checked={checked}
            onClick={() => setChecked(!checked)}
            sx={{ marginLeft: '20px', height: '16px' }}
            size='medium'
          />
        </Box>
        <Typography sx={{ marginTop: '15px' }}>
          {'7 days of '}
          <span style={{ fontWeight: '600' }}>AI</span>
          {' feedback for '}
          <span style={{ fontWeight: '600' }}>free</span>
          {`, and then feedback from ${teacher_name} for subsequent recordings`}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            paddingLeft: '20px',
            paddingRight: '20px',
            marginTop: '20px',
          }}
        >
          <Button
            variant='contained'
            onClick={() => {
              onClick(getPlanId(false, recordingsNumber), checked)
            }}
            fullWidth
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default FreeTrialModal
