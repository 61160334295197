import { Box, Paper as PaperMui } from '@common/components'
import { styled } from '@common/utils/styles'

export const Paper = styled(PaperMui)({
  padding: '15px',
  // width: '100%',
  // height: "100px",
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '10px',
})
export const SessionsWrapper = styled(Box)({
  // marginTop: '15px',
  borderRadius: '20px',
  height: 'calc(100vh - 150px)',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  justifyContent: 'flex-start',
})
