import { styled } from '@common/utils/styles'
import { Box, Button, Paper } from '@common/components'

export const QuizContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  background: theme.palette.mode === 'dark' ? '#3D474F' : 'rgba(158, 197, 254, 0.3)',
  // minHeight: '250px',
  height: '100%',
  borderRadius: '10px',
  padding: '20px',
}))
export const QuestionContainer = styled(Paper)(() => ({
  position: 'relative',
  padding: '16px 20px',
  background: 'white',
  width: '100%',
  minHeight: '200px',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

export const AnswerBox = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 500,
  color: theme.palette.primary.main,
  padding: '6px 4px',
  // border: `1px solid ${theme.palette.primary.main} `,
  borderRadius: '5px',
  // borderLeft: `4px solid ${theme.palette.primary.main} `,
  // borderBottom: `4px solid ${theme.palette.primary.main} `,
}))
export const AnswerButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected, theme }) => ({
  opacity: selected ? 0.5 : 1,
  textTransform: 'initial',
  border: '1px solid',
  borderLeft: selected
    ? '1px solid'
    : theme.palette.mode === 'dark'
    ? `4px solid ${theme.palette.neutral.main} `
    : `4px solid ${theme.palette.primary.main} `,
  borderBottom: selected
    ? '1px solid'
    : `4px solid ${
        theme.palette.mode === 'dark' ? theme.palette.neutral.main : theme.palette.primary.main
      } `,
}))
