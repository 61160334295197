import { Button as ButtonMui } from '@common/components'
import { styled } from '@common/utils/styles'

export const FilterButton = styled(ButtonMui, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected, theme }) => ({
  borderRadius: '5px',
  backgroundColor: selected ? theme.palette.neutral.main : 'inherit',
  color: selected ? theme.palette.neutral.contrastText : 'inherit',
  '&:hover': {
    backgroundColor: selected ? theme.palette.neutral.main : 'inherit',
    color: selected ? theme.palette.neutral.contrastText : 'inherit',
  },
}))
