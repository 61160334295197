import { Box, Card, CardActionArea, styled } from '@mui/material'

export const ActivityContainer = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'image' || prop !== 'size' || prop !== 'selected',
})(({ selected, image, size }) => ({
  width: size === 'small' ? 65 : 100,
  height: size === 'small' ? 50 : 100,
  borderRadius: '15px',
  transition: '0.3s',
  outline: selected && '3px solid #0057E3',
  outlineOffset: '1px',
  backgroundColor: 'rgb(30,30,30)',
  overflow: 'hidden',
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}))
