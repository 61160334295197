import { Box, Typography } from '@common/components'
import { styled } from '@common/utils/styles'

export const EllipseBanner = styled(Box)(({ isMobile }) => ({
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%,-50%) rotate(-4deg)',
  borderRadius: '75%',
  backgroundColor: ' #2ABFFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '25px 0',
}))

export const EllipseTitle = styled(Typography)(({ isMobile }) => ({
  color: '#FFFFFF',
  transform: 'rotate(4.1deg)',
  textAlign: 'center',
  fontFamily: 'ReportRegular',
  fontWeight: 700,
}))

export const BannerSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#FFFFF' : '#09437F',
  fontSize: '17px',
  fontWeight: 500,
  lineHeight: '23px',
  letterSpacing: '0em',
}))

export const DownArrowIndicatorWrapper = styled(Box)(() => ({
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 60,
}))
