import { ThemeOptions } from '@common/utils/styles'

const globalTheme: ThemeOptions = {
  typography: {
    fontFamily: "'Roboto', 'Poppins', sans-serif",
    fontSize: 14,
    h1: {
      fontWeight: 500,
      fontSize: '70px',
    },
    h2: {
      fontWeight: 500,
      fontSize: '60px',
    },
    h3: {
      fontWeight: 400,
      fontSize: '50px',
    },
    h4: {
      fontWeight: 400,
      fontSize: '35px',
    },
    h5: {
      fontWeight: 400,
      fontSize: '25px',
    },
    h6: {
      fontWeight: 400,
      fontSize: '25px',
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          textTransform: 'capitalize',
          borderRadius: '0px !important',
        },
        sizeSmall: {
          // borderRadius: "22px",
        },
        sizeLarge: {
          borderRadius: '20px',
          fontSize: '26px',
          padding: '6px 30px',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius: '0px !important',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '0px !important',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          borderRadius: '0px !important',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: '0px !important',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '.MuiBox-root': { borderRadius: '0px !important' },
        '.MuiAvatar-root': {
          color: 'black !important',
        },
        input: { borderRadius: '0px !important' },
        '*::-webkit-scrollbar': {
          height: '10px',
          width: '7px',
          backgroundColor: 'rgba(0, 24, 53, 0.5)',
        },
        '*::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(80, 80, 80, 0.1)',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: '5px',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
        },
      },
    },
  },
}

export default globalTheme
