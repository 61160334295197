import { styled } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

export const Accordion = styled(MuiAccordion)({
  borderTop: '1px solid',
  background: 'rgba(0,0,0,0)',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
})

export const AccordionSummary = styled(MuiAccordionSummary)({
  backgroundColor: 'rgba(0, 0, 0,0)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '4px',
  },
})

export const AccordionDetails = styled(MuiAccordionDetails)({
  marginTop: '-10px',
  maxWidth: '95%',
  // borderTop: "1px solid rgba(255, 255, 255, 1)",
})
