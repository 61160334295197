import { Box, Stack } from '@common/components'
import { BannerSubtitle, DownArrowIndicatorWrapper } from './banner.styles'
import loadingScreenGameDownArrow from '@assets/svg/loadingScreenGameDownArrow.svg'

const Banner = (props) => {
  const { title, subtitle, ...restProps } = props

  return (
    <Stack alignItems='center' {...restProps}>
      <DownArrowIndicatorWrapper>
        <img
          style={{
            height: '4rem',
            width: '4rem',
          }}
          alt='drop down arrow'
          src={loadingScreenGameDownArrow}
        />
      </DownArrowIndicatorWrapper>
      <Box
        sx={{
          position: 'relative',
          maxWidth: 500,
          border: '4px solid #2ABFFF',
          borderRadius: '40px',
          padding: '20px',
          paddingTop: '50px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translate(-50%,-50%) rotate(-4deg)',
            borderRadius: '100%',
            backgroundColor: ' #2ABFFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '12px 35px',
          }}
        >
          {title}
        </Box>
        <BannerSubtitle variant='body1' align='center'>
          {subtitle}
        </BannerSubtitle>
      </Box>
    </Stack>
  )
}

export default Banner
