import { Paper as PaperMui } from '@common/components'
import { styled } from '@common/utils/styles'

export const Paper = styled(PaperMui)({
  padding: '15px',
  width: '100%',
  height: '70px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '10px',
})
