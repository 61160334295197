import { Fab as FabMui, Typography as TypographyMui } from '@common/components'
import { styled } from '@common/utils/styles'

export const Fab = styled(FabMui)({
  fontSize: '12px',
  width: '55px',
  textTransform: 'capitalize',
  height: 'unset',
  padding: '4px',
  position: 'absolute',
  bottom: '50%',
  transform: 'translate(0%,50%)',
  right: 0,
})

export const Typography = styled(TypographyMui, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active }: { active: boolean }) => ({
  position: 'relative',
  background: active ? '#9EC5FE5c' : '',
  padding: '6px',
  borderRadius: '5px',
  ':hover': {
    background: '#c5d3ea5c',
  },
}))
