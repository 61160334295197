import { RootState } from '@app/store'
import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  constraints: { audio: false, video: false, cameraId: null, microphoneId: null, speakerId: null },
  currentRtcRoom: null,
  isBreakoutSessionEnded: false,
}

const panelsSlice = createSlice({
  name: 'panels',
  initialState,
  reducers: {
    setConstraints: (state, action) => {
      state.constraints = { ...state.constraints, ...action.payload }
    },
    setCurrentRtcRoom: (state, action) => {
      state.currentRtcRoom = action.payload
    },
    setIsBreakoutSessionEnded: (state, action) => {
      state.isBreakoutSessionEnded = action.payload
    },
  },
})

export default panelsSlice.reducer

export const selectConstraints = (state: RootState) => state.panels.constraints
export const selectCurrentRtcRoom = (state: RootState) => state.panels.currentRtcRoom
export const selectIsBreakoutSessionEnded = (state: RootState) =>
  state.panels.isBreakoutSessionEnded

export const { setConstraints, setCurrentRtcRoom, setIsBreakoutSessionEnded } = panelsSlice.actions
