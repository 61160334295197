import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    chatStartGame: build.mutation<ChatStartGameApiResponse, ChatStartGameApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/chat/${queryArg.eventId}/start`,
        method: 'POST',
        params: { exit_: queryArg.exit },
      }),
    }),
    chatLeaveGame: build.mutation<ChatLeaveGameApiResponse, ChatLeaveGameApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/chat/${queryArg.eventId}/exit`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type ChatStartGameApiResponse = /** status 200 Successful Response */ any
export type ChatStartGameApiArg = {
  eventId: string
  exit?: boolean
}
export type ChatLeaveGameApiResponse = /** status 200 Successful Response */ any
export type ChatLeaveGameApiArg = {
  eventId: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export const { useChatStartGameMutation, useChatLeaveGameMutation } = injectedRtkApi
