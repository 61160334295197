import * as React from 'react'
const TicketIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={21} height={22} fill='none' {...props}>
    <g fill='#D92D20' clipPath='url(#a)'>
      <path d='M21 14.375v-.114a1.768 1.768 0 0 0-1.34-1.698 1.75 1.75 0 0 1 0-3.376A1.768 1.768 0 0 0 21 7.49v-.114A4.38 4.38 0 0 0 16.625 3h-14A2.625 2.625 0 0 0 0 5.625v1.864a1.768 1.768 0 0 0 1.34 1.698 1.75 1.75 0 0 1 0 3.376A1.768 1.768 0 0 0 0 14.26v1.864a2.625 2.625 0 0 0 2.625 2.625h14A4.38 4.38 0 0 0 21 14.375Zm-19.25 1.75.055-1.875a3.5 3.5 0 0 0-.055-6.761V5.625a.875.875 0 0 1 .875-.875h3.5V6.5a.875.875 0 0 0 1.75 0V4.75h8.75a2.625 2.625 0 0 1 2.625 2.625l-.055.125a3.509 3.509 0 0 0-2.57 3.375 3.562 3.562 0 0 0 2.625 3.406v.094A2.625 2.625 0 0 1 16.625 17h-8.75v-1.75a.875.875 0 1 0-1.75 0V17h-3.5a.875.875 0 0 1-.875-.875Z' />
      <path d='M7.875 11.75V10a.875.875 0 0 0-1.75 0v1.75a.875.875 0 1 0 1.75 0Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M21 .375v21H0v-21z' />
      </clipPath>
    </defs>
  </svg>
)
export default TicketIcon
