import { Box, Button, Typography } from '@common/components'
import { Link } from 'react-router-dom'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import { DASHBOARD_ROUTE } from '@common/constants/routes'
import './not-found.styles.css'
import { GlobalStyles } from '@mui/material'
import { useTheme } from '@features/theme/theme.hooks'

const NotFound = (props) => {
  const { theme } = useTheme()
  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 65px)',
        maxHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        ...props.sx,
      }}
    >
      <GlobalStyles
        styles={{
          '.error-container>span.zero:after': {
            background: theme.palette.background.default,
          },
        }}
      />
      <section className='error-container' style={{ opacity: 0.75 }}>
        <span className='four'>
          <span className='screen-reader-text'>4</span>
        </span>
        <span className='zero'>
          <span className='screen-reader-text'>0</span>
        </span>
        <span className='four'>
          <span className='screen-reader-text'>4</span>
        </span>
      </section>
      <Typography variant='h5' sx={{ fontFamily: 'Lexend', opacity: 0.6 }} color='primary'>
        Page not found
      </Typography>
      <Typography variant='h4'>Are you lost?</Typography>
      <Link to={DASHBOARD_ROUTE}>
        <Button variant='contained' startIcon={<KeyboardReturnIcon />} sx={{ mt: 4 }}>
          Back to home
        </Button>
      </Link>
    </Box>
  )
}

export default NotFound
