import { Alert, AlertTitle, Box, Button, Container, Typography } from '@common/components'
import { CenterContainer } from '@common/containers'
import { PROD } from '@config'
import { useRouteError } from '@lib/router'

const ErrorBoundary: React.FC = () => {
  const error = useRouteError()

  const handleRefresh = () => {
    window.location.reload()
  }

  return (
    <CenterContainer fullScreen>
      {PROD ? (
        <Alert severity='error'>
          <AlertTitle sx={{ fontWeight: 600 }}>
            An error has occured, we will fix it as soon as possible
          </AlertTitle>
          <Typography>Try reloading as a first step</Typography>
          <Button
            variant='contained'
            color='error'
            onClick={handleRefresh}
            sx={{ width: '150px', ml: 'auto', display: 'block' }}
          >
            Reload
          </Button>
        </Alert>
      ) : (
        <Container maxWidth='lg'>
          <Alert variant='outlined' severity='error'>
            <Typography variant='body1' sx={{ mb: 2 }}>
              This is just shown in DEV env, please report to the devs
            </Typography>
            <Box sx={{ backgroundColor: 'black', color: 'white', p: 2 }}>
              <Typography variant='body1' sx={{ mb: 2 }}>
                {error?.message ?? error?.data ?? JSON.stringify(error)}
              </Typography>
              <Typography variant='body2'>{error.stack}</Typography>
            </Box>
          </Alert>
        </Container>
      )}
    </CenterContainer>
  )
}

export default ErrorBoundary
