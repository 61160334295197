import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

// we put the actions in a seperate file to avoid circular dependencies in app/serivces/base-query

export const SET_USER = 'auth/set_user'
export const setUser = createAction<void>(SET_USER)

export const SET_TOKENS = 'auth/set_tokens'
export const setTokens = createAction<void>(SET_TOKENS)

export const SET_ACCESS_TOKEN = 'auth/set_access_token'
export const setAccessToken = createAction<void>(SET_ACCESS_TOKEN)

export const LOGOUT = 'auth/logout'
export const logout = createAction<void>(LOGOUT)

export const waitForRehydration = createAsyncThunk('waitForRehydration', async (api) => {
  await api.dispatch({ type: 'persist/REHYDRATE' })
})
