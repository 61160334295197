import { createTheme, responsiveFontSizes } from '@common/utils/styles'
import globalTheme from './global-theme'

const lightTheme = createTheme({
  ...globalTheme,
  palette: {
    mode: 'light',
    primary: {
      main: '#4681e0',
      contrastText: '#fff',
    },
    secondary: { main: '#FF8B01', contrastText: '#fff' },
    tertiary: { main: '#5556c780', contrastText: '#fff' },
    dark: { main: '#010101', dark: 'rgb(40,40,40)', contrastText: '#fff' },
    light: {
      main: '#EBECED',
      light: '#EBECED',
      dark: 'rgb(150,150,150)',
      contrastText: '#010101',
    },
    neutral: {
      main: 'rgb(70,70,70)',
      contrastText: '#fff',
    },
    background: {
      default: '#D8EFFF',
    },
    icons: {
      main: '#2A2F41',
      dark: '#1d202d',
      light: '#404761',
      contrastText: '#ffffff',
    },
  },
  components: {
    ...globalTheme.components,
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar': {
          height: '10px',
          width: '12px',
          backgroundColor: 'transparent',
        },
        '*::-webkit-scrollbar-track': {
          // boxShadow: 'inset 0 0 6px rgba(80, 80, 80,1)',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: '5px',
          backgroundColor: 'rgba(30, 30, 30, 0.5)',
        },
      },
    },
  },
})

export default responsiveFontSizes(lightTheme)
