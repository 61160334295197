import { Box } from '@common/components'
import { styled } from '@common/utils/styles'

export const VideoContainer = styled(Box)({
  width: '380px',
  maxWidth: '100%',
  height: '230px',
  borderRadius: '11px',
  background: 'rgba(230,230,230,0.7)',
  overflow: 'hidden',
  position: 'relative',
})
