import { Box, Divider, Typography } from '@common/components'
interface ContainerProps {
  title: string
  children: React.ReactNode
}
const Container: React.FC<ContainerProps> = (props) => {
  const { title, children } = props
  return (
    <>
      <Typography sx={{ display: 'inline' }}>{title}</Typography>
      <Divider
        sx={{
          mt: 1,
          mb: { xs: 1, lg: 3 },
        }}
      />
      {children}
    </>
  )
}

export default Container
