import { Box, Skeleton } from '@common/components'

const LanguagePlaceholder = () => {
  return (
    <Box>
      <Skeleton width='150px' height='30px' />
      <Skeleton width='130px' height='30px' sx={{ mt: 3 }} />
      <Skeleton width='330px' height='80px' />
      <Skeleton width='130px' height='30px' sx={{ mt: 3 }} />
      <Skeleton width='330px' height='80px' />
    </Box>
  )
}

export default LanguagePlaceholder
