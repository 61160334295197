import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '@app/store'

const initialState = {
  uploadProgress: 0,
  recordingStatus: false,
}

export const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    setUploadProgress: (state, action) => {
      return {
        ...state,
        uploadProgress: action.payload,
      }
    },
    setRecordingStatus: (state, action) => {
      return {
        ...state,
        recordingStatus: action.payload,
      }
    },
  },
})

export const { setUploadProgress, setRecordingStatus } = globalSlice.actions
export const selectUploadProgress = (state: RootState) => state.recordings.uploadProgress
export const selectRecordingStatus = (state: RootState) => state.recordings.recordingStatus

export default globalSlice.reducer
