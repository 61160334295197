import styled from '@emotion/styled'
import { Box, Stack, Typography } from '@common/components'

export const CountdownWrapper = styled(Stack)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

// export const NumberCard = styled(Box)({
//   padding: '5px 10px',
//   color: '#fff',
//   background: '#494E53',
//   marginLeft: '4px',
//   borderRadius: '5px',
//   fontSize: '25px',
//   width: '35px',
// })

export const NumberCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size }: { size: string }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  background: '#494E53',
  marginLeft: '4px',
  borderRadius: '5px',
  fontSize: size === 'small' ? '16px' : '25px',
  width: size === 'small' ? '22px' : '35px',
  padding: size === 'small' ? '5px' : '5px 10px',
}))

export const NumbersWrapper = styled(Box)({
  display: 'flex',
})

export const SeperatorWrapper = styled(Typography)({
  fontWeight: 700,
  textAlign: 'center',
})
