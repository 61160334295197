import { Box } from '@common/components'

type sizeEnum = 'small' | 'medium'
export const BlinkingDot = (props: { status: string; size?: sizeEnum }) => {
  const { status, size = 'medium' } = props
  const isRecording = status === 'recording'
  return isRecording ? (
    <Box
      className='blinking-dot'
      sx={{
        width: size === 'small' ? '10px' : '15px',
        height: size === 'small' ? '10px' : '15px',
        background: ' rgba(179, 0, 0, 0.8)',
        borderRadius: ' 50% !important',
        animation: 'blink 1s infinite',
        '@keyframes blink': {
          '50%': {
            opacity: '0',
          },
        },
      }}
    />
  ) : null
}

export default BlinkingDot
