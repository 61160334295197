import { Box, Paper } from '@common/components'
import { styled } from '@common/utils/styles'
import container from '../../../../common/components/container'

export const StepContainer = styled(container)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingTop: '50px',
  paddingBottom: 13,
})

export const Container = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  maxWidth: '90%',
  borderRadius: '15px',
  overflow: 'hidden',
})

export const RelativeContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: { sm: '600px', md: '100%' },
  display: 'flex',
})

export const ProgressViewContainer = styled(Box)({
  width: '100%',
  height: '100%',
})
