import { CheckCircleOutlineIcon } from '@common/icons'
import { Box, Card, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const PlanCard = (props) => {
  const {
    highlighted,
    title,
    subprice,
    price,
    detail,
    callToAction: CallToAction,
    description,
    planImg,
    icons = false,
    ...restProps
  } = props
  const { t } = useTranslation()

  return (
    <Card
      elevation={2}
      sx={{
        outline: highlighted && '3px #7D2AE7 solid',
        width: '100%',
        maxWidth: '350px',
        mx: 'auto',
        height: '100%',
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: "flex-start",
        position: 'relative',
        '&::before': {
          content: highlighted && "'Popular'",
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
          textAlign: 'center',
          background: '#7D2AE7',
          color: 'white',
          py: 0.5,
        },
      }}
      {...restProps}
    >
      <Stack spacing={2.5} alignItems='center' sx={{ width: '100%' }}>
        <Typography
          fontWeight={600}
          textAlign='center'
          sx={{ fontSize: '18px', pt: 2, height: '55px' }}
        >
          {title}
        </Typography>
        {planImg ? (
          planImg
        ) : (
          <Typography
            fontWeight={500}
            textAlign='center'
            color='text.secondary'
            sx={{ fontSize: '12px', height: '25px' }}
          >
            {subprice}
          </Typography>
        )}
        {price && (
          <Typography variant={'h3'} fontWeight={500} textAlign='center' sx={{ height: '45px' }}>
            {price}
          </Typography>
        )}
        {detail && (
          <Typography
            fontWeight={500}
            textAlign='center'
            color='text.secondary'
            sx={{ height: '30px', fontSize: '12px' }}
          >
            {detail}
          </Typography>
        )}
        <Box sx={{ width: '80%' }}>
          <CallToAction />
        </Box>
        <Divider sx={{ pt: 0.5, width: '40%', mx: 'auto' }} />
      </Stack>
      <Stack gap={2} sx={{ pt: 1, minHeight: '100px' }}>
        {description?.map((text, index) => (
          <Stack key={index} direction={'row'}>
            {icons && <CheckCircleOutlineIcon sx={{ color: '#28AA4B', mr: 1 }} />}
            <Typography color='text.secondary' fontWeight={500} sx={{ fontSize: '13px' }}>
              {text}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Card>
  )
}
