import { Button as ButtonMui } from '@mui/material'
import { styled } from '@common/utils/styles'

export const Button = styled(ButtonMui, {
  shouldForwardProp: (prop) => prop !== 'floating',
})<{ floating?: boolean }>(({ color, floating, theme }) => ({
  transition: 'all .2s ease-in-out',
  ...(floating && {
    textTransform: 'none',
    border: 'none',
    maxWidth: '100%',
    margin: '0 auto',
    display: 'flex',
    fontWeight: 'bold',
    color: color === 'secondary' ? '#202930' : theme.palette.mode === 'dark' ? 'white' : '#3D474F',
    background:
      color === 'primary'
        ? theme.palette.primary.main
        : color === 'secondary'
        ? '#FED363'
        : theme.palette.mode === 'dark'
        ? '#3D474F'
        : 'transparent',
    transform: 'translate(5px, -2px)',
    boxShadow:
      color === 'primary'
        ? 'inset 0px 0px 0px 1px #282E33, -4px 4px 0 0 #282E33'
        : color === 'secondary'
        ? 'inset 0px 0px 0px 0px #665428, -4px 4px 0 0 #665428'
        : theme.palette.mode === 'dark'
        ? 'inset 0px 0px 0px 1px #282E33, -4px 4px 0 0 #282E33'
        : 'inset 0px 0px 0px 1px #0c0f13, -4px 4px 0 0 #0c0f13',
    ':hover ': {
      border: 'none',
      // border: theme.palette.mode === 'dark' ? 'none' : '1px solid rgba(80,70,70,0.5)',
      transform: 'translate(0px, 0px)',
      boxShadow: 'inset 0px 0px 0px 0px #0c0f13, 0px 0px 3px 0 #0c0f13',
      background:
        color === 'primary'
          ? theme.palette.primary.main
          : color === 'secondary'
          ? '#FED363'
          : theme.palette.mode === 'dark'
          ? '#3D474F'
          : 'transparent',
    },
  }),
}))
