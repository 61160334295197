export type Proficiency = 0 | 1 | 2 | 3 | 4
export type ResolvedProficiency = 'Beginner' | 'Medium' | 'Advanced' | 'Native' | 'All levels'

const resolveProficiency = (proficiency: Proficiency): ResolvedProficiency => {
  switch (proficiency) {
    case 0:
      return 'Beginner'
    case 1:
      return 'Medium'
    case 2:
      return 'Advanced'
    case 3:
      return 'Native'
    case 4:
      return 'All levels'
    default:
      return 'Native'
  }
}
export { resolveProficiency }
