import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    storageUploadToBucket: build.mutation<
      StorageUploadToBucketApiResponse,
      StorageUploadToBucketApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/storage/upload/${queryArg.bucketName}`,
        method: 'POST',
        body: queryArg.bodyUploadToBucketApiV1ExtensionsStorageUploadBucketNamePost,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type StorageUploadToBucketApiResponse =
  /** status 201 Successful Response */ UploadFileResponse
export type StorageUploadToBucketApiArg = {
  bucketName: string
  bodyUploadToBucketApiV1ExtensionsStorageUploadBucketNamePost: BodyUploadToBucketApiV1ExtensionsStorageUploadBucketNamePost
}
export type UploadFileResponse = {
  url: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type BodyUploadToBucketApiV1ExtensionsStorageUploadBucketNamePost = {
  file: Blob
}
export const { useStorageUploadToBucketMutation } = injectedRtkApi
