import * as Yup from 'yup'
export const BecomeAmbassadorSchema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  intro: Yup.string().required('Introduction is a required field'),
})

export const videoLinkSchema = Yup.object({
  video_link: Yup.string()
    .matches(
      /^(http:\/\/|https:\/\/)(www\.)?(youtube\.com|vimeo\.com)\/[\w-]+(\?v=([\w-]+))?$/gm,
      'Please enter a valid YouTube or Vimeo link',
    )
    .required('Please enter a video link'),
})
