import { Box } from '@common/components'

interface CenterContainerProps {
  children: React.ReactNode
  sx?: object
  fullScreen?: boolean
}

const CenterContainer: React.FC<CenterContainerProps> = (props) => {
  const { children, fullScreen, sx, ...restProps } = props
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: fullScreen ? 'calc( 100vh - 66px )' : '100vh',
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Box>
  )
}

export default CenterContainer
