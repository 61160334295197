import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    localizationGetLocalizationFile: build.query<
      LocalizationGetLocalizationFileApiResponse,
      LocalizationGetLocalizationFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/localization/${queryArg.languageCode}.json`,
        params: { ns: queryArg.ns, nested: queryArg.nested },
      }),
    }),
    localizationAddLocalization: build.mutation<
      LocalizationAddLocalizationApiResponse,
      LocalizationAddLocalizationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/localization/add/${queryArg.languageCode}`,
        method: 'POST',
        body: queryArg.addKeyValRequest,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type LocalizationGetLocalizationFileApiResponse = /** status 200 Successful Response */ any
export type LocalizationGetLocalizationFileApiArg = {
  languageCode: string
  ns?: string | null
  nested?: boolean
}
export type LocalizationAddLocalizationApiResponse = /** status 201 Successful Response */ any
export type LocalizationAddLocalizationApiArg = {
  languageCode: string
  addKeyValRequest: AddKeyValRequest
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type AddKeyValRequest = {
  key: string
  val: string
  namespace?: string | null
}
export const {
  useLocalizationGetLocalizationFileQuery,
  useLazyLocalizationGetLocalizationFileQuery,
  useLocalizationAddLocalizationMutation,
} = injectedRtkApi
