import { Box, Button, Modal, Typography } from '@common/components'
import { SUBSCRIPTION_ROUTE } from '@common/constants/routes'
import { useNavigate } from 'react-router-dom'
import { TicketIcon } from '@common/icons'

const PageAlmostReadyModal = (props) => {
  const { open, onClose } = props
  const navigate = useNavigate()
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ padding: '20px' }}>
        <TicketIcon />
        <Typography sx={{ fontWeight: '600', marginTop: '10px' }}>
          Your page is ALMOST ready!
        </Typography>
        <Typography sx={{ marginTop: '5px' }}>
          Please take 2 minutes to connect your stripe account in order to start receiving payments
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            paddingLeft: '20px',
            paddingRight: '20px',
            marginTop: '20px',
          }}
        >
          <Button variant='outlined' sx={{ width: '150px' }} onClick={onClose}>
            Maybe Later
          </Button>
          <Button
            variant='contained'
            sx={{ width: '150px' }}
            onClick={() => {
              navigate(SUBSCRIPTION_ROUTE)
            }}
          >
            Go To Settings
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default PageAlmostReadyModal
