import { Box, Typography } from '@common/components'
import { styled } from '@common/utils/styles'

export const ProgressBar = styled(Box)(() => ({
  background: `repeating-linear-gradient(to left,
        #11304C -8.63%, 
        #013D7B 14.28%, 
        #005FB8 37.08%, 
        #009FE0 59.29%, 
        #7ED3F6 80.92%, 
        #BFE7F9 103.37%
        )`,
  backgroundSize: '200% auto',
  backgroundPosition: '0 100%',
  animation: ' gradient 1.5s infinite',
  animationFillMode: 'forwards',
  animationTimingFunction: 'linear',
  height: '18px',
  width: '100%',
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '100%': {
      backgroundPosition: '-200% 0',
    },
  },
}))

export const HeaderContainer = styled(Box)(() => ({
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const GameModeContainer = styled(Box)(() => ({
  width: '24%',
  justifyContent: 'center',
  display: 'flex',
}))

export const ModeTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderBottom: '1.04978px solid #0F82FF',
  padding: 1.25,
}))

export const linkStyle = {
  width: '62%',
  color: '#0F82FF',
  fontFamily: 'Inter',
  pl: 2,
  fontSize: '14px!important',
  padding: 2,
  border: '1.04978px solid #0F82FF',
  borderTopLeftRadius: 15,
  borderBottomLeftRadius: 15,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

export const copyLinkButtonStyle = {
  width: '38%',
  boxShadow: 'none',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  textTransform: 'lowercase',
  backgroundColor: '#0F82FF',

  color: '#FFFFFF',
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '13.5px!important',
  letterSpacing: '-0.011em',
}

export const InformationTypography = styled(Typography)(() => ({
  color: '#A6ADB5',
  mt: '1vh',
  fontSize: '17px',
  fontWeight: 500,
  lineHeight: '30px',
  letterSpacing: '-0.011em',
  textAlign: 'left',
}))

export const DownArrowIndicatorWrapper = styled(Box)(({ isMobile }) => ({
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: isMobile ? '10vh' : '13vh',
}))
