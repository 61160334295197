export const CheckMicPermission = async () => {
  try {
    const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true })
    mediaStream.getTracks().forEach((track) => track.stop())
    return 'granted'
  } catch (error) {
    console.error('Error checking microphone permission:', error)
    return 'denied'
  }
}

export default CheckMicPermission
