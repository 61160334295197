import { useEffect } from 'react'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import * as CookieConsent from 'vanilla-cookieconsent'

const useCookieConsent = () => {
  useEffect(() => {
    CookieConsent.run({
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {
          services: {
            posthog: {
              label: 'Posthog',
              cookies: [
                {
                  name: /^(ph_phc_)/,
                },
              ],
              onReject: () => {
                console.log('Posthog rejected')
              },
            },
            googleAnalytics: {
              label: 'Google Analytics',
              cookies: [
                {
                  name: /^(_ga)/,
                },
                {
                  name: '_gid',
                },
              ],
            },
            crisp: {
              label: 'Crisp',
              cookies: [
                {
                  name: /^(crisp)/,
                },
              ],
            },
          },
          readOnly: false,
          autoClear: {
            cookies: [
              {
                name: /^(ph_phc_)/,
              },
              {
                name: /^(crisp)/,
              },
              {
                name: /^(_ga)/,
              },
              {
                name: '_gid',
              },
            ],
          },
        },
      },
      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'We use cookies',
              description: 'We use necessary cookies to make our site work.',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage Individual preferences',
            },
            preferencesModal: {
              title: 'Manage cookie preferences',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              sections: [
                {
                  title: 'Strictly Necessary cookies',
                  description:
                    'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                  linkedCategory: 'necessary',
                },
                {
                  title: 'Performance and Analytics',
                  description:
                    'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                  linkedCategory: 'analytics',
                },
              ],
            },
          },
        },
      },
    })
  }, [])

  return null
}

export default useCookieConsent
