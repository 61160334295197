import { Container, Box, Skeleton } from '@common/components'

const NotFoundPlaceholder = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Skeleton width={'205px'} height={'40px'} />
      <Skeleton width={'140px'} height={'30px'} sx={{ mt: 4 }} />
    </Box>
  )
}

export default NotFoundPlaceholder
