import React from 'react'
import Slider, { SliderProps } from '@mui/material/Slider'
import { Controller } from '@lib/form'

type ControllerSliderProps = SliderProps & {
  control?: any
}

const ControllerSlider: React.FC<ControllerSliderProps> = React.forwardRef((props, ref) => {
  const { name, control, ...restProps } = props
  if (Boolean(control) && typeof name === 'string') {
    return (
      <Controller
        name={name}
        control={control}
        render={(args) => {
          return <Slider {...restProps} {...args.field} ref={ref} />
        }}
      />
    )
  }
  return <Slider name={name} {...restProps} ref={ref} />
})

ControllerSlider.displayName = 'ControllerSlider'

export default ControllerSlider
export * from '@mui/material/Slider'
