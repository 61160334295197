import { Stack } from '@mui/material'
import { forwardRef } from 'react'
import { Bar } from './level-display.styles'

const LevelDisplay = forwardRef((props, ref) => {
  const { level, sx, ...restProps } = props
  return (
    <Stack ref={ref} spacing='2px' direction='row' sx={{ height: '22px', ...sx }} {...restProps}>
      {[0, 1, 2, 3].map((elem, k) => (
        <Bar key={k} background={level > k - 1} />
      ))}
    </Stack>
  )
})

LevelDisplay.displayName = 'LevelDisplay'

export default LevelDisplay
