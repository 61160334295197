import { defer } from '@lib/router'
import { notesApi } from '@app/services/notes'
import { store } from '@app/store'

const liveboardsLoader = async () => {
  const liveboards = promise()
  return defer({ liveboards })
}

const promise = async () => {
  const result = null

  return result
}

export default liveboardsLoader
