import { Container, Grid } from '@common/components'
import { GlobalStyles } from '@mui/material'

interface ProfileContainerProps {
  children: React.ReactNode
}
const ProfileContainer: React.FC<ProfileContainerProps> = (props) => {
  const { children, ...rest } = props
  return (
    <>
      {/* <GlobalStyles
        styles={(theme: any) => {
          return {
            body: {
              backgroundColor: theme.palette.mode === 'light' ? '#D8EEFF' : '',
            },
          }
        }}
      /> */}
      <Container maxWidth='lg' {...rest}>
        <Grid
          container
          spacing={5}
          sx={{
            mt: 0.5,
            minHeight: 'calc( 90vh - 70px )',
            pb: 5,
          }}
        >
          {children}
        </Grid>
      </Container>
    </>
  )
}

export default ProfileContainer
