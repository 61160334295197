import { Button, Link, Modal, Stack, Typography } from '@common/components'
import pengguinLogo from '@assets/svg/pengguin-logo.svg'
import { useTheme } from '@features/theme/theme.hooks'
import { useAppDispatch, useAppSelector } from '@common/hooks'
import { selectUserFirstTime, setUserFirstTime } from '@features/auth/auth-slice'
import isepakLogoDark from '@assets/svg/logo-speakduo-dark.svg'
import { isUNSW } from '@utils'
import { useLocation } from 'react-router-dom'
import { DASHBOARD_ROUTE } from '@common/constants/routes'

const WelcomeModal = () => {
  const { isDark } = useTheme()
  const location = useLocation()
  const firstTime = useAppSelector(selectUserFirstTime)
  const dispatch = useAppDispatch()
  const handleClose = () => {
    dispatch(setUserFirstTime(false))
  }

  return (
    <Modal
      open={firstTime && location.pathname.startsWith(DASHBOARD_ROUTE)}
      onClose={handleClose}
      paperProps={{
        sx: { py: 4, px: 2, width: '400px', height: '470px', borderRadius: '10px' },
      }}
    >
      <Stack sx={{ height: '100%' }}>
        <Typography fontWeight={500} sx={{ fontSize: '22px', mb: 2 }}>
          Welcome to SpeakDuo!
        </Typography>
        <img
          src={isDark && !isUNSW() ? isepakLogoDark : pengguinLogo}
          alt={'pegguin'}
          style={{
            marginTop: '10px',
            display: 'block',
            width: '190px',
            margin: '0 auto',
            marginBottom: '22px',
            // filter: isDark ? 'invert(1)' : 'initial',
          }}
        />
        <Typography fontWeight={500}>
          SpeakDuo helps you improve your English speaking skills in 3 steps:
        </Typography>
        <Typography component={'ol'} sx={{ flex: 1, m: 0, p: 2, pt: 0.5 }}>
          <Typography component={'li'} variant='subtitle2' sx={{ mt: 1 }}>
            Join our Live Workshops to practice speaking with like-minded learners every day.
          </Typography>
          <Typography component={'li'} variant='subtitle2' sx={{ my: 1 }}>
            Check out the corrections from our Speech AI and learn from your mistakes.
          </Typography>
          <Typography component={'li'} variant='subtitle2'>
            Answer the questions in the proficiency test to see how you’ve progressed.
          </Typography>
        </Typography>
        <Button
          component={Link}
          href={'https://ispeak.gitbook.io/ispeak-getting-started'}
          target='_blank'
          rel='noopener'
          sx={{ borderRadius: '7px', ml: 'auto' }}
          // color='neutral'
          variant='contained'
        >
          Full Tutorial
        </Button>
      </Stack>
    </Modal>
  )
}

export default WelcomeModal
