import { Slider as SlideMui } from '@common/components'
import { styled } from '@common/utils/styles'

export const Slider = styled(SlideMui)(({ theme }) => ({
  flex: 1,
  '&.MuiSlider-root': { height: '10px' },
  '& .MuiSlider-thumb': {
    width: '20px',
    height: '20px',
    backgroundColor: '#fff',
    border: '2px solid',
    '&:before': {
      boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: '12px',
    background: 'unset',
    padding: '0px',
    width: '32px',
    height: '32px',
    borderRadius: '50% 50% 50% 0',
    backgroundColor: theme.palette.primary.main,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}))
