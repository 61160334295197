import React from 'react'
import { Container, StyledLinearProgress } from './ProgressBar.styles'

const ProgressBar = (props) => {
  const { height, currentStep, numberOfSteps, ...restProps } = props
  const progress =
    numberOfSteps && currentStep && currentStep <= numberOfSteps && currentStep >= 0
      ? (1 / (numberOfSteps - 1)) * 100 * currentStep
      : 0
  return (
    <Container height={height} {...restProps}>
      <StyledLinearProgress variant='determinate' value={progress} />
    </Container>
  )
}

ProgressBar.defaultProps = {
  height: 20,
}

export { ProgressBar }
