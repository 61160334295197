import { Outlet } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@common/utils/styles'
import lightTheme from '@styles/themes/light-theme'
import lightThemeUnsw from '@styles/themes/unsw-light-theme'
import { isUNSW } from '@utils'
import { useTheme } from '@features/theme/theme.hooks'
import darkTheme from '@styles/themes/dark-theme'

const AuthLayout = () => {
  const { isDark } = useTheme()
  return (
    <ThemeProvider theme={isUNSW() ? lightThemeUnsw : isDark ? darkTheme : lightTheme}>
      <CssBaseline />
      <Outlet />
    </ThemeProvider>
  )
}

export default AuthLayout
