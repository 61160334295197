import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { Controller } from '@lib/form'

type ControllerAutocompleteProps = AutocompleteProps & {
  control?: any
}

const ControllerAutocomplete: React.FC<ControllerAutocompleteProps> = (props) => {
  const { name, control, ...restProps } = props
  if (Boolean(control) && typeof name === 'string') {
    return (
      <Controller
        name={name}
        control={control}
        render={(args) => {
          return (
            <Autocomplete
              {...restProps}
              {...args.field}
              onChange={(event, newValue) => {
                args.field.onChange(newValue)
              }}
            />
          )
        }}
      />
    )
  }
  return <Autocomplete name={name} {...restProps} />
}

export default ControllerAutocomplete
export * from '@mui/material/Autocomplete'
