import { Chip } from '@common/components'
import { styled } from '@common/utils/styles'

export const Option = styled(Chip)({
  width: '250px',
  marginTop: '20px',
  '&.MuiChip-root': {
    borderRadius: '5px',
  },
})
