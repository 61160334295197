import { KickUserRequest, useEventKickUserMutation } from '@app/services'
import { Box, Button, Modal, Typography } from '@common/components'
import { PersonRemoveOutlinedIcon } from '@common/icons'
import { useCurrentEvent } from '@features/event/hooks'
import { Divider, Slider } from '@mui/material'
import React from 'react'

const KickStudentModal = (props) => {
  const { open, onClose, id } = props
  const [kickUser] = useEventKickUserMutation()
  const { event } = useCurrentEvent()
  const KickUserFromEvent = async (event_id, kickrequest: KickUserRequest) => {
    try {
      await kickUser({ eventId: event_id, kickUserRequest: kickrequest })
      onClose()
    } catch (error) {}
  }
  const [banFor, setBanFor] = React.useState(60)
  const handleChangeBanFor = (e) => {
    setBanFor(e.target.value)
  }
  const formatLabel = (e) => {
    return `${e} minutes`
  }
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ padding: '20px' }}>
        <PersonRemoveOutlinedIcon sx={{ color: 'red' }} />
        <Typography sx={{ fontWeight: '600', marginTop: '10px' }}>
          Kick Student from Lesson
        </Typography>
        <Typography sx={{ marginTop: '5px' }}>
          Are you sure you want to remove this student from the lesson?
        </Typography>
        <Box sx={{ mx: '20px' }}>
          <Slider
            min={0}
            step={10}
            valueLabelDisplay='auto'
            max={180}
            value={banFor}
            onChange={handleChangeBanFor}
            valueLabelFormat={formatLabel}
          />
        </Box>
        <Divider>Or</Divider>
        <Button
          variant='contained'
          onClick={() => {
            KickUserFromEvent(event?.event_id, {
              user_id: id,
              // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
              ban_for_seconds: -1,
            })
          }}
          sx={{ mt: 2, display: 'flex', mx: 'auto' }}
        >
          Ban User Permanent
        </Button>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            paddingLeft: '20px',
            paddingRight: '20px',
            marginTop: '20px',
          }}
        >
          <Button
            color='neutral'
            variant='outlined'
            sx={{ width: '150px', borderRadius: '10px' }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variant='contained'
            color='neutral'
            sx={{ width: '150px', borderRadius: '10px' }}
            onClick={() => {
              KickUserFromEvent(event?.event_id, { user_id: id, ban_for_seconds: 60 * banFor })
            }}
          >
            Kick
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default KickStudentModal
