import React from 'react'
import { Box, Grid, Typography } from '@common/components'
import ReactPlayer from 'react-player'

export const FeatureSection = (props) => {
  const { title, description, url, reverse, ...restProps } = props
  return (
    <Grid
      container
      {...restProps}
      spacing={8}
      sx={{ flexDirection: reverse && 'row-reverse', ...restProps?.sx }}
    >
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box>
          <Typography variant='h4' align={'left'} sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
          <Typography variant='body1' align={'left'} sx={{ mt: 2 }}>
            {description}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <VideoFeature url={url} />
      </Grid>
    </Grid>
  )
}

const VideoFeature = ({ url }) => {
  return (
    <Box
      sx={{
        maxWidth: '480px',
        width: '100%',
        position: 'relative',
        aspectRatio: '16/9',
        borderRadius: '11px',
        background: 'rgba(230,230,230,0.7)',
        overflow: 'hidden',
        mx: 'auto',
        pointerEvents: 'none',
      }}
    >
      <ReactPlayer
        // volume={0.8}
        playing={true}
        showinfo={false}
        config={{
          youtube: {
            playerVars: { modestbranding: 0, showinfo: 0, loop: 1 },
          },
        }}
        muted
        url={url}
        loop
        // controls
        width='100%'
        height='100%'
        style={{ position: 'absolute', top: 0, left: 0, objectFit: 'cover' }}
      />
    </Box>
  )
}
