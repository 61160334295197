import { Skeleton, Stack } from '@common/components'
import { CenterContainer } from '@common/containers'

const SubscriptionPlaceholder = () => {
  return (
    <CenterContainer>
      <Stack direction={{ xs: 'column', md: 'row' }} maxWidth='100%' spacing={4}>
        <Skeleton
          variant='rectangular'
          sx={{ borderRadius: 5, maxWidth: '100%' }}
          width={300}
          height={220}
        />
        <Skeleton
          variant='rectangular'
          sx={{ borderRadius: 5, maxWidth: '100%' }}
          width={300}
          height={220}
        />
      </Stack>
    </CenterContainer>
  )
}

export default SubscriptionPlaceholder
