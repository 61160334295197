import { Box, Skeleton, Stack } from '@common/components'

const BioPlaceholder = () => {
  return (
    <Box>
      <Skeleton width='150px' height='30px' />
      <Skeleton width='80%' height='130px' />
      <Skeleton width='150px' height='30px' />
      <Skeleton width='80%' height='130px' />
      <Stack direction='row' spacing={5} sx={{ width: '80%' }}>
        <Skeleton width='100%' height='130px' />
        <Skeleton width='100%' height='130px' />
      </Stack>
      <Stack direction='row' spacing={5} sx={{ width: '80%' }}>
        <Skeleton width='130px' height='60px' />
        <Skeleton width='130px' height='60px' />
      </Stack>
    </Box>
  )
}

export default BioPlaceholder
