import styled from '@emotion/styled'
import {
  alpha,
  inputBaseClasses,
  Card as MuiCard,
  CardActionArea as MuiCardActionArea,
  Typography,
} from '@mui/material'
import { TextField, Box } from '@common/components'

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& .${inputBaseClasses.root}`]: {
    borderRadius: '11px',
    backgroundColor: alpha('#A6ADB5', 0.15),
  },
  [`& .${inputBaseClasses.input}`]: {
    borderRadius: '11px',
  },
}))
export default StyledTextField

export const Card = styled(MuiCard)({
  width: '200px',
  height: '280px',
  maxWidth: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '20px',
})

export const CardActionArea = styled(MuiCardActionArea)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
})

export const ImageOuterContainer = styled(Box, {
  shouldForwardProp: (prop) => !['isTeach'].includes(prop),
})(({ isTeach }) => ({
  height: '200px',
  background: isTeach ? '#E21C3D' : '#1469CE',
  borderRadius: '13.6098px 13.6098px 0px 0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}))

export const ImageInnerContainer = styled(Box, {
  shouldForwardProp: (prop) => !['isTeach'].includes(prop),
})(({ isTeach }) => ({
  background: isTeach ? '#D11132' : '#0C53C1',
  borderRadius: '50%',
  width: '120px',
  height: '120px',
}))

export const TitleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '80px',
  alignItems: 'center',
  justifyContent: 'center',
})

export const TitleTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontWeight: '300',
  fontSize: '30px',
})

export const FlagsContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '20px',
})

export const Flag = styled(Box, {
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})(({ isSelected }) => ({
  borderRadius: '30%',
  border: isSelected && '2px solid rgba(0, 97, 253, 0.78)',
  '&:hover ': {
    boxShadow: '0px 0px 4px black',
  },
  transition: '.2s',
  cursor: 'pointer',
}))
