import { Outlet, ScrollRestoration } from 'react-router-dom'
import GlobalStyles from '@styles/global-styles'
import Toast from '@features/toast/toast'
import CrispChat from '@features/crisp-chat/components/crisp-chat'

const RootLayout: React.FC = () => {
  return (
    <>
      <ScrollRestoration />
      <GlobalStyles />
      <Toast />
      <Outlet />
      <CrispChat />
    </>
  )
}

export default RootLayout
