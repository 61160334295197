import * as Yup from 'yup'

export const loginSchema = Yup.object({
  email: Yup.string().email(),
  password: Yup.string().required(),
  username: Yup.string(),
})

export const resetPasswordSchemaStep2 = Yup.object({
  code: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string().required(),
})

export const registerSchema = Yup.object().shape({
  username: Yup.string().required(),
  email: Yup.string().email().required(),
  bio: Yup.string(),
  image: Yup.string(),
  full_name: Yup.string(),
  country: Yup.string(),
  pronouns: Yup.string(),
  date_of_birth: Yup.date(),
  learning: Yup.array(),
  address: Yup.object().shape({
    country: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    address: Yup.string(),
    zip_code: Yup.string(),
  }),
  speaking: Yup.array(),
  status: Yup.string().oneOf(['STUDENT', 'TEACHER']).required(),
  profile: Yup.object().shape({
    bio: Yup.string(),
    experience: Yup.string(),
    tags: Yup.array(),
    video_link: Yup.string(),
    teaching_style: Yup.string(),
    price_per_hour: Yup.number(),
    partner: Yup.boolean(),
  }),
  password: Yup.string().min(6).required(),
  registration_code: Yup.string(),
  promotion_code: Yup.string(),
})

export const sendResetPasswordEmailSchema = Yup.object({
  email: Yup.string().email().required(),
  // code: Yup.string().required(),
  // password: Yup.string().required(),
})

export const resetPasswordSchema = Yup.object({
  password: Yup.string().min(4),
  confirmPassword: Yup.string()
    .min(4)
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export const bioSchema = Yup.object({
  bio: Yup.string().min(0).max(50),
  experience: Yup.string(),
  teaching_style: Yup.string(),
  tags: Yup.array().of(Yup.string()),
})

export const createOrJoinAsGuestSchema = [
  Yup.object({
    status: Yup.string().required('You must choose a stqtus'),
  }),
  Yup.object({
    display_name: Yup.string().required('You must choose a display name'),
  }),
  Yup.object({
    native_language: Yup.mixed().required('You must choose a native language'),
  }),
  Yup.object({
    learning_language: Yup.mixed().required('You must choose a learning language'),
  }),
]

export const deleteAccountSchema = Yup.object({
  isChecked: Yup.boolean().oneOf(
    [true],
    'You need to confirm that you want to delete your account',
  ),
})

export const verifyEmailSchema = Yup.object({
  verifyEmailRequest: Yup.object().shape({
    email: Yup.string().required('There is no Email!'),
    code: Yup.string().required('Verification Code'),
  }),
})

export const updateAccountSchema = Yup.object({
  full_name: Yup.string().required(),
  address: Yup.string().optional(),
  city: Yup.string().optional(),
  state: Yup.string().optional(),
  zip_code: Yup.string().optional(),
  country: Yup.object().shape({
    code: Yup.string().optional(),
    label: Yup.string().optional(),
    phone: Yup.string().optional(),
    suggested: Yup.boolean(),
  }),
})

export const updateLanguagesSchema = Yup.object({
  learning: Yup.lazy((value) =>
    Array.isArray(value)
      ? Yup.array().of(
          Yup.object().shape({
            language: Yup.string(),
            proficiency: Yup.number().integer().min(1).max(4),
          }),
        )
      : Yup.object().shape({
          language: Yup.string(),
          proficiency: Yup.number().integer().min(1).max(4),
        }),
  ),
  speaking: Yup.array().of(
    Yup.object().shape({
      language: Yup.string(),
      proficiency: Yup.number().integer().min(1).max(4),
    }),
  ),
})

export const payCashSchema = Yup.object({
  fullName: Yup.string().required(),
  address: Yup.string().required(),
  phone_number: Yup.string().required(),
})
