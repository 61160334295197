import { ChatMessage } from '@types'
import React from 'react'

interface chatContextType {
  messages: ChatMessage[]
  handleSendMessage: (message: string) => void
  channelChatJoined: boolean
}

const chatContext = React.createContext<chatContextType>({
  messages: [],
  handleSendMessage: () => {},
  channelChatJoined: false,
})

export default chatContext
