import { Box, Container, Grid, Skeleton, Stack, Typography } from '@common/components'

const DashboardPlaceholder = () => {
  return (
    <Container maxWidth='lg' sx={{ mb: 5 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Skeleton
          variant='rectangular'
          sx={{ top: 0, position: 'absolute', width: '100%', height: '27vw', minHeight: '250px' }}
        />
        <Box sx={{ width: '100%', height: '27vw', minHeight: '250px' }} />
        <Stack
          direction={'row'}
          justifyContent={'flex-start'}
          sx={{ width: '100%', position: 'relative', pl: 1 }}
        >
          <Skeleton sx={{ my: 1, py: 1 }} variant='text'>
            <Typography component={'h1'} fontWeight={500} variant='h5' sx={{ fontWeight: 700 }}>
              English Events
            </Typography>
          </Skeleton>
        </Stack>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <Box>
              <Skeleton variant='text' sx={{ ml: 1 }}>
                <Typography component={'h1'} fontWeight={500} variant='h5' sx={{ fontWeight: 700 }}>
                  English Events
                </Typography>
              </Skeleton>
              {Array(4)
                .fill(null)
                .map((elem, index) => (
                  <Skeleton
                    key={index}
                    variant='rectangular'
                    width={'100%'}
                    height={250}
                    sx={{ borderRadius: '10px', maxWidth: '750px', m: 1 }}
                  />
                ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <Skeleton variant='text' sx={{ ml: 1 }}>
                <Typography fontWeight={500} variant='h5' sx={{ fontWeight: 700, py: 1 }}>
                  Testimonials
                </Typography>
              </Skeleton>
              <Skeleton variant='text' sx={{ ml: 1 }}>
                <Typography fontWeight={500} variant='h5' sx={{ fontWeight: 700 }}>
                  Help us by sharing your thoughts.
                </Typography>
              </Skeleton>
              {Array(3)
                .fill(null)
                .map((elem, index) => (
                  <Skeleton
                    key={index}
                    variant='rectangular'
                    width={'100%'}
                    height={250}
                    sx={{ borderRadius: '10px', maxWidth: '750px', m: 1 }}
                  />
                ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default DashboardPlaceholder
