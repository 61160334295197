import { baseApi as api } from './api'

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDictionnary: build.query({
      query: ({ lang, word }) => ({
        url: `https://dictionary.pengguin.com/${lang}/${word}`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetDictionnaryQuery, useLazyGetDictionnaryQuery } = injectedRtkApi
