import * as React from 'react'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Divider,
  Paper,
  Stack,
  Tooltip,
  Typography,
  LevelDisplay,
} from '@common/components'
import { useToggle, useTranslation } from '@common/hooks'
import { motion } from 'framer-motion'
import CheckCircle from '@mui/icons-material/CheckCircle'
import teacherPlaceholder from '@assets/images/teacher.png'

import { KeyboardArrowDownIcon, KeyboardArrowUpIcon, StarBorderIcon } from '@common/icons'
import { useNavigate } from 'react-router-dom'
// import { CardWrapper, Overlay } from "./teacher-card.styles"
// import {
//   KeyboardArrowUpIcon,
//   VerifiedUserIcon,
//   StarOutlineIcon,
// } from "@common/icons"
// import { useTheme } from "@mui/material"
// import {
//   Button,
//   ClickAwayListener,
//   Chip,
//   Collapse,
//   Box,
//   Typography,
//   Divider,
//   Grid,
//   Stack,
// } from "@common/components"
// import PersonIcon from "@mui/icons-material/Person"
interface TeacherCardProps {
  teacher: {}
}

const TeacherCard = React.forwardRef((props: TeacherCardProps, ref) => {
  const { teacher, ...restProps } = props
  const { t } = useTranslation('marketplace')
  const { open: expanded, handleToggle } = useToggle(false)

  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`/user/${teacher?.username}`)
  }
  console.log(teacher)

  return (
    <Card
      {...restProps}
      sx={{
        position: 'relative',
        width: '250px',
        height: '300px',
        ...restProps.sx,
      }}
    >
      <img
        src={teacherPlaceholder}
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
      />
      <CardActionArea
        onClick={handleToggle}
        component={motion.div}
        animate={{ y: expanded ? -200 : 0 }}
        sx={{
          position: 'absolute',
          top: 220,
          height: 'calc(100% + 100px)',
        }}
      >
        <Paper sx={{ height: '100%', p: 1 }} elevation={5}>
          <Stack direction='row' justifyContent='center'>
            {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            spacing={2}
            sx={{ width: '100%' }}
          >
            <Stack direction='row' alignItems='center' spacing={0.5}>
              <Typography variant='body1' fontWeight={500} noWrap sx={{ maxWidth: '150px' }}>
                {teacher.full_name ?? teacher.username}
              </Typography>
              {teacher?.is_pro && (
                <Tooltip title='Pro teacher'>
                  <CheckCircle sx={{ fontSize: '17px' }} />
                </Tooltip>
              )}
            </Stack>
            {teacher?.perHour && (
              <Typography variant='body1' color={'#0061FD'}>
                {teacher?.perHour}$<Typography variant='caption'>/hr</Typography>
              </Typography>
            )}
          </Stack>
          {expanded && (
            <Stack>
              <Divider sx={{ borderBottomWidth: '3px', my: 1 }} />
              <Stack direction='row' spacing={3} alignItems='center'>
                <Stack direction='row' alignItems='center'>
                  <StarBorderIcon />
                  <Typography variant='body1' fontWeight={600} noWrap>
                    {teacher?.rating_summary?.rating_average} stars
                  </Typography>
                </Stack>
                <Typography variant='caption' noWrap>
                  {teacher?.rating_summary?.reviews_count} reviews
                </Typography>
              </Stack>
              <Stack direction='column' spacing={1}>
                {teacher?.learning?.map((language, index) => (
                  <Stack
                    sx={{ px: 1 }}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    key={index}
                  >
                    <Typography sx={{ width: '85px' }}>Teaches</Typography>

                    <Typography key={index}>{language?.language}</Typography>
                    <LevelDisplay level={language?.proficiency} />
                  </Stack>
                ))}
                {teacher?.speaking?.map((language, index) => (
                  <Stack
                    sx={{ px: 1 }}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    key={index}
                  >
                    <Typography sx={{ width: '85px' }}>speaks</Typography>

                    <Typography key={index}>{language?.language}</Typography>
                    <LevelDisplay level={language?.proficiency} />
                  </Stack>
                ))}
              </Stack>
              <Button
                onClick={handleClick}
                variant='contained'
                color='tertiary'
                sx={{ mt: 2, borderRadius: '20px', maxWidth: '120px' }}
              >
                View Profile
              </Button>
            </Stack>
          )}
        </Paper>
      </CardActionArea>
    </Card>
  )
})

TeacherCard.displayName = 'teacherCard'

export default TeacherCard
