import { MobileTimePicker, MobileTimePickerProps } from '@mui/x-date-pickers'
import React from 'react'
import { Controller } from '@lib/form'

type ControllerMobileTimePickerProps = MobileTimePickerProps & {
  control?: any
}

const ControllerMobileTimePicker: React.FC<ControllerMobileTimePickerProps> = React.forwardRef(
  (props, ref) => {
    const { name, control, ...restProps } = props
    if (Boolean(control) && typeof name === 'string') {
      return (
        <Controller
          name={name}
          control={control}
          render={(args) => {
            return <MobileTimePicker {...restProps} {...args.field} ref={ref} />
          }}
        />
      )
    }
    return <MobileTimePicker name={name} {...restProps} ref={ref} />
  },
)

ControllerMobileTimePicker.displayName = 'ControllerMobileTimePicker'

export default ControllerMobileTimePicker
export * from '@mui/x-date-pickers/MobileTimePicker'
