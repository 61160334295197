import React, { useState, forwardRef, useImperativeHandle } from 'react'
import interviewJobQuestions from '../../../mock/interview-job.json'
import ieltsQuestions from '../../../mock/ielts.json'
import { Typography, IconButton, Stack } from '@common/components'
import { ReplayIcon } from '@common/icons'

export interface InterviewQuestionViewHandle {
  getNewQuestion: () => void
}

interface InterviewQuestionViewProps {
  question?: string
  workshop?: 'job-interview' | 'ielts'
}

export const InterviewQuestionView = forwardRef<
  InterviewQuestionViewHandle,
  InterviewQuestionViewProps
>(({ question, workshop }, ref) => {
  const getQuestionsList = () => {
    return workshop === 'ielts' ? ieltsQuestions : interviewJobQuestions
  }

  const [currentQuestion, setCurrentQuestion] = useState(() => {
    const questions = getQuestionsList()
    const randomIndex = Math.floor(Math.random() * questions.length)
    return questions[randomIndex]
  })

  const getNewQuestion = () => {
    const questions = getQuestionsList()
    const randomIndex = Math.floor(Math.random() * questions.length)
    setCurrentQuestion(questions[randomIndex])
  }

  useImperativeHandle(ref, () => ({
    getNewQuestion,
  }))

  return (
    <Stack direction='row' alignItems='center' spacing={2} sx={{ p: 1 }}>
      <Typography variant='body1' align='center' fontWeight={600}>
        {question || currentQuestion.Question}
      </Typography>
      <IconButton color='primary' onClick={getNewQuestion}>
        <ReplayIcon />
      </IconButton>
    </Stack>
  )
})

InterviewQuestionView.displayName = 'InterviewQuestionView'
