/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react'
import { Box, ClickAwayListener, Grow, Popper, useTheme, IconButton, Paper } from '@mui/material'
import { BackdropButton, BackdropButtonWithShadow, Container } from './popper-button.styles'
import CloseIcon from '@mui/icons-material/Close'

const PopperButton = React.forwardRef((props, ref) => {
  const { icon: Icon, children, buttonProps, containerProps, ...restProps } = props

  const { theme } = useTheme()
  const color = theme.palette.background.paper
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <Box {...restProps}>
      <Box
        ref={ref}
        sx={{
          display: 'inline-block',
          position: 'relative',
        }}
      >
        <Box sx={{ backgroundColor: color, borderRadius: '50%' }}>
          <IconButton
            ref={anchorRef}
            onClick={handleToggle}
            variant='contained'
            sx={{
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              zIndex: open && 999,
            }}
            {...buttonProps}
          >
            <Icon />
          </IconButton>
        </Box>
        <Grow in={open}>
          <Box>
            <BackdropButton sx={{ backgroundColor: color }} />
            <BackdropButtonWithShadow sx={{ backgroundColor: color }} />
          </Box>
        </Grow>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement='top-start'
        transition
        sx={{ zIndex: 2 }}
      >
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: `${placement.split('-')[1] === 'start' ? 'left' : 'right'} ${
                  placement.split('-')[0] === 'top' ? 'bottom' : 'top'
                }`,
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                }}
              >
                <Container
                  placement={placement}
                  {...containerProps}
                  sx={{ backgroundColor: color, ...containerProps?.sx }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box sx={{ zIndex: 10001 }}>
                      <IconButton
                        size='small'
                        sx={{
                          position: 'absolute',
                          top: 5,
                          right: placement !== 'bottom-end' && 5,
                          left: placement === 'bottom-end' && 5,
                        }}
                        onClick={handleClose}
                      >
                        <CloseIcon />
                      </IconButton>
                      <div>{children}</div>
                    </Box>
                  </ClickAwayListener>
                </Container>
              </Box>
            </Grow>
          )
        }}
      </Popper>
    </Box>
  )
})

export default PopperButton
