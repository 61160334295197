import React from 'react'

const lessonFeedbackContext = React.createContext({
  position: 0,
  setPosition: () => {},
  audioRef: null,
  selectedChunk: null,
  setSelectedChunk: () => {},
  paused: true,
  setPaused: () => {},
  addChunk: (feedback) => {},
})

export default lessonFeedbackContext
