import { Checkbox, CheckboxProps } from '@mui/material'
import { Controller } from '@lib/form'

type ControllerCheckboxProps = CheckboxProps & {
  control?: any
}

const ControllerCheckbox: React.FC<ControllerCheckboxProps> = (props) => {
  const { name, control, ...restProps } = props
  if (Boolean(control) && typeof name === 'string') {
    return (
      <Controller
        name={name}
        control={control}
        render={(args) => {
          // console.log(args)
          return <Checkbox {...restProps} {...args.field} />
        }}
      />
    )
  }
  return <Checkbox name={name} {...restProps} />
}

export default ControllerCheckbox
export * from '@mui/material/Checkbox'
