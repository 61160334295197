import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    captionThisGetCaptionThisGamesConfig: build.query<
      CaptionThisGetCaptionThisGamesConfigApiResponse,
      CaptionThisGetCaptionThisGamesConfigApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/caption_this/config` }),
    }),
    captionThisJoinGame: build.mutation<CaptionThisJoinGameApiResponse, CaptionThisJoinGameApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/caption_this/${queryArg.eventId}/start`,
        method: 'POST',
      }),
    }),
    captionThisExitGame: build.mutation<CaptionThisExitGameApiResponse, CaptionThisExitGameApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/caption_this/${queryArg.eventId}/exit`,
        method: 'POST',
      }),
    }),
    captionThisGetGameResult: build.query<
      CaptionThisGetGameResultApiResponse,
      CaptionThisGetGameResultApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/caption_this/${queryArg.eventId}/get_game_result`,
      }),
    }),
    captionThisListGameVideos: build.query<
      CaptionThisListGameVideosApiResponse,
      CaptionThisListGameVideosApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/extensions/caption_this/${queryArg.eventId}/videos` }),
    }),
    captionThisGuessGameVideo: build.mutation<
      CaptionThisGuessGameVideoApiResponse,
      CaptionThisGuessGameVideoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/caption_this/${queryArg.eventId}/guess`,
        method: 'POST',
        body: queryArg.guessCaptionThisRequest,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type CaptionThisGetCaptionThisGamesConfigApiResponse =
  /** status 200 Successful Response */ CaptionThisConfig
export type CaptionThisGetCaptionThisGamesConfigApiArg = void
export type CaptionThisJoinGameApiResponse = /** status 200 Successful Response */ any
export type CaptionThisJoinGameApiArg = {
  eventId: string
}
export type CaptionThisExitGameApiResponse = /** status 200 Successful Response */ any
export type CaptionThisExitGameApiArg = {
  eventId: string
}
export type CaptionThisGetGameResultApiResponse =
  /** status 200 Successful Response */ CaptionThisGameScore
export type CaptionThisGetGameResultApiArg = {
  eventId: string
}
export type CaptionThisListGameVideosApiResponse =
  /** status 200 Successful Response */ CaptionThisVideoResponseList
export type CaptionThisListGameVideosApiArg = {
  eventId: string
}
export type CaptionThisGuessGameVideoApiResponse =
  /** status 200 Successful Response */ GuessCaptionThisResponse
export type CaptionThisGuessGameVideoApiArg = {
  eventId: string
  guessCaptionThisRequest: GuessCaptionThisRequest
}
export type CaptionThisConfig = {
  min_number_users: number
  max_number_users: number
  retries: number
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type CaptionThisGameScore = {
  score: string
}
export type CaptionThisGameStatus = 'PENDING' | 'STARTED' | 'SUCCESSFUL' | 'FAILED' | 'FINISHED'
export type CaptionThisVideoResponse = {
  id: number
  provider: string
  video_id: string
  start_ms: string
  end_ms: string
  prefix_phrase: string
  suffix_phrase: string
  last_guess?: string | null
  guess_length: number
  tries: number
  status: CaptionThisGameStatus
}
export type CaptionThisVideoResponseList = {
  videos: CaptionThisVideoResponse[]
}
export type GuessCaptionThisResponse = {
  game_id: number
  success?: boolean | null
  correct_guess?: string | null
  round_ended?: boolean
}
export type GuessCaptionThisRequest = {
  game_id: number
  word: string
}
export const {
  useCaptionThisGetCaptionThisGamesConfigQuery,
  useLazyCaptionThisGetCaptionThisGamesConfigQuery,
  useCaptionThisJoinGameMutation,
  useCaptionThisExitGameMutation,
  useCaptionThisGetGameResultQuery,
  useLazyCaptionThisGetGameResultQuery,
  useCaptionThisListGameVideosQuery,
  useLazyCaptionThisListGameVideosQuery,
  useCaptionThisGuessGameVideoMutation,
} = injectedRtkApi
