import { Controller } from '@lib/form'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import React from 'react'
// import { TextField } from "./text-field.styles"

type ControllerTextFieldProps = TextFieldProps & {
  control?: any
}

const ControllerTextField: React.FC<ControllerTextFieldProps> = React.forwardRef((props, ref) => {
  const { variant = 'outlined', name, control, ...restProps } = props
  if (Boolean(control) && typeof name === 'string') {
    return (
      <Controller
        name={name}
        defaultValue=''
        control={control}
        render={(args) => {
          return (
            <TextField
              variant={variant}
              {...restProps}
              {...args.field}
              ref={ref}
              error={args.fieldState.invalid}
              helperText={args.fieldState.error?.message}
            />
          )
        }}
      />
    )
  }
  return <TextField variant={variant} name={name} {...restProps} ref={ref} />
})

ControllerTextField.displayName = 'ControllerTextField'
export default ControllerTextField
export * from '@mui/material/TextField'
