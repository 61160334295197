import { Slider as SliderMui, Typography as TypographyMui, styled } from '@mui/material'

export const Typography = styled(TypographyMui)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
})

export const Slider = styled(SliderMui)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#fff' : '#FF8B01',
  height: 6,
  '& .MuiSlider-thumb': {
    width: 12,
    height: 12,
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    '&:before': {
      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
    },
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${
        theme.palette.mode === 'dark' ? 'rgb(255 255 255 / 16%)' : 'rgb(0 0 0 / 16%)'
      }`,
    },
    '&.Mui-active': {
      width: 20,
      height: 20,
    },
  },
  '& .MuiSlider-rail': {
    opacity: 0.28,
  },
  '& .MuiSlider-mark': {
    color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary.main,
  },
  '& .MuiSlider-markActive': {
    background: theme.palette.primary.main,
  },
  '& .MuiSlider-markLabel': {
    top: -32,
  },
  marginBottom: 0,
}))
