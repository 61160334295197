import { RootState } from '@app/store'
import { isTokenExpired } from '@common/utils/tokens'
import { API_BASE_URL } from '@config'
import { logout, setAccessToken, waitForRehydration } from '@features/auth/auth-actions'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import queryString from 'query-string'

const mutex = new Mutex()

const baseQueryDefault = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, baseQueryApi) => {
    const { getState } = baseQueryApi
    const tokens = (getState() as RootState).auth.tokens
    if (tokens && !isTokenExpired(tokens.access_token.expiration_time)) {
      // include token in req header
      headers.set('authorization', `Bearer ${tokens.access_token?.token}`)
      return headers
    }
  },
})

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  fetchArgs,
  api,
  extraOptions,
) => {
  const args =
    typeof fetchArgs === 'string'
      ? fetchArgs
      : {
          ...fetchArgs,
          url: fetchArgs.params
            ? fetchArgs.url + '?' + queryString.stringify(fetchArgs.params)
            : fetchArgs.url,
          params: undefined,
        }

  const { getState } = api
  await api.dispatch(waitForRehydration(api)) // wait for persisted values not initial ones
  const tokens = await (getState() as RootState).auth.tokens

  // logout if refresh token expired
  if (tokens?.refresh_token && isTokenExpired(tokens.refresh_token.expiration_time)) {
    api.dispatch(logout())
  }

  // refresh the access token if expired
  if (tokens?.access_token && isTokenExpired(tokens.access_token.expiration_time)) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQueryDefault(
          {
            url: '/api/v1/sdk/auth/refresh',
            headers: { authorization: `Bearer ${tokens?.refresh_token?.token}` },
            method: 'POST',
          },
          api,
          extraOptions,
        )
        if (refreshResult.data) {
          api.dispatch(setAccessToken(refreshResult.data.access_token))
        } else {
          api.dispatch(logout())
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
    }
  }

  const result = await baseQueryDefault(args, api, extraOptions)
  return result
}

export default baseQuery
