import { LoadingButton } from '@mui/lab'
import { ButtonProps } from '@mui/material/Button'
import { Button as MuiButton } from './button.styles'

const Button = <C extends React.ElementType>(
  props: {
    floating?: boolean
    loading?: boolean
  } & ButtonProps<C, { component?: C }>,
): React.ReactElement => {
  const { loading, ...restProps } = props

  if (typeof loading === 'boolean') {
    return <LoadingButton loading={loading} {...restProps} />
  }
  return <MuiButton floating={props.floating} {...restProps} />
}

export default Button
export * from '@mui/material/Button'
