import { Box, Button as ButtonMui } from '@common/components'
import { Switch as SwitchMui } from '@mui/material'
import { styled } from '@common/utils/styles'

export const FlotingButton = styled(ButtonMui, {
  shouldForwardProp: (prop) => prop !== 'highlighted',
})(({ highlighted }) => ({
  textTransform: 'none',
  margin: '0 auto',
  display: 'flex',
  width: '200px',
  maxWidth: '100%',
  fontWeight: 'bold',
  padding: '10px 18px',
  fontSize: '16px',
  borderRadius: '30px',
  color: 'black',
  background: highlighted ? '#FED363' : 'white',
  transition: 'all .2s ease-in-out',
  transform: 'translate(5px, -5px)',
  border: 'none',
  boxShadow: highlighted
    ? 'inset 0px 0px 0px 0px #665428, -4px 4px 0 0 #665428'
    : 'inset 0px 0px 0px 1px #0c0f13, -4px 4px 0 0 #0c0f13',
  ':hover ': {
    transform: 'translate(0px, 0px)',
    boxShadow: 'inset 0px 0px 0px 0px #0c0f13, 0px 0px 3px 0 #0c0f13',
    background: highlighted ? '#FED363' : 'white',
  },
}))

export const DotBlinking = styled(Box)({
  width: '8px',
  height: '8px',
  background: '#ff9641',
  borderRadius: ' 50% !important',
  animation: 'blink 1.5s infinite',
  '@keyframes blink': {
    '50%': {
      opacity: '0',
    },
  },
})

export const Switch = styled(SwitchMui)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        // backgroundColor: '#665428',
        backgroundColor: '#ff9641',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    // backgroundColor: '#665428',
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))
