import { Box, CardActionArea, IconButton as IconButtonMui, styled } from '@mui/material'

export const CardAction = styled(CardActionArea, {
  shouldForwardProp: (prop) => prop !== 'direction',
})(({ direction }) => ({
  zIndex: '1111',
  position: 'absolute',
  top: '0',
  height: '100%',
  width: '72px',
  borderRadius: direction === 'left' ? '0 20px 20px  0' : '20px 0 0 20px',
  background: 'rgba(255,255,255,0.3)',
}))
