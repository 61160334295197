import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {
  Box,
  Button,
  ColorPicker,
  InputAdornment,
  Paper,
  Popover,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@common/components'
import { ThemeProvider, useTheme } from '@common/utils/styles'
import React, { useState } from 'react'
import { ContentState, EditorState } from 'draft-js'
import { convertToRaw } from 'draft-js'
import draftjsToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { CheckIcon, LinkIcon } from '@common/icons'
import { useTogglePopover } from '@common/hooks'
import lightTheme from '@styles/themes/light-theme'
import './styles.css'
interface TextEditorProps {
  htmlContent: string
  bgPickOption?: boolean
  onChange: (arg: string) => void
  color?: string
  onColorChange?: () => void
  onSave?: () => void
  onCancel?: () => void
  readOnly?: boolean
  onUpdate?: () => void
  handleChangeVideo?: () => void
  videoLink?: string
  handleChangeExternalLink?: () => void
  externalLink?: string
}
const TextEditor: React.FC<TextEditorProps> = (props) => {
  const {
    htmlContent,
    bgPickOption,
    handleChangeVideo,
    videoLink,
    handleChangeExternalLink,
    externalLink,
    onChange: handleChange,
    color,
    onColorChange,
    onSave,
    onCancel,
    readOnly,
    onUpdate,
    ...rest
  } = props
  const contentBlock = htmlToDraft(htmlContent ? htmlContent : '')
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  const [editorState, setEditorState] = useState(
    htmlContent ? EditorState.createWithContent(contentState) : EditorState.createEmpty(),
  )

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
    handleChange(draftjsToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  const getCustomButton = () => {
    const buttons = []
    if (bgPickOption) {
      buttons.push(<ColorPicker key={'1'} color={color} onChangeColor={onColorChange} />)
    }
    if (onCancel) {
      buttons.push(
        <Button
          onClick={() => onCancel()}
          color='error'
          key={'3'}
          variant='outlined'
          size='small'
          sx={{
            border: '1px solid rgba(0,0,0,0.1)',
            ml: 1,
            height: '31.6px',
            borderRadius: '0px',
            width: '40px',
          }}
        >
          cancel
        </Button>,
      )
    }
    if (onSave) {
      buttons.push(
        <Button
          onClick={() => onSave()}
          key={'2'}
          variant='outlined'
          size='small'
          sx={{
            border: '1px solid rgba(0,0,0,0.1)',
            ml: 1,
            height: '31.6px',
            borderRadius: '0px',
          }}
        >
          <CheckIcon />
          save
        </Button>,
      )
    }
    if (onUpdate) {
      buttons.push(
        <Button
          onClick={() => onUpdate()}
          key={'2'}
          variant='outlined'
          size='small'
          sx={{
            border: '1px solid rgba(0,0,0,0.1)',
            ml: 1,
            height: '31.6px',
            borderRadius: '0px',
          }}
        >
          <CheckIcon />
          save
        </Button>,
      )
    }
    // buttons.push(
    //   <ThemeProvider theme={lightTheme}>
    //     <Stack
    //       sx={{ pb: 1, height: '30px' }}
    //       direction='row'
    //       spacing={2}
    //       alignItems={'center'}
    //       width={'100%'}
    //     >
    //       <Typography>Include video</Typography>
    //       <Switch onChange={handleChangeSwitch} checked={checked} />
    //       {checked && (
    //         <TextField
    //           placeholder='Add YouTube link '
    //           value={videoLink}
    //           onChange={handleChangeVideo}
    //           variant='standard'
    //           InputProps={{
    //             endAdornment: (
    //               <InputAdornment position='end'>
    //                 <LinkIcon sx={{ transform: 'rotate(-45deg)' }} />
    //               </InputAdornment>
    //             ),
    //           }}
    //         />
    //       )}
    //     </Stack>
    //   </ThemeProvider>,
    // )
    // buttons.push(
    //   <ThemeProvider theme={lightTheme}>
    //     <Stack
    //       sx={{ pb: 1, height: '30px' }}
    //       direction='row'
    //       spacing={2}
    //       alignItems={'center'}
    //       width={'100%'}
    //     >
    //       <Typography>External link</Typography>
    //       <Switch onChange={handleChangeSwitchExternal} checked={checkedExternal} />
    //       {checkedExternal && (
    //         <TextField
    //           placeholder='Add External link '
    //           value={externalLink}
    //           onChange={handleChangeExternalLink}
    //           variant='standard'
    //           InputProps={{
    //             endAdornment: (
    //               <InputAdornment position='end'>
    //                 <LinkIcon sx={{ transform: 'rotate(-45deg)' }} />
    //               </InputAdornment>
    //             ),
    //           }}
    //         />
    //       )}
    //     </Stack>
    //   </ThemeProvider>,
    // )
    return buttons
  }

  return (
    <Box {...rest}>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName='wrapper-class'
        editorClassName='editor-class'
        toolbarClassName='toolbar-class'
        editorStyle={{
          padding: '0 7px 0',
          height: '240px',
        }}
        toolbarStyle={{
          display: 'flex',
          justifyContent: 'flex-start',
          // backgroundColor: theme.palette.background.paper,
          border: 'none',
        }}
        wrapperStyle={{
          width: '100%',
          height: '100%',
          background: color,
        }}
        toolbar={{
          options: ['inline', 'fontSize', 'colorPicker', 'textAlign'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          textAlign: {
            inDropdown: true,
          },
        }}
        toolbarCustomButtons={getCustomButton()}
        readOnly={readOnly}
      />
    </Box>
  )
}

export default TextEditor
