import { defer } from '@lib/router'
import { documentsApi } from '@app/services/documents'
import { store } from '@app/store'

const documentsLoader = async () => {
  const documents = promise()
  return defer({ documents })
}

const promise = async () => {
  const result = await Promise.all([
    store.dispatch(documentsApi.endpoints.documentsGetDocuments.initiate({})),
  ])

  return result
}

export default documentsLoader
