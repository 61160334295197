import * as React from 'react'
const GreenTicketIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={56} height={56} fill='none' {...props}>
    <rect width={48} height={48} x={4} y={4} fill='#DAE8CF' rx={24} />
    <rect width={48} height={48} x={4} y={4} stroke='#F2FEF3' strokeWidth={8} rx={24} />
    <g fill='#19B420' clipPath='url(#a)'>
      <path d='M38 31.375v-.114a1.768 1.768 0 0 0-1.34-1.698 1.75 1.75 0 0 1 0-3.376A1.768 1.768 0 0 0 38 24.49v-.114A4.38 4.38 0 0 0 33.625 20h-14A2.625 2.625 0 0 0 17 22.625v1.864a1.768 1.768 0 0 0 1.34 1.698 1.75 1.75 0 0 1 0 3.376A1.768 1.768 0 0 0 17 31.26v1.864a2.625 2.625 0 0 0 2.625 2.625h14A4.38 4.38 0 0 0 38 31.375Zm-19.25 1.75.055-1.875a3.5 3.5 0 0 0-.055-6.761v-1.864a.875.875 0 0 1 .875-.875h3.5v1.75a.875.875 0 1 0 1.75 0v-1.75h8.75a2.625 2.625 0 0 1 2.625 2.625l-.055.125a3.51 3.51 0 0 0-2.57 3.375 3.562 3.562 0 0 0 2.625 3.406v.094A2.625 2.625 0 0 1 33.625 34h-8.75v-1.75a.875.875 0 1 0-1.75 0V34h-3.5a.875.875 0 0 1-.875-.875Z' />
      <path d='M24.875 28.75V27a.875.875 0 1 0-1.75 0v1.75a.875.875 0 1 0 1.75 0Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M38 17.375v21H17v-21z' />
      </clipPath>
    </defs>
  </svg>
)
export default GreenTicketIcon
