import { Await, Outlet, useLoaderData } from '@lib/router'
import { ProfileContainer, SettingsContainer } from '@common/containers'
import { useToggle } from '@common/hooks'
import Sidebar from '../sidebar'
import { Drawer } from '@common/components'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@features/theme/theme.hooks'

const ProfileLayout: React.FC = () => {
  const { open, handleClose, handleOpen } = useToggle(false)
  const { user } = useLoaderData()
  const { theme } = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Await resolve={user}>
      <ProfileContainer>
        {matches ? (
          <Drawer anchor={'left'} open={open} onClose={handleClose}>
            <Sidebar background={false} />
          </Drawer>
        ) : (
          <Sidebar />
        )}
        <SettingsContainer handleOpen={handleOpen}>
          <Outlet />
        </SettingsContainer>
      </ProfileContainer>
    </Await>
  )
}

export default ProfileLayout
