import { DateCalendar, DateCalendarProps } from '@mui/x-date-pickers/DateCalendar'
import React from 'react'
import { Controller } from '@lib/form'

type ControllerDateCalendarProps = DateCalendarProps & {
  control?: any
}

const ControllerDateCalendar: React.FC<ControllerDateCalendarProps> = React.forwardRef(
  (props, ref) => {
    const { name, control, ...restProps } = props
    if (Boolean(control) && typeof name === 'string') {
      return (
        <Controller
          name={name}
          control={control}
          render={(args) => {
            return <DateCalendar {...restProps} {...args.field} ref={ref} />
          }}
        />
      )
    }
    return <DateCalendar name={name} {...restProps} ref={ref} />
  },
)

ControllerDateCalendar.displayName = 'ControllerDateCalendar'

export default ControllerDateCalendar
export * from '@mui/x-date-pickers/DateCalendar'
