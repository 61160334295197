import { Box } from '@common/components'
import { SxProps } from '@mui/material'
import React, { useEffect, useRef } from 'react'

interface AudioVisualizerProps {
  stream?: MediaStream | null
  backgroundColor?: string
  strokeColor?: string
  height?: number
  sx?: SxProps
}

const AudioVisualizer: React.FC<AudioVisualizerProps> = ({
  stream,
  backgroundColor = '#fff',
  strokeColor = '#2196f3',
  height = 70,
  sx,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const animationFrameRef = useRef<number>()
  const analyzerRef = useRef<AnalyserNode | null>(null)

  useEffect(() => {
    if (!stream) return

    const audioContext = new AudioContext()
    const source = audioContext.createMediaStreamSource(stream)
    const analyzer = audioContext.createAnalyser()
    analyzerRef.current = analyzer

    analyzer.fftSize = 2048
    analyzer.smoothingTimeConstant = 0.8
    source.connect(analyzer)

    const bufferLength = analyzer.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)

    const draw = () => {
      const canvas = canvasRef.current
      if (!canvas) return

      const canvasCtx = canvas.getContext('2d')
      if (!canvasCtx) return

      animationFrameRef.current = requestAnimationFrame(draw)

      analyzer.getByteTimeDomainData(dataArray)

      canvasCtx.fillStyle = backgroundColor
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height)

      canvasCtx.lineWidth = 2
      canvasCtx.strokeStyle = strokeColor
      canvasCtx.beginPath()

      const sliceWidth = canvas.width / bufferLength
      let x = 0

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0
        const y = (v * canvas.height) / 2

        if (i === 0) {
          canvasCtx.moveTo(x, y)
        } else {
          canvasCtx.lineTo(x, y)
        }

        x += sliceWidth
      }

      canvasCtx.lineTo(canvas.width, canvas.height / 2)
      canvasCtx.stroke()
    }

    animationFrameRef.current = requestAnimationFrame(draw)

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current)
      }
      audioContext.close()
    }
  }, [stream, backgroundColor, strokeColor])

  return (
    <Box
      sx={{
        borderRadius: 10,
        border: '1px solid #ccc',
        overflow: 'hidden',
        height,
        ...sx,
      }}
    >
      <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
    </Box>
  )
}

export default AudioVisualizer
