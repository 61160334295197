import { Box, Link, MenuItem, menuItemClasses, MenuItemProps } from '@common/components'
import { styled } from '@common/utils/styles'

export const UserOverviewContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '250px',
  padding: 16,
})

interface ExpandableMenuItemProps extends MenuItemProps {
  selected?: boolean
  isLanguage?: boolean
}

export const ExpandableMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'isLanguage',
})<ExpandableMenuItemProps>(({ selected, theme, isLanguage }) => ({
  width: '100%',
  paddingLeft: 35,
  background: selected ? theme.palette.primary.main : 'inherit',
  color: selected ? theme.palette.primary.contrastText : 'inherit',
  display: isLanguage ? 'flex' : 'inherit',
  justifyContent: isLanguage ? 'space-between' : 'inherit',
  '&:hover': {
    color: theme.palette.mode === 'light' ? 'black' : 'inherit',
  },
  [`${menuItemClasses.root}`]: {
    color: selected && theme.palette.primary.contrastText,
    backgroundColor: selected && theme.palette.primary.main,
  },
}))

export const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '64px',
  position: 'relative',
  zIndex: 25,
})

export const MetaData = styled(Box)({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '16px',
  letterSpacing: '0em',
})
