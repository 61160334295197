import { Card, CardActionArea } from '@common/components'
import { styled } from '@common/utils/styles'

export const WeblinkCard = styled(Card)(() => ({
  borderRadius: '10px',
  backgroundColor: '#0288d1',
  marginTop: '10px',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))
export const WeblinkCardActionArea = styled(CardActionArea)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '10px',
  paddingRight: '10px',
  height: '100%',
  maxWidth: '95%',
}))
