import { styled } from '@common/utils/styles'
import {
  InputBase,
  IconButton,
  Stack,
  InputBaseProps,
  inputBaseClasses,
  Box,
} from '@common/components'
interface InputFieldProps extends InputBaseProps {
  isError: boolean
}

export const InputField = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'isError',
})<InputFieldProps>(({ theme, isError }) => ({
  color: isError ? 'red' : theme.palette.text.primary,
  backgroundColor: 'white',
  boxShadow: theme.shadows[1],
  borderRadius: '11px',
  fontFamily: 'Poppins',
  fontSize: '36px',
  fontWeight: 600,
  textAlign: 'center',
  width: '60px',
  height: '60px',
  border: `1px solid ${isError ? 'red' : 'grey'}`,
  '&:hover': {
    appearance: 'none',
    outline: 'none',
    border: `1px solid ${theme.palette.text.primary}`,
  },
  '&:focus': {
    appearance: 'none',
    outline: 'none',
    border: `2px solid ${theme.palette.primary.main}`,
  },
  [`& .${inputBaseClasses.input}`]: {
    textAlign: 'center',
  },
}))

export const DayContainer = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  border: `2px solid ${theme.palette.primary.main}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: selected && theme.palette.primary.main,
  fontSize: '1rem',
  fontWeight: 600,
  color: selected ? 'white' : 'primary',
  ':hover': {
    background: theme.palette.primary.main,
    color: 'white',
  },
}))

export const TagsContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
  flexDirection: 'row',
  [theme.breakpoints.down(300)]: {
    flexDirection: 'column',
  },
}))

export const BoxDay = styled(Box)(({ theme }) => ({
  height: '56px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'rgba(230,230,230,0.6)',
  padding: '4px 6px',
  borderRadius: '5px 5px 0 0',
  borderBottom: `1.5px solid ${theme.palette.primary.main}`,
}))
