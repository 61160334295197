import { Box, Paper, Skeleton, Stack } from '@common/components'
import React from 'react'

const EventPlaceholder = () => {
  return (
    <Box width='100%' height='100vh' sx={{ position: 'relative' }}>
      {/* voicecall */}
      {/* <Stack
        direction="row"
        component={Paper}
        spacing={2}
        sx={{
          px: 2,
          py: 1,
          position: "absolute",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          borderRadius: "25px",
        }}
      >
        {[1, 2, 3].map((elem, index) => (
          <Box key={index} sx={{ p: 1 }}>
            <Skeleton
              variant="circular"
              width={120}
              height={120}
              sx={{ mb: 1 }}
            />
            <Skeleton />
          </Box>
        ))}
      </Stack> */}
      {/* chat */}
      <Paper
        sx={{
          position: 'absolute',
          top: 120,
          bottom: 200,
          maxHeight: 400,
          right: 0,
          width: '300px',
          p: 2,
        }}
      >
        {Array(3)
          .fill(null)
          .map((elem, k) => (
            <Box
              key={k}
              sx={{
                display: 'flex',
                justifyContent: k % 2 === 0 ? 'flex-start' : 'flex-end',
                alignItems: 'center',
              }}
            >
              <Skeleton variant='circular' width={35} height={35} sx={{ m: 1 }} />
              <Skeleton width='70%' height={40} />
            </Box>
          ))}
      </Paper>
      {/* buttons */}
      <Skeleton
        variant='circular'
        width={55}
        height={55}
        sx={{ position: 'absolute', top: 10, right: 10 }}
      />
      <Skeleton variant='rounded' width={200} height={55} />
      <Skeleton
        variant='rectangular'
        width={42}
        height={300}
        sx={{
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translate(0,-70%)',
        }}
      />
    </Box>
  )
}

export default EventPlaceholder
