import { Box, Chip, Skeleton, Stack } from '@common/components'

const vocabularyLoader = () => {
  return (
    <Box>
      <Skeleton variant='rounded' width='150px' height='28px' />
      <Stack direction='row' gap={1} sx={{ mt: 3, flexWrap: 'wrap' }}>
        {Array(4)
          .fill(null)
          .map((elem, index) => (
            <Skeleton key={index} variant='rounded' sx={{ borderRadius: '20px' }}>
              <Chip label='Deletable' />
            </Skeleton>
          ))}
      </Stack>
    </Box>
  )
}

export default vocabularyLoader
