import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    discussionGetConfig: build.query<DiscussionGetConfigApiResponse, DiscussionGetConfigApiArg>({
      query: () => ({ url: `/api/v1/extensions/discussion/config` }),
    }),
    discussionGenerateNewDiscussionTopic: build.mutation<
      DiscussionGenerateNewDiscussionTopicApiResponse,
      DiscussionGenerateNewDiscussionTopicApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/discussion/${queryArg.eventId}/${queryArg.roomId}/get_topic`,
        method: 'POST',
        body: queryArg.discussionTopicFilterRequest,
      }),
    }),
    discussionGetCurrentDiscussionTopic: build.query<
      DiscussionGetCurrentDiscussionTopicApiResponse,
      DiscussionGetCurrentDiscussionTopicApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/discussion/${queryArg.eventId}/${queryArg.roomId}`,
      }),
    }),
    discussionStartDiscussion: build.mutation<
      DiscussionStartDiscussionApiResponse,
      DiscussionStartDiscussionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/discussion/${queryArg.eventId}/start`,
        method: 'POST',
        params: { exit_: queryArg.exit },
      }),
    }),
    discussionExitDiscussion: build.mutation<
      DiscussionExitDiscussionApiResponse,
      DiscussionExitDiscussionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/discussion/${queryArg.eventId}/exit`,
        method: 'POST',
      }),
    }),
    discussionGetNewTopic: build.mutation<
      DiscussionGetNewTopicApiResponse,
      DiscussionGetNewTopicApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/discussion/${queryArg.eventId}/${queryArg.roomId}/topic`,
        method: 'POST',
        body: queryArg.discussionTopicFilterRequest,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type DiscussionGetConfigApiResponse = /** status 200 Successful Response */ DiscussionConfig
export type DiscussionGetConfigApiArg = void
export type DiscussionGenerateNewDiscussionTopicApiResponse =
  /** status 200 Successful Response */ DiscussionTopicResponse
export type DiscussionGenerateNewDiscussionTopicApiArg = {
  eventId: string
  roomId: number
  discussionTopicFilterRequest: DiscussionTopicFilterRequest
}
export type DiscussionGetCurrentDiscussionTopicApiResponse =
  /** status 200 Successful Response */ DiscussionTopicResponse | null
export type DiscussionGetCurrentDiscussionTopicApiArg = {
  eventId: string
  roomId: number
}
export type DiscussionStartDiscussionApiResponse = /** status 200 Successful Response */ any
export type DiscussionStartDiscussionApiArg = {
  eventId: string
  exit?: boolean
}
export type DiscussionExitDiscussionApiResponse = /** status 200 Successful Response */ any
export type DiscussionExitDiscussionApiArg = {
  eventId: string
}
export type DiscussionGetNewTopicApiResponse =
  /** status 200 Successful Response */ DiscussionTopicResponse
export type DiscussionGetNewTopicApiArg = {
  eventId: string
  roomId: number
  discussionTopicFilterRequest: DiscussionTopicFilterRequest
}
export type DiscussionConfig = {
  supported_languages: string[]
  metrics: string[]
}
export type LangaugeResponse = {
  id: number
  english_name: string
  native_name: string
  code: string
}
export type DiscussionTopicResponse = {
  laid_back_to_deep?: number | null
  personal_to_impersonal?: number | null
  suitable_more_for_two_individuals?: number | null
  phrase: string
  language: LangaugeResponse
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type DiscussionTopicFilterRequest = {
  laid_back_to_deep?: number | null
  personal_to_impersonal?: number | null
  suitable_more_for_two_individuals?: number | null
}
export const {
  useDiscussionGetConfigQuery,
  useLazyDiscussionGetConfigQuery,
  useDiscussionGenerateNewDiscussionTopicMutation,
  useDiscussionGetCurrentDiscussionTopicQuery,
  useLazyDiscussionGetCurrentDiscussionTopicQuery,
  useDiscussionStartDiscussionMutation,
  useDiscussionExitDiscussionMutation,
  useDiscussionGetNewTopicMutation,
} = injectedRtkApi
