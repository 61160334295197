import { Box, Skeleton, Stack } from '@common/components'

const AvailabilityPlaceholder = () => {
  return (
    <Box>
      <Skeleton width='150px' height='80px' />
      <Skeleton width='80px' height='50px' />
      <Skeleton width='80px' height='50px' />
      <Skeleton width='80px' height='50px' />
      <Skeleton width='80px' height='50px' />
      <Skeleton width='80px' height='50px' />
      <Skeleton width='80px' height='50px' />
      <Skeleton width='80px' height='50px' />
      <Stack direction='row' spacing={5} sx={{ width: '80%' }}>
        <Skeleton width='130px' height='60px' />
        <Skeleton width='130px' height='60px' />
      </Stack>
    </Box>
  )
}

export default AvailabilityPlaceholder
