import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    notificationGetUnreadNotificationsNumber: build.query<
      NotificationGetUnreadNotificationsNumberApiResponse,
      NotificationGetUnreadNotificationsNumberApiArg
    >({
      query: () => ({ url: `/api/v1/sdk/notification/notifications/unread/number` }),
    }),
    notificationGetNotifications: build.query<
      NotificationGetNotificationsApiResponse,
      NotificationGetNotificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/notification/notifications`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          order_by: queryArg.orderBy,
          order_type: queryArg.orderType,
        },
      }),
    }),
    notificationGetNotification: build.query<
      NotificationGetNotificationApiResponse,
      NotificationGetNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/notification/notifications/${queryArg.notificationId}`,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type NotificationGetUnreadNotificationsNumberApiResponse =
  /** status 200 Successful Response */ GetUnreadNotificationsNumberResponse
export type NotificationGetUnreadNotificationsNumberApiArg = void
export type NotificationGetNotificationsApiResponse =
  /** status 200 Successful Response */ NotificationDbModel[]
export type NotificationGetNotificationsApiArg = {
  limit?: number | null
  offset?: number | null
  orderBy?: string | null
  orderType?: string | null
}
export type NotificationGetNotificationApiResponse =
  /** status 200 Successful Response */ NotificationDbModel
export type NotificationGetNotificationApiArg = {
  notificationId: number
}
export type GetUnreadNotificationsNumberResponse = {
  number: number
}
export type NotificationDbModel = {
  id: number
  user_id?: number | null
  message_title?: string | null
  message_body_link?: string | null
  message_body_text?: string | null
  is_read_at?: string | null
  created_at?: string
  updated_at?: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export const {
  useNotificationGetUnreadNotificationsNumberQuery,
  useLazyNotificationGetUnreadNotificationsNumberQuery,
  useNotificationGetNotificationsQuery,
  useLazyNotificationGetNotificationsQuery,
  useNotificationGetNotificationQuery,
  useLazyNotificationGetNotificationQuery,
} = injectedRtkApi
