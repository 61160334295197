import { Container, Box, Skeleton, Grid } from '@common/components'
import { CenterContainer } from '@common/containers'

const HomePlaceholder = () => {
  return (
    <Container maxWidth='lg'>
      <CenterContainer fullScreen>
        <Grid container spacing={3}>
          <GridItem />
          <GridItem />
          <GridItem />
          <GridItem />
        </Grid>
      </CenterContainer>
    </Container>
  )
}

const GridItem = () => {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Skeleton
        variant='rectangular'
        sx={{
          height: { xs: '18vh', md: '230px' },
          width: '100%',
          maxWidth: { xs: '80%', md: '100%' },
        }}
      />
    </Grid>
  )
}
export default HomePlaceholder
