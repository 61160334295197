import { API_BASE_URL } from '@config'
import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './baseQuery'

// initialize an empty api service that we'll inject endpoints into later as needed
const api = createApi({
  baseQuery,
  endpoints: () => ({}),
})

export { api as baseApi }
