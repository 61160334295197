import { Box, Button, Modal, Typography } from '@common/components'
import CoolLaptop from '@assets/images/laptop.png'

interface CheckBrowserCompatProps {
  open: boolean
  onClose: () => void
}

const CheckBrowserCompat = ({ open, onClose }: CheckBrowserCompatProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      showCloseBtn={false}
      paperProps={{
        sx: {
          pt: 6,
          pb: 3,
          px: 3,
          width: '600px',
          maxWidth: '90%',
          textAlign: 'center',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          borderRadius: '10px',
          padding: '20px 60px',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 700,
            fontSize: '16px',
          }}
        >
          Unsupported Browser or Device Detected
        </Typography>

        <img
          src={CoolLaptop}
          style={{
            marginTop: '10px',
            aspectRatio: '1',
            objectFit: 'auto',
            objectPosition: 'center',
            width: 175,
            maxWidth: '100%',
          }}
        />
        <Typography
          variant='body1'
          fontWeight={500}
          margin='6px 15px 0 16px'
          sx={{
            textAlign: 'left',
          }}
        >
          To ensure the best experience on our platform, we currently support only the following
          configurations:
          <br />
          <br />
          <Typography variant='body1' fontWeight={700} component='span'>
            Browsers:{' '}
          </Typography>
          <Typography variant='body1' fontWeight={400} component='span'>
            Google Chrome or Mozilla Firefox
          </Typography>
          <br />
          <Typography variant='body1' fontWeight={700} component='span'>
            Devices:{' '}
          </Typography>
          <Typography variant='body1' fontWeight={400} component='span'>
            Windows PC, Apple Mac
          </Typography>
          <br />
          <br />
          <Typography variant='body1' fontWeight={400} component='span'>
            Please switch to one of the supported configurations to continue. We are working to
            expand support to more browsers and devices in the future.
          </Typography>
        </Typography>

        <Button
          sx={{
            marginTop: '20px',
            backgroundColor: '#CFD0EE',
            width: '100%',
            maxWidth: 445,
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            padding: '20px 60px',
            fontFamily: 'Work Sans, -apple-system, Roboto, Helvetica, sans-serif',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '132%',
            borderRadius: '0',
            textTransform: 'none',
            color: '#000',
          }}
          onClick={onClose}
        >
          Exit and Switch
        </Button>
      </Box>
    </Modal>
  )
}

export default CheckBrowserCompat
