import { Fab, Tooltip } from '@common/components'
import { useToggle } from '@common/hooks'
import { AddIcon } from '@common/icons'
import React from 'react'
import WeblinkModal from '../weblink-modal'

const AddWeblink = (props) => {
  const { open, handleClose, handleOpen } = useToggle(false)
  return (
    <>
      <Tooltip title='Add a new note' onClick={handleOpen} {...props}>
        <Fab color='primary' aria-label='edit'>
          <AddIcon />
        </Fab>
      </Tooltip>
      <WeblinkModal open={open} handleClose={handleClose} />
    </>
  )
}

export default AddWeblink
