import { SelectProps } from '@mui/material'
import { Controller } from '@lib/form'
import Select from '@mui/material/Select'

type ControllerSelectProps = SelectProps & {
  control?: any
}

const ControllerSelect: React.FC<ControllerSelectProps> = (props) => {
  const { name, control, ...restProps } = props
  if (Boolean(control) && typeof name === 'string') {
    return (
      <Controller
        name={name}
        control={control}
        render={(args) => {
          // console.log(args)
          return <Select {...restProps} {...args.field} />
        }}
      />
    )
  }
  return <Select name={name} {...restProps} />
}

export default ControllerSelect
export * from '@mui/material/Select'
