import { Box, Skeleton, Stack } from '@common/components'

const PreferredTeacherPlaceholder = () => {
  return (
    <Box>
      <Skeleton width='150px' height='50px' />
      <Stack
        direction={{ xs: 'column-reverse', md: 'column-reverse', lg: 'row' }}
        alignItems='center'
        spacing={{ xs: 1, lg: 3 }}
      >
        <Skeleton variant='rectangular' sx={{ width: '100%', maxWidth: '90%', height: '300px' }} />
      </Stack>
    </Box>
  )
}

export default PreferredTeacherPlaceholder
