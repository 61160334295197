import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    languagesGetLanguages: build.query<
      LanguagesGetLanguagesApiResponse,
      LanguagesGetLanguagesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/languages/languages`,
        params: { sort_country: queryArg.sortCountry },
      }),
    }),
    languagesGetLocation: build.query<LanguagesGetLocationApiResponse, LanguagesGetLocationApiArg>({
      query: () => ({ url: `/api/v1/sdk/languages/location` }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type LanguagesGetLanguagesApiResponse =
  /** status 200 Successful Response */ LanguageDbModel[]
export type LanguagesGetLanguagesApiArg = {
  sortCountry?: boolean | null
}
export type LanguagesGetLocationApiResponse = /** status 200 Successful Response */ any
export type LanguagesGetLocationApiArg = void
export type LanguageDbModel = {
  id: number
  english_name?: string | null
  native_name?: string | null
  code?: string | null
  is_room_language?: boolean | null
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export const {
  useLanguagesGetLanguagesQuery,
  useLazyLanguagesGetLanguagesQuery,
  useLanguagesGetLocationQuery,
  useLazyLanguagesGetLocationQuery,
} = injectedRtkApi
