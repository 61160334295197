import { Container, Box, Skeleton, Grid } from '@common/components'

const MarketplacePlaceholder = () => {
  return (
    <Container
      maxWidth='lg'
      sx={{
        minHeight: 'calc(100vh - 64px)',
        pt: 15,
      }}
    >
      <Grid container>
        <GridItem />
        <GridItem />
      </Grid>
    </Container>
  )
  return (
    <Container maxWidth='xs'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Skeleton width={300} height={60} />
        <Skeleton width={400} height={100} />
        <Skeleton width={400} height={100} />
        <Skeleton variant='text' width={200} />
        <Skeleton width={350} height={60} />
        <Skeleton variant='text' width={50} />
        <Skeleton width={350} height={60} />
      </Box>
    </Container>
  )
}

const GridItem = (props) => {
  const { children, title, sx } = props
  return (
    <Grid
      item
      md={6}
      xs={12}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Skeleton width={300} height={80} sx={{ mb: 3 }} />
      <Skeleton variant='rounded' sx={{ borderRadius: '25px' }} width={350} height={250} />
    </Grid>
  )
}

export default MarketplacePlaceholder
