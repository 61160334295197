const img1 =
  'https://testing-pengguin.s3.amazonaws.com/8f8b33df9211ca2a3b872a907f28b3b208d1c0876379f0f9d45b1782cb042999.png'
const img2 =
  'https://testing-pengguin.s3.amazonaws.com/7cd802d4eb251b9b9dbf9a3a4d6f98f1446cf9a323e632fc7c130feadadd5ca3.png'
const img3 =
  'https://testing-pengguin.s3.amazonaws.com/94a6c8fd74fe8a8fd71275498751f971eb35b64e2aeadab7073e21093ae4140b.png'
const img4 =
  'https://testing-pengguin.s3.amazonaws.com/32faa539a3824a8890c2aef927e207d457e0958523d6f1e1a50d49d33894ec39.png'
const img5 =
  'https://testing-pengguin.s3.amazonaws.com/d33329f81cd699ae88c079eec794873e486cbc413d2f212e39edbbf13d6ca76c.png'
const img6 =
  'https://testing-pengguin.s3.amazonaws.com/55aac611afff9073fd39034bccb3d8db5ebd0812aa51c1d9c8fba743ffe08016.png'
const img7 =
  'https://testing-pengguin.s3.amazonaws.com/a1e24d14e5dc9b8ed4fcbd8cd93eaeeae49ffc32f3e19c5e3606ef603bcb6029.png'
const img8 =
  'https://testing-pengguin.s3.amazonaws.com/9f4c7e913004f23b29fa04c830bea0d3669c68ccadb45d129e7690806047c538.png'

export const thumbnails = [img3, img1, img8, img4, img7, img6, img5, img2]
