import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Checkbox,
  Chip,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
  tooltipClasses,
} from '@common/components'
import { useLanguages } from '@features/languages/hooks'
import { useTheme } from '@features/theme/theme.hooks'
import React from 'react'

interface LanguageAutocompleteProps extends AutocompleteProps {
  placeholder: string
  control?: any
  name?: string
  options?: any
  defaultValue?: any
  isMultiple?: boolean
}

const LanguagesAutocomplete: React.FC<LanguageAutocompleteProps> = (props) => {
  const {
    placeholder,
    control,
    options,
    name,
    isMultiple = true,
    defaultValue,
    ...restProps
  } = props
  return (
    <Autocomplete
      filterSelectedOptions
      multiple={isMultiple}
      defaultValue={defaultValue}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      limitTags={4}
      options={options || useLanguages()}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option: any, index: number) => (
          <Chip
            {...getTagProps({ index })}
            key={index}
            label={
              <Stack direction='row' alignItems='center' spacing={1}>
                <div>{option.name}</div>
                <img
                  src={option.flag_image}
                  alt={option.name}
                  style={{ width: '25px', borderRadius: '2px' }}
                />
              </Stack>
            }
          />
        ))
      }}
      // isOptionEqualToValue={(option,value)=> option.code === value.code}
      getOptionLabel={(option: any) => option.name ?? ''}
      renderOption={(props, option, state) => (
        <Box component='li' {...props}>
          <Typography sx={{ flex: 1 }}>{option.name}</Typography>
          <img
            src={option.flag_image}
            alt={option.name}
            style={{ width: '25px', borderRadius: '2px' }}
          />
        </Box>
      )}
      renderInput={(params: any) => {
        return <TextField {...params} placeholder={placeholder ?? 'Choose a language'} />
      }}
      control={control}
      name={name}
      {...restProps}
    />
  )
}

// TODO ADD LANGUAGE LEVEL
interface LanguageLevelProps {
  level: number
  onChange: (arg: number) => void
  autoLevelOpen: boolean
}
const LanguageLevel: React.FC<LanguageLevelProps> = (props) => {
  const { level, onChange, autoLevelOpen, ...restProps } = props
  const [open, setOpen] = React.useState(autoLevelOpen)
  const handleTooltipClose = () => {
    setOpen(false)
  }
  const handleTooltipOpen = () => {
    setOpen((prevState) => !prevState)
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Box sx={{ mr: 1.5 }} {...restProps}>
          <Tooltip
            sx={{
              [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: '#E7F4FB',
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: 2,
                fontSize: 11,
              },
            }}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            placement='top'
            title={
              <FormGroup>
                {[
                  { label: 'Beginner', level: 1 },
                  { label: 'Intermediate', level: 2 },
                  { label: 'Advanced', level: 3 },
                ].map((elem) => (
                  <FormControlLabel
                    key={elem.level}
                    control={
                      <Checkbox
                        checked={elem.level === level}
                        onChange={() => {
                          onChange(elem.level)
                          setTimeout(handleTooltipClose, 200)
                        }}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography sx={{ minWidth: '120px' }} align='left'>
                          {elem.label}
                        </Typography>
                        <LevelDisplay level={elem.level} />
                      </Box>
                    }
                  />
                ))}
              </FormGroup>
            }
          >
            <LevelDisplay level={level} onClick={handleTooltipOpen} />
          </Tooltip>
        </Box>
      </ClickAwayListener>
    </>
  )
}

interface LevelDisplayProps {
  level: number
  onClick?: () => void
}

const LevelDisplay = React.forwardRef<HTMLDivElement, LevelDisplayProps>(
  ({ level, onClick, ...rest }, ref) => {
    const { theme } = useTheme()
    return (
      <Stack
        ref={ref}
        spacing='2px'
        direction='row'
        sx={{ height: '22px' }}
        onClick={onClick}
        {...rest}
      >
        {[0, 1, 2].map((elem, k) => (
          <Box
            key={k}
            sx={{
              cursor: 'pointer',
              width: '6px',
              height: '20px',
              backgroundColor: level > k ? theme.palette.primary.main : '#C4C4C4',
              borderRadius: '3px',
            }}
          ></Box>
        ))}
      </Stack>
    )
  },
)

LevelDisplay.displayName = 'LevelDisplay'

export default LanguagesAutocomplete
