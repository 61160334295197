import { useDispatch } from 'react-redux'
import { toast } from './toast.slice'

export const useToast = () => {
  const dispatch = useDispatch()
  const toastFunc = (message, severity = 'info') => {
    dispatch(toast({ message, severity }))
  }
  return toastFunc
}
