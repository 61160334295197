import { defer } from '@lib/router'
import { classroomApi } from '@app/services/classroom'
import { store } from '@app/store'

const stickyNotesLoader = async () => {
  const tasks = promise()
  return defer({ tasks })
}

const promise = async () => {
  const result = await Promise.all([
    store.dispatch(classroomApi.endpoints.classroomListClassroomTasks.initiate({})),
  ])

  return result
}

export default stickyNotesLoader
