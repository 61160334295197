import { Container, Box, Skeleton, Grid } from '@common/components'

const HomePagePlaceholder = () => {
  return (
    <>
      <Skeleton variant='rectangular' height={300} />
      <Container maxWidth='xs'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Skeleton
            variant='circular'
            sx={{
              width: '90px',
              height: '45px',
              borderRadius: '0 0 50px 50px',
            }}
          />
          <Skeleton width={350} height={60} sx={{ width: '350px', maxWidth: '100%' }} />
          <Skeleton variant='text' width={300} sx={{ width: '300px', maxWidth: '100%' }} />
          <Skeleton
            variant='rectangular'
            sx={{ my: 5, width: '480px', maxWidth: '100%', height: '270px', borderRadius: '11px' }}
          />
          <Skeleton
            variant='rectangular'
            height={24}
            sx={{ width: '420px', maxWidth: '100%', mb: 2, borderRadius: '30px' }}
          />
          <Skeleton
            variant='rectangular'
            height={20}
            sx={{ width: '420px', maxWidth: '100%', mb: 2, borderRadius: '30px' }}
          />
        </Box>
      </Container>
      <Container maxWidth='sm' sx={{ my: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Skeleton
              variant='rectangular'
              height={470}
              sx={{ width: '100%', maxWidth: '270px', mb: 2, mx: 'auto' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton
              variant='rectangular'
              height={470}
              sx={{ width: '100%', maxWidth: '270px', mb: 2, mx: 'auto' }}
            />
          </Grid>
        </Grid>
      </Container>
      <Skeleton variant='rectangular' height={250} sx={{ mt: 6 }} />
    </>
  )
}

export default HomePagePlaceholder
