export const convertToSeconds = (time) => {
  const splitTime = time.split(':')
  const minutes = parseInt(splitTime[0])
  const seconds = parseInt(splitTime[1])
  const totalSeconds = minutes * 60 + seconds
  return totalSeconds
}

export const formatSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(remainingSeconds.toFixed(2)).padStart(
    remainingSeconds < 10 ? 5 : 2,
    '0',
  )

  return `${formattedMinutes}:${formattedSeconds}`
}
