import { RootState } from '@app/store'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentDiscussion: null,
  isDiscussionOpen: false,
}

const discussionSlice = createSlice({
  name: 'discussion',
  initialState,
  reducers: {
    setCurrentDiscussion: (state, action) => {
      state.currentDiscussion = action.payload
    },
    setIsDiscussionOpen: (state, action) => {
      state.isDiscussionOpen = action.payload
    },
  },
})

export default discussionSlice.reducer

export const selectCurrentDiscussion = (state: RootState) => state.panels.currentDiscussion
export const selectIsDiscussionOpen = (state: RootState) => state.panels.isDiscussionOpen

export const { setCurrentDiscussion, setIsDiscussionOpen } = discussionSlice.actions
