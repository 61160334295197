import { Box, styled } from '@mui/material'
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'

const CodeInput = React.forwardRef(
  ({ code, setCode, status, sx, length, focus, inputMode = 'numeric', ...rest }, ref) => {
    const digit1El = useRef(null)
    const digit2El = useRef(null)
    const digit3El = useRef(null)
    const digit4El = useRef(null)
    const digit5El = useRef(null)
    const digit6El = useRef(null)
    const [digit1, setDigit1] = useState(code && code[0] ? code[0] : '')
    const [digit2, setDigit2] = useState(code && code[1] ? code[1] : '')
    const [digit3, setDigit3] = useState(code && code[2] ? code[2] : '')
    const [digit4, setDigit4] = useState(code && code[3] ? code[3] : '')
    const [digit5, setDigit5] = useState(code && code[4] ? code[4] : '')
    const [digit6, setDigit6] = useState(code && code[5] ? code[5] : '')

    const digits = [
      { ref: digit1El, value: digit1, setValue: setDigit1 },
      { ref: digit2El, value: digit2, setValue: setDigit2 },
      { ref: digit3El, value: digit3, setValue: setDigit3 },
      { ref: digit4El, value: digit4, setValue: setDigit4 },
      { ref: digit5El, value: digit5, setValue: setDigit5 },
      { ref: digit6El, value: digit6, setValue: setDigit6 },
    ].slice(0, length)

    const handleChange = (e, elem, k) => {
      if (e.target.value && e.target.value.length === 1) {
        elem.setValue(e.target.value)
        if (k < digits.length - 1) {
          digits[k + 1].ref.current.focus()
        }
      } else if (e.target.value === '') {
        elem.setValue(e.target.value)
        if (k > 0) {
          digits[k - 1].ref.current.focus()
        }
      } else {
        elem.current.select()
      }
    }

    const handleKeyDown = (e, index) => {
      if (e.key === 'ArrowRight' && index < length - 1) {
        digits[index + 1].ref.current.focus()
      }

      if (e.key === 'ArrowLeft' && index > 0) {
        digits[index - 1].ref.current.focus()
      }
    }

    useEffect(() => {
      setCode(`${digit1}${digit2}${digit3}${digit4}${digit5}${digit6}`)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [digit1, digit2, digit3, digit4, digit5, digit6])

    useEffect(() => {
      if (focus) {
        digits[0].ref.current.focus()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focus])

    useImperativeHandle(ref, () => ({
      reset() {
        setDigit1('')
        setDigit2('')
        setDigit3('')
        setDigit4('')
        setDigit5('')
        setDigit6('')
        digits[0].ref.current.focus()
      },
    }))

    return (
      <Box
        sx={{
          minWidth: length * 70,
          ...sx,
          display: { xs: 'flex' },
          justifyContent: { xs: 'center', sm: 'flex-start' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: { xs: 'wrap', sm: 'unset' },
            width: { xs: '235px', sm: '100%' },
            gap: { xs: '20px', sm: '0px', md: '5px', lg: '0px' },
            mb: 1,
          }}
        >
          {digits.slice(0, length).map((elem, index) => (
            <StyledTextField
              key={index}
              ref={elem.ref}
              value={elem.value}
              onChange={(e) => handleChange(e, elem, index)}
              onFocus={() => elem.ref.current.select()}
              type='text'
              onPaste={(e) => {
                e.preventDefault()
                const text = e.clipboardData.getData('Text')
                digits.forEach((elem, k) => {
                  elem.setValue(text[k])
                })
              }}
              onKeyDown={(e) => handleKeyDown(e, index)}
              inputMode={inputMode}
              status={status}
            />
          ))}
        </Box>
      </Box>
    )
  },
)

CodeInput.defaultProps = {
  length: 4,
  status: 'neutral',
}

const handleStatus = (status, success, error, neutral) => {
  switch (status) {
    case 'success':
      return success
    case 'error':
      return error
    case 'neutral':
      return neutral
    default:
      return neutral
  }
}

const StyledTextField = styled('input')(({ status, theme }) => ({
  color: handleStatus(status, 'green', 'red', 'grey'),
  backgroundColor: 'white',
  boxShadow: theme.shadows[1],
  borderRadius: '10px',
  fontFamily: 'Poppins',
  fontSize: '36px',
  fontWeight: 600,
  textAlign: 'center',
  width: '60px',
  height: '60px',
  border: handleStatus(status, '2px solid green', '2px solid red', '1px solid grey'),
  '&:focus': {
    appearance: 'none',
    outline: 'none',
    border: `3px solid ${theme.palette.primary.main}`,
  },
}))

export default CodeInput
