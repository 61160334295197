import React from 'react'
import {
  Box,
  CardActionArea,
  Container,
  EmblaCarousel,
  EmblaSlider,
  Skeleton,
} from '@common/components'
import { PlayerWrapper } from './task-carousel-view.styles'
import ReactPlayer from 'react-player'
import Task from '../task'

const TaskCarouselView = (props: { tasks: any; onClick?: () => void }) => {
  const { tasks, onClick: handleClick } = props
  const handleClickExternal = (link: string) => {
    if (!link.startsWith('http://') && !link.startsWith('https://')) {
      link = 'http://' + link
    }
    window.open(link, '_blank')
  }
  const handleCardClick = (note) => {
    if (note?.external_link) {
      handleClickExternal(note?.external_link)
    } else {
      if (handleClick) {
        handleClick()
      }
    }
  }
  return (
    <Container maxWidth='md'>
      <EmblaCarousel sliders={tasks}>
        {tasks?.map((note, k) => (
          <EmblaSlider key={k}>
            <Box sx={{ display: 'flex', height: '100%' }}>
              {note?.video_link && (
                <PlayerWrapper
                  direction={note?.video_link.includes('shorts') ? 'vertical' : 'horizontal'}
                >
                  <ReactPlayer
                    fallback={
                      <Skeleton
                        variant='rectangular'
                        sx={{
                          borderRadius: '10px',
                          height: '100%',
                          width: '100%',
                        }}
                      />
                    }
                    showinfo={false}
                    volume={0.8}
                    url={note?.video_link}
                    // controls
                    loop={note?.video_link.includes('shorts') ? true : false}
                    width='100%'
                    height='100%'
                    style={{ position: 'absolute', top: 0, left: 0 }}
                    config={{
                      youtube: {
                        playerVars: { modestbranding: 0, showinfo: 0, loop: 1 },
                      },
                    }}
                  />
                </PlayerWrapper>
              )}
              <ControlledCardActionArea
                onClick={
                  !note?.external_link && !handleClick ? undefined : () => handleCardClick(note)
                }
                sx={{ flex: 1 }}
              >
                <Task note={note} />
              </ControlledCardActionArea>
            </Box>
          </EmblaSlider>
        ))}
      </EmblaCarousel>
    </Container>
  )
}

const ControlledCardActionArea = (props: { onClick?: () => void; children: React.ReactNode }) => {
  const { onClick, children, ...restProps } = props
  if (!onClick) return <Box {...restProps}>{children}</Box>
  else
    return (
      <CardActionArea
        component='div'
        onClick={onClick}
        sx={{ p: 0, height: '100%', position: 'relative' }}
        {...restProps}
      >
        {children}
      </CardActionArea>
    )
}

export default TaskCarouselView
