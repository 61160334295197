import React from 'react'
import { default as ReactCountdownComponent } from 'react-countdown'
import { CountdownWrapper, NumberCard, NumbersWrapper, SeperatorWrapper } from './Countdown.styles'

interface CountdownProps {
  size: 'small' | 'medium'
  startTime: string | number | Date | undefined
  CustomCountdownComponent?: React.ComponentType<{
    time: { hours: number; minutes: number; seconds: number }
  }>
  CustomCompletionist?: React.ComponentType
  [key: string]: any
}

interface RendererProps {
  hours: number
  minutes: number
  seconds: number
  completed: boolean
}

const Countdown: React.FC<CountdownProps> = (props) => {
  const {
    size = 'medium',
    startTime,
    CustomCountdownComponent,
    CustomCompletionist,
    ...restProps
  } = props
  const Completionist = () => <span>You are good to go!</span>
  const TimeDisplay: React.FC<{ time: number }> = (time) => {
    return (
      <NumbersWrapper {...restProps}>
        {time.time
          .toString()
          .split('')
          .map((i, k) => {
            return parseInt(i) <= 10 && time.time.toString().split('').length === 1 ? (
              <React.Fragment key={k}>
                <NumberCard size={size}>0</NumberCard>
                <NumberCard size={size}>{i}</NumberCard>
              </React.Fragment>
            ) : (
              <NumberCard key={k} size={size}>
                {i}
              </NumberCard>
            )
          })}
      </NumbersWrapper>
    )
  }
  const renderer = ({ hours, minutes, seconds, completed }: RendererProps) => {
    if (completed) {
      return (CustomCompletionist && <CustomCompletionist />) || <Completionist />
    } else {
      return (
        (CustomCountdownComponent && (
          <CustomCountdownComponent time={{ hours, minutes, seconds }} />
        )) || (
          <CountdownWrapper direction={'row'} spacing={size === 'small' ? 0.5 : 1}>
            <TimeDisplay time={hours} />
            <SeperatorWrapper fontSize={size === 'small' ? '16px' : '25px'}>:</SeperatorWrapper>
            <TimeDisplay time={minutes} />
            <SeperatorWrapper fontSize={size === 'small' ? '16px' : '25px'}>:</SeperatorWrapper>
            <TimeDisplay time={seconds} />
          </CountdownWrapper>
        )
      )
    }
  }

  return <ReactCountdownComponent renderer={renderer} date={startTime}></ReactCountdownComponent>
}

export default Countdown
