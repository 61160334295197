import React from 'react'
import {
  Box,
  IconButton,
  PaperProps,
  Stack,
  Typography,
  Avatar as MuiAvatar,
  Badge,
  Grid,
  Container,
  Tooltip,
  Button,
  Popper,
} from '@common/components'
import { handleGravatar } from '@common/utils/gravatar'
import MicIcon from '@mui/icons-material/Mic'
import MicOffIcon from '@mui/icons-material/MicOff'
import ScreenShareIcon from '@mui/icons-material/ScreenShare'
import ShieldIcon from '@mui/icons-material/Shield'
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare'
import VideocamIcon from '@mui/icons-material/Videocam'
import VideocamOffIcon from '@mui/icons-material/VideocamOff'
import Paper from '@mui/material/Paper'
import { ICameraVideoTrack } from 'agora-rtc-sdk-ng'
import { useTheme } from '@features/theme/theme.hooks'
import { useCurrentEvent } from '@features/event/hooks'
import { useUser } from '@features/auth/hooks'
import { useAppDispatch, useAppSelector, useMediaQuery, useToggle } from '@common/hooks'
import { useNavigate } from 'react-router-dom'
import { setKickedFromEvent } from '@features/inventory/inventory.slice'
import { DASHBOARD_ROUTE, FEEDBACK_ROUTE } from '@common/constants/routes'
import UnmuteUserModal from '../../unmute-user-modal'
import endCallIcon from '@assets/svg/end-call-icon.svg'
import {
  PublicUserProfile,
  useEventDisableVideoUserEventMutation,
  useEventEnableVideoUserEventMutation,
  useEventMuteEventMutation,
  useEventUnmuteEventMutation,
} from '@app/services'
import KickStudentModal from '../../kick-student-modal'
import {
  BackHandIcon,
  BlurOffIcon,
  CancelOutlinedIcon,
  FlagIcon,
  LensBlurIcon,
  LoopIcon,
  MicOffOutlinedIcon,
  Person2OutlinedIcon,
  PersonAddAltIcon,
  RecordVoiceOverOutlinedIcon,
  VideocamOffOutlinedIcon,
  VideocamOutlinedIcon,
} from '@common/icons'
import { MicNoneOutlined } from '@mui/icons-material'
import { AgoraVideoPlayer } from '@features/agora/lib/agora-rtc'
import chatContext from '../../chat/context/chat.context'
import { selectCurrentRtcRoom } from '@features/panels/panels.slice'
import videoCallContext from '../context/videocall.context'
import ReportUser from '@features/auth/components/report-user/report-user'
import FollowUser from '@features/auth/components/follow-user'

type User = {
  pseudo: string
}

type VideoCallProps = PaperProps & {
  user: User
  remoteUsers: User[]
}

const resolveAvatarHeight = (totalUsers: number) => {
  switch (totalUsers) {
    case 1:
      return '500px'
    case 2:
      return '400px'
    case 3:
      return '250px'
    default:
      return '200px'
  }
}

const VideoCall = React.forwardRef<HTMLDivElement, VideoCallProps>((props, ref) => {
  const {
    users,
    volumes,
    joined,
    tracks,
    trackState,
    onMuteToggle,
    onWebcamToggle,
    onScreenShareToggle,
    changeSpeakerDevice,
    ...restProps
  } = props
  const { event } = useCurrentEvent()
  const { user, isHost } = useUser(event?.host.id)
  const { theme } = useTheme()
  const isMobile = useMediaQuery(900)
  // console.log(users)
  const navigate = useNavigate()
  const handleEndCall = () => {
    navigate(FEEDBACK_ROUTE)
  }
  const currentRtcRoom = useAppSelector(selectCurrentRtcRoom)
  const { mainRoomSettings } = React.useContext(videoCallContext)
  const { handleSendMessage } = React.useContext(chatContext)
  const [isRaiseHandDisabled, setIsRaiseHandDisabled] = React.useState(false)
  const handleRaiseHand = () => {
    setIsRaiseHandDisabled(true)
    handleSendMessage('#raise_hand')
    setTimeout(() => {
      setIsRaiseHandDisabled(false)
    }, 5000)
  }
  const disabledAudio = !mainRoomSettings.audio && !isHost && currentRtcRoom?.is_main
  const disabledVideo = !mainRoomSettings.video && !isHost && currentRtcRoom?.is_main
  return (
    <Paper
      elevation={3}
      {...restProps}
      sx={{ borderRadius: isMobile ? '15px' : '25px', ...restProps.sx }}
    >
      <Stack
        direction='row'
        component={Paper}
        elevation={7}
        spacing={3}
        sx={{ p: 2, borderRadius: isMobile ? '15px' : '25px' }}
      >
        {users.slice(0, isMobile ? (users.length > 2 ? 1 : 2) : 4).map((elem, index) =>
          isHost ? (
            <TooltipAvatar
              key={index}
              videoTrack={elem.videoTrack}
              muted={elem?._audio_muted_}
              host={event?.host?.id.toString() === elem.uid}
              volume={volumes.find((el) => el.uid === elem.uid)?.level}
              // id={elem?.uid}
              id={parseInt(elem?.uid)}
              eventId={event?.event_id}
              src={handleGravatar(
                event?.users?.find((user) => user?.id?.toString() === elem.uid)?.image,
              )}
            />
          ) : (
            <Avatar
              key={index}
              videoTrack={elem.videoTrack}
              muted={elem?._audio_muted_}
              host={event?.host?.id.toString() === elem.uid}
              volume={volumes.find((el) => el.uid === elem.uid)?.level}
              src={handleGravatar(
                event?.users?.find((user) => user?.id?.toString() === elem.uid)?.image,
              )}
            />
          ),
        )}
        {users.length > (isMobile ? 2 : 5) && (
          <Tooltip
            title={
              <>
                {users.slice(isMobile ? 1 : 5, users.length).map((el, index) => (
                  <Typography key={index} sx={{ p: 1 }}>
                    {event?.users?.find((elem) => elem?.id?.toString() === el.uid)?.username ?? ''}
                  </Typography>
                ))}
              </>
            }
          >
            <MuiAvatar
              sx={{
                width: isMobile ? '60px' : '150px',
                height: isMobile ? '60px' : '150px',
                boxShadow: theme.shadows[3],
                zIndex: 1,
              }}
              // src={user?.image ? handleGravatar(user.image) : undefined}
            >
              <Typography variant='h4' fontWeight={500}>
                {users.length - (isMobile ? 1 : 5)}+
              </Typography>
            </MuiAvatar>
          </Tooltip>
        )}
        <Avatar
          muted={!trackState.audio}
          videoTrack={
            Array.isArray(tracks) && trackState?.video && tracks[1] ? tracks[1] : undefined
          }
          host={user?.id.toString() === event?.host?.id?.toString()}
          volume={volumes.find((el) => el.uid === user?.id.toString())?.level}
          src={user?.image ? handleGravatar(user?.image) : undefined}
        />
      </Stack>
      <Stack direction='row' alignItems='center' sx={{ px: 2, py: 1 }} spacing={3}>
        {users.slice(0, isMobile ? (users.length > 2 ? 1 : 2) : 4).map((user, index) => {
          return (
            <Box key={index} sx={{ width: isMobile ? '60px' : '150px' }}>
              <Typography variant='body1' align='center' noWrap>
                {event?.users?.find((elem) => elem?.id?.toString() === user?.uid)?.username ?? ''}
              </Typography>
            </Box>
          )
        })}
        {users.length > (isMobile ? 2 : 5) && (
          <Box sx={{ width: isMobile ? '50px' : '150px' }}></Box>
        )}
        <Box
          sx={{
            width: isMobile ? '60px' : '150px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Stack
            direction='row'
            justifyContent='center'
            spacing={1}
            sx={{
              width: isMobile ? '60px' : '150px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Tooltip title={disabledAudio ? 'The host mutes all' : ''} placement='top'>
              <span>
                <IconButton disabled={disabledAudio} size='small' onClick={onMuteToggle}>
                  {trackState.audio ? <MicIcon /> : <MicOffIcon />}
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={disabledVideo ? 'The host has turned off webcams for everyone' : ''}
              placement='top'
            >
              <span>
                <IconButton disabled={disabledVideo} size='small' onClick={onWebcamToggle}>
                  {trackState.video ? <VideocamIcon /> : <VideocamOffIcon />}
                </IconButton>
              </span>
            </Tooltip>
            {isHost ? (
              <IconButton size='small' onClick={onScreenShareToggle}>
                {trackState.screen ? <ScreenShareIcon /> : <StopScreenShareIcon />}
              </IconButton>
            ) : (
              <IconButton disabled={isRaiseHandDisabled} onClick={handleRaiseHand} size='small'>
                <BackHandIcon />
              </IconButton>
            )}
            <IconButton size='small' onClick={handleEndCall}>
              <img src={endCallIcon} alt='end call' width={24} />
            </IconButton>
            {/* <IconButton size='small'>{true ? <LensBlurIcon /> : <BlurOffIcon />}</IconButton> */}
          </Stack>
        </Box>
      </Stack>
    </Paper>
  )
})

VideoCall.displayName = 'VideoCall'

interface AvatarProps {
  id: number
  volume: number
  muted?: boolean
  host?: boolean
  videoTrack: ICameraVideoTrack
  src: string | undefined
  isChat?: boolean
  placement?: 'top' | 'bottom' | 'left' | 'right'
}

interface TooltipAvatarProps {
  volume: number
  muted?: boolean
  host?: boolean
  videoTrack: ICameraVideoTrack
  id: number
  eventId: string
  isChat?: boolean
  placement?: 'top' | 'bottom' | 'left' | 'right'
}

export const Avatar = (props: AvatarProps) => {
  const {
    volume,
    muted,
    host,
    id,
    videoTrack,
    src,
    placement = 'top',
    isChat,
    ...restProps
  } = props
  const mediaRef = React.useRef(null)
  const { theme } = useTheme()
  const isMobile = useMediaQuery(900)
  const { event } = useCurrentEvent()
  const currentRtcRoom = useAppSelector(selectCurrentRtcRoom)

  React.useEffect(() => {
    if (videoTrack && mediaRef.current && videoTrack.play) {
      videoTrack.play(mediaRef.current)
    }
  }, [videoTrack, mediaRef.current])

  return (
    <Tooltip
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            display: currentRtcRoom?.is_main ? 'none' : 'flex',
            // height: '180px',
            maxWidth: '400px',
            borderRadius: '15px',
            backgroundColor: '#102845',
            '& .MuiTooltip-arrow': {
              color: '#102845',
            },
          },
        },
      }}
      title={
        <Stack
          spacing={2}
          sx={{
            p: 2,
          }}
        >
          <Box sx={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
            <PersonAddAltIcon sx={{ color: '#8F9BBA', mr: '20px' }} />
            <FollowUser
              userToFollow={event?.users?.find((u) => u.id === id) as PublicUserProfile}
            />
          </Box>
          <Box sx={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
            <FlagIcon sx={{ color: '#8F9BBA', mr: '20px' }} />
            <ReportUser
              lightColor='rgba(255,255,255,0.7)'
              userToReport={event?.users?.find((u) => u.id === id) as PublicUserProfile}
            />
          </Box>
        </Stack>
      }
      placement={placement}
    >
      <Badge
        overlap='circular'
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        invisible={!muted || isChat}
        sx={{ '&	.MuiBadge-badge': { zIndex: 999 } }}
        badgeContent={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              backgroundColor: 'background.paper',
              width: '25px',
              height: '25px',
            }}
          >
            <MicOffIcon sx={{ fontSize: '17px' }} />
          </Box>
        }
      >
        <Badge
          overlap='circular'
          invisible={!host || isChat}
          sx={{ '&	.MuiBadge-badge': { zIndex: 998 } }}
          badgeContent={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                backgroundColor: 'primary.main',
                width: '25px',
                height: '25px',
              }}
            >
              <ShieldIcon sx={{ fontSize: '17px' }} />
            </Box>
          }
        >
          <Box
            sx={{
              position: 'relative',
              borderRadius: '50%',
              outline: volume > 60 ? '5px solid green' : '0px solid rgba(0,0,0,0)',
              transition: '.2s',
            }}
          >
            {videoTrack ? (
              <Box
                ref={mediaRef}
                sx={{
                  position: 'relative',
                  width: isMobile ? '60px' : '150px',
                  height: isMobile ? '60px' : '150px',
                  borderRadius: '50%',
                  boxShadow: theme.shadows[3],
                  zIndex: 200,
                  overflow: 'hidden',
                  backgroundColor: 'black',
                }}
              />
            ) : (
              <MuiAvatar
                src={src}
                {...restProps}
                sx={{
                  width: isMobile ? '60px' : '150px',
                  height: isMobile ? '60px' : '150px',
                  boxShadow: theme.shadows[3],
                  zIndex: 1,
                  ...restProps.sx,
                }}
              />
            )}
            {/* <Box
            component={motion.div}
            initial={{ opacity: 0.5, scale: 0.5 }}
            animate={{ opacity: 0.5 + volume , scale: volume / 5 + 0.95 }}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '150px',
              height: '150px',
              backgroundColor: 'green',
              borderRadius: '50%',
            }}
          /> */}
          </Box>
        </Badge>
      </Badge>
    </Tooltip>
  )
}

export const TooltipAvatar = (props: TooltipAvatarProps) => {
  const { volume, muted, host, videoTrack, id, isChat, placement = 'top', ...restProps } = props
  const mediaRef = React.useRef(null)
  const { theme } = useTheme()
  const { open, handleClose, handleOpen } = useToggle(false)
  const [muteUser] = useEventMuteEventMutation()
  const [unmuteUser] = useEventUnmuteEventMutation()
  const [disableCamera] = useEventDisableVideoUserEventMutation()
  const [enableCamera] = useEventEnableVideoUserEventMutation()
  const { event } = useCurrentEvent()
  const isMobile = useMediaQuery(900)
  const { handleSendMessage } = React.useContext(chatContext)
  const handleReload = (userId: number) => {
    handleSendMessage(`#reload_page_${userId}`)
  }
  React.useEffect(() => {
    if (videoTrack && mediaRef.current && videoTrack.play) {
      videoTrack.play(mediaRef.current)
    }
  }, [videoTrack, mediaRef.current])

  return (
    <>
      <KickStudentModal open={open} onClose={handleClose} id={id} />
      <Tooltip
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              // height: '180px',
              maxWidth: '400px',
              borderRadius: '15px',
              backgroundColor: '#102845',
              '& .MuiTooltip-arrow': {
                color: '#102845',
              },
            },
          },
        }}
        title={
          <Box
            sx={{
              padding: '10px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <RecordVoiceOverOutlinedIcon sx={{ color: '#8F9BBA' }} />
              {muted ? (
                <Button
                  startIcon={<MicNoneOutlined />}
                  variant='contained'
                  sx={{ marginLeft: '20px' }}
                  onClick={() => {
                    unmuteUser({ eventId: event?.event_id, userId: id })
                  }}
                >
                  Ask to unmute
                </Button>
              ) : (
                <Button
                  startIcon={<MicOffOutlinedIcon />}
                  variant='outlined'
                  sx={{ marginLeft: '20px' }}
                  onClick={() => {
                    muteUser({ eventId: event?.event_id, userId: id })
                  }}
                >
                  Turn off mic
                </Button>
              )}
            </Box>
            <Box sx={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
              <VideocamOutlinedIcon sx={{ color: '#8F9BBA' }} />
              {videoTrack ? (
                <Button
                  startIcon={<VideocamOffOutlinedIcon />}
                  variant='outlined'
                  sx={{ marginLeft: '20px' }}
                  onClick={() => {
                    disableCamera({ eventId: event?.event_id, userId: id })
                  }}
                >
                  Turn off camera
                </Button>
              ) : (
                <Button
                  startIcon={<VideocamOutlinedIcon />}
                  variant='contained'
                  sx={{ marginLeft: '20px' }}
                  onClick={() => {
                    enableCamera({ eventId: event?.event_id, userId: id })
                  }}
                >
                  Ask to turn on webcam
                </Button>
              )}
            </Box>
            <Box sx={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
              <Person2OutlinedIcon sx={{ color: '#8F9BBA' }} />
              <Button
                startIcon={<CancelOutlinedIcon />}
                variant='outlined'
                sx={{
                  marginLeft: '20px',
                  color: '#DC2626',
                  borderColor: '#DC2626',
                  ':hover': { borderColor: '#DC2626' },
                }}
                onClick={handleOpen}
              >
                Kick
              </Button>
            </Box>
            <Box sx={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
              <LoopIcon sx={{ color: '#8F9BBA' }} />
              <Button
                startIcon={<LoopIcon />}
                variant='outlined'
                color='primary'
                sx={{
                  marginLeft: '20px',
                }}
                onClick={() => handleReload(id)}
              >
                Reload
              </Button>
            </Box>
            <Box sx={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
              <PersonAddAltIcon sx={{ color: '#8F9BBA', mr: '20px' }} />
              <FollowUser
                userToFollow={event?.users?.find((u) => u.id === id) as PublicUserProfile}
              />
            </Box>
            <Box sx={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
              <FlagIcon sx={{ color: '#8F9BBA', mr: '20px' }} />
              <ReportUser
                lightColor='rgba(255,255,255,0.7)'
                userToReport={event?.users?.find((u) => u.id === id) as PublicUserProfile}
              />
            </Box>
          </Box>
        }
        placement={placement}
      >
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          invisible={!muted || isChat}
          sx={{ '&	.MuiBadge-badge': { zIndex: 999 } }}
          badgeContent={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                backgroundColor: 'background.paper',
                width: '25px',
                height: '25px',
              }}
            >
              <MicOffIcon sx={{ fontSize: '17px' }} />
            </Box>
          }
        >
          <Badge
            overlap='circular'
            invisible={!host || isChat}
            sx={{ '&	.MuiBadge-badge': { zIndex: 998 } }}
            badgeContent={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                  backgroundColor: 'primary.main',
                  width: '25px',
                  height: '25px',
                }}
              >
                <ShieldIcon sx={{ fontSize: '17px' }} />
              </Box>
            }
          >
            <Box
              sx={{
                position: 'relative',
                borderRadius: '50%',
                outline: volume > 60 ? '5px solid green' : '0px solid rgba(0,0,0,0)',
                transition: '.2s',
              }}
            >
              {videoTrack ? (
                <Box
                  ref={mediaRef}
                  sx={{
                    position: 'relative',
                    width: isMobile ? '60px' : '150px',
                    height: isMobile ? '60px' : '150px',
                    borderRadius: '50%',
                    boxShadow: theme.shadows[3],
                    zIndex: 200,
                    overflow: 'hidden',
                    backgroundColor: 'black',
                  }}
                />
              ) : (
                <MuiAvatar
                  {...restProps}
                  sx={{
                    width: isMobile ? '60px' : '150px',
                    height: isMobile ? '60px' : '150px',
                    boxShadow: theme.shadows[3],
                    zIndex: 1,
                    ...restProps.sx,
                  }}
                />
              )}
              {/* <Box
            component={motion.div}
            initial={{ opacity: 0.5, scale: 0.5 }}
            animate={{ opacity: 0.5 + volume , scale: volume / 5 + 0.95 }}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '150px',
              height: '150px',
              backgroundColor: 'green',
              borderRadius: '50%',
            }}
          /> */}
            </Box>
          </Badge>
        </Badge>
      </Tooltip>
    </>
  )
}

export default VideoCall

export const TooltipAgoraVideoPlayer = (props) => {
  const { muted, host, videoTrack, id, eventId, ...restProps } = props
  const [openTooltip, setOpenTooltip] = React.useState(false)
  const { open, handleClose, handleOpen } = useToggle(false)
  const [muteUser] = useEventMuteEventMutation()
  const [unmuteUser] = useEventUnmuteEventMutation()
  const [disableCamera] = useEventDisableVideoUserEventMutation()
  const [enableCamera] = useEventEnableVideoUserEventMutation()
  const anchorRef = React.useRef(null)
  const { handleSendMessage } = React.useContext(chatContext)
  const handleReload = (userId: number) => {
    handleSendMessage(`#reload_page_${userId}`)
  }
  const handleMouseEnter = () => {
    setOpenTooltip(true)
  }

  const handleMouseLeave = () => {
    setOpenTooltip(false)
  }

  return (
    <>
      <KickStudentModal open={open} onClose={handleClose} id={id} />
      <div
        style={{ position: 'relative', width: '100%', height: '100%' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={anchorRef}
      >
        <AgoraVideoPlayer
          style={{
            width: '100%',
            height: '100%',
          }}
          videoTrack={videoTrack}
          key={id}
        />
        <Popper open={openTooltip} anchorEl={anchorRef.current} disablePortal sx={{ zIndex: 9999 }}>
          {/* Tooltip content */}
          <Box
            sx={{
              position: 'absolute',
              transform: 'translate(-50%, -100%)',
              height: '180px',
              width: '310px',
              borderRadius: '15px',
              backgroundColor: '#102845',
              '& .MuiTooltip-arrow': {
                color: '#102845',
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                padding: '10px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <RecordVoiceOverOutlinedIcon sx={{ color: '#8F9BBA' }} />
                {muted ? (
                  <Button
                    startIcon={<MicNoneOutlined />}
                    variant='contained'
                    sx={{ marginLeft: '20px' }}
                    onClick={() => {
                      unmuteUser({ eventId: eventId, userId: id })
                    }}
                  >
                    Ask to unmute
                  </Button>
                ) : (
                  <Button
                    startIcon={<MicOffOutlinedIcon />}
                    variant='outlined'
                    sx={{ marginLeft: '20px' }}
                    onClick={() => {
                      muteUser({ eventId: eventId, userId: id })
                    }}
                  >
                    Turn off mic
                  </Button>
                )}
              </Box>
              <Box sx={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
                <VideocamOutlinedIcon sx={{ color: '#8F9BBA' }} />
                {videoTrack ? (
                  <Button
                    startIcon={<VideocamOffOutlinedIcon />}
                    variant='outlined'
                    sx={{ marginLeft: '20px' }}
                    onClick={() => {
                      disableCamera({ eventId: eventId, userId: id })
                    }}
                  >
                    Turn off camera
                  </Button>
                ) : (
                  <Button
                    startIcon={<VideocamOutlinedIcon />}
                    variant='contained'
                    sx={{ marginLeft: '20px' }}
                    onClick={() => {
                      enableCamera({ eventId: eventId, userId: id })
                    }}
                  >
                    Ask to turn on webcam
                  </Button>
                )}
              </Box>
              <Box sx={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
                <Person2OutlinedIcon sx={{ color: '#8F9BBA' }} />
                <Button
                  startIcon={<CancelOutlinedIcon />}
                  variant='outlined'
                  sx={{
                    marginLeft: '20px',
                    color: '#DC2626',
                    borderColor: '#DC2626',
                    ':hover': { borderColor: '#DC2626' },
                  }}
                  onClick={handleOpen}
                >
                  Kick
                </Button>
              </Box>
              <Box sx={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
                <LoopIcon sx={{ color: '#8F9BBA' }} />
                <Button
                  startIcon={<LoopIcon />}
                  variant='outlined'
                  sx={{
                    marginLeft: '20px',
                    color: '#DC2626',
                    borderColor: '#DC2626',
                    ':hover': { borderColor: '#DC2626' },
                  }}
                  onClick={() => handleReload(id)}
                >
                  Reload
                </Button>
              </Box>
            </Box>
          </Box>
        </Popper>
      </div>
    </>
  )
}
