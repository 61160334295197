import React from 'react'
import { Modal, Box, Typography, Button, Stack } from '@common/components'
import MicBlocked from '@assets/images/permissions_mic_blocked.svg'
import MicBlockedDark from '@assets/images/permissions_mic_blocked_dark.svg'
import AllowMic from '@assets/images/allow-mic.png'
import { useTheme } from '@features/theme/theme.hooks'
import { isUNSW } from '@utils'
interface AllowMicModalProps {
  open: boolean
  onClose: () => void
  micState: string
}

const AllowMicModal = ({ micState, open, onClose }: AllowMicModalProps) => {
  if (!open) return null
  const { isDark } = useTheme()
  React.useEffect(() => {
    const checkMicrophonePermission = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      stream.getTracks().forEach((track) => track.stop())
    }

    checkMicrophonePermission()
  }, [])

  return (
    <>
      {micState === 'denied' ? (
        <Modal
          open={open}
          onClose={onClose}
          paperProps={{
            sx: {
              p: 3,
              width: '600px',
              maxWidth: '90%',
              textAlign: 'center',
            },
          }}
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={'center'}>
            <img
              src={isUNSW() ? MicBlocked : isDark ? MicBlockedDark : MicBlocked}
              style={{ width: '100%', maxWidth: '322px' }}
            />
            <Stack justifyContent={'space-between'}>
              <Typography
                sx={{ fontWeight: 600, fontSize: '16px', marginTop: '20px', objectFit: 'contain' }}
              >
                Please make sure that your browser&apos;s microphone permissions are allowed before
                proceeding.
              </Typography>
              <Button sx={{ marginTop: '20px' }} variant='contained' onClick={onClose}>
                Done
              </Button>
            </Stack>
          </Stack>
        </Modal>
      ) : (
        <Modal
          open={open}
          onClose={onClose}
          paperProps={{
            sx: {
              pt: 6,
              pb: 3,
              px: 3,
              width: '600px',
              maxWidth: '90%',
              textAlign: 'center',
            },
          }}
        >
          <Box
            sx={{
              padding: '50px 20px 50px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              borderRadius: '10px',
            }}
          >
            <img src={AllowMic} style={{ marginTop: '10px' }} />
            <Typography sx={{ fontWeight: 600, fontSize: '16px', marginTop: '20px' }}>
              Please make sure that your browser&apos;s microphone permissions are allowed before
              proceeding.
            </Typography>

            <Button
              sx={{ marginTop: '20px', backgroundColor: '#5F65F6' }}
              variant='contained'
              onClick={onClose}
            >
              Done
            </Button>
          </Box>
        </Modal>
      )}
    </>
  )
}

export default AllowMicModal
