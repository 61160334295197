import { Container, Skeleton, Grid } from '@common/components'

const InventoryPlaceholder = () => {
  return (
    <Container maxWidth='xs'>
      <Grid
        justifyContent='center'
        sx={{
          mt: '20px',
          minHeight: '600px',
          alignItems: 'center',
        }}
        container
        spacing={4}
      >
        <Grid item xs={12} md={6}>
          <Skeleton width={180} height={350} sx={{ borderRadius: '10px' }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton width={180} height={350} sx={{ borderRadius: '10px' }} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default InventoryPlaceholder
