import { Chip, IconButton, Paper, styled } from '@mui/material'

export const Container = styled(Paper)({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '95%',
  width: 400,
  p: 0,
  borderRadius: 20,
  overflow: 'hidden',
})

export const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: 10,
  right: 10,
})

export const StyledChip = styled(Chip)(() => ({
  borderRadius: '7px',
  backgroundColor: ' #A196E4',
  color: 'white',
}))
