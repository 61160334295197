import { Box, Grid, Skeleton, Stack } from '@common/components'

const PersonalPlaceholder = () => {
  return (
    <Box>
      <Skeleton width='150px' height='30px' />
      <Box
        sx={{
          width: '100px',
          height: '100px',
          position: 'relative',
          mx: 'auto',
          my: -1,
        }}
      >
        <Skeleton width='100%' height='100%' variant='circular' sx={{ borderRadius: '50%' }} />
      </Box>
      <Grid container columnSpacing={3} rowSpacing={0}>
        <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
          <Skeleton width='100%' height='60px' />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
          <Skeleton width='100%' height='60px' />
        </Grid>
        <Grid item xs={12}>
          <Skeleton width='100%' height='60px' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton width='100%' height='60px' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton width='100%' height='60px' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton width='100%' height='60px' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton width='100%' height='60px' />
        </Grid>
      </Grid>
      <Stack direction='row' spacing={5} sx={{ width: '80%' }}>
        <Skeleton width='130px' height='60px' />
        <Skeleton width='130px' height='60px' />
      </Stack>
    </Box>
  )
}

export default PersonalPlaceholder
