import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    hyperbeamStartHyperbeamVm: build.mutation<
      HyperbeamStartHyperbeamVmApiResponse,
      HyperbeamStartHyperbeamVmApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/hyperbeam/${queryArg.eventId}/start`,
        method: 'POST',
        params: { exit_: queryArg.exit },
      }),
    }),
    hyperbeamGetHyperbeamEvent: build.query<
      HyperbeamGetHyperbeamEventApiResponse,
      HyperbeamGetHyperbeamEventApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/extensions/hyperbeam/${queryArg.eventId}` }),
    }),
    hyperbeamShutdownHyperbeamVm: build.mutation<
      HyperbeamShutdownHyperbeamVmApiResponse,
      HyperbeamShutdownHyperbeamVmApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/hyperbeam/${queryArg.eventId}/exit`,
        method: 'POST',
      }),
    }),
    hyperbeamGetHyperbeamSessions: build.query<
      HyperbeamGetHyperbeamSessionsApiResponse,
      HyperbeamGetHyperbeamSessionsApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/hyperbeam/sessions` }),
    }),
    hyperbeamDeleteHyperbeamSession: build.mutation<
      HyperbeamDeleteHyperbeamSessionApiResponse,
      HyperbeamDeleteHyperbeamSessionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/hyperbeam/sessions/${queryArg.sessionId}`,
        method: 'DELETE',
      }),
    }),
    hyperbeamGetHyperbeamSession: build.query<
      HyperbeamGetHyperbeamSessionApiResponse,
      HyperbeamGetHyperbeamSessionApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/extensions/hyperbeam/sessions/${queryArg.sessionId}` }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type HyperbeamStartHyperbeamVmApiResponse = /** status 200 Successful Response */ any
export type HyperbeamStartHyperbeamVmApiArg = {
  eventId: string
  exit?: boolean
}
export type HyperbeamGetHyperbeamEventApiResponse = /** status 200 Successful Response */ any
export type HyperbeamGetHyperbeamEventApiArg = {
  eventId: string
}
export type HyperbeamShutdownHyperbeamVmApiResponse = /** status 200 Successful Response */ any
export type HyperbeamShutdownHyperbeamVmApiArg = {
  eventId: string
}
export type HyperbeamGetHyperbeamSessionsApiResponse =
  /** status 200 Successful Response */ HyperbeamSessionResponse[]
export type HyperbeamGetHyperbeamSessionsApiArg = void
export type HyperbeamDeleteHyperbeamSessionApiResponse = /** status 200 Successful Response */ any
export type HyperbeamDeleteHyperbeamSessionApiArg = {
  sessionId: string
}
export type HyperbeamGetHyperbeamSessionApiResponse =
  /** status 200 Successful Response */ HyperbeamSessionResponse
export type HyperbeamGetHyperbeamSessionApiArg = {
  sessionId: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type HyperbeamSessionResponse = {
  session_id: string
  embed_url: string
  admin_token: string
  event_id?: string | null
  termination_date?: string | null
}
export const {
  useHyperbeamStartHyperbeamVmMutation,
  useHyperbeamGetHyperbeamEventQuery,
  useLazyHyperbeamGetHyperbeamEventQuery,
  useHyperbeamShutdownHyperbeamVmMutation,
  useHyperbeamGetHyperbeamSessionsQuery,
  useLazyHyperbeamGetHyperbeamSessionsQuery,
  useHyperbeamDeleteHyperbeamSessionMutation,
  useHyperbeamGetHyperbeamSessionQuery,
  useLazyHyperbeamGetHyperbeamSessionQuery,
} = injectedRtkApi
