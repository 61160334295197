import React from 'react'

type ObjectType = {
  [key: string]: number
}

interface chunkContextType {
  parentRef: React.MutableRefObject<null> | null
  elements: ObjectType
  setElements: () => void
}

const chunkContext = React.createContext<chunkContextType | null>({
  parentRef: null,
  elements: {},
  setElements: () => {},
})

export default chunkContext
