import { DisplayWeblinks } from '@features/weblinks/components'
import AddWeblink from '@features/weblinks/components/add-weblink/add-weblink'

const WeblinksPage = () => {
  return (
    <>
      {' '}
      <DisplayWeblinks />
      <AddWeblink sx={{ position: 'absolute', bottom: 30, right: 30 }} />
    </>
  )
}
export default WeblinksPage
