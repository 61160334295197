import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { MODE, SENTRY } from './config'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import { useEffect } from 'react'

Sentry.init({
  dsn: 'https://ee997c2a294c4d26aea33daeb99a358d@o1221558.ingest.us.sentry.io/4504003884941312',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  release: 'staging 1.1',
  enabled: SENTRY,
  ignoreErrors: [/^empty string$/],
})
