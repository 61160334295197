import { defer } from '@lib/router'
import { revisionApi } from '@app/services/revision'
import { store } from '@app/store'

const VocabularyLoader = async () => {
  const words = promise()
  return defer({ words })
}

const promise = async () => {
  const result = await Promise.all([
    store.dispatch(revisionApi.endpoints.revisionGetRevision.initiate({})),
  ])

  return result
}

export default VocabularyLoader
