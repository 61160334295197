import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    bookingsListUserBookingRequest: build.query<
      BookingsListUserBookingRequestApiResponse,
      BookingsListUserBookingRequestApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/bookings/booking` }),
    }),
    bookingsCreateBookingRequest: build.mutation<
      BookingsCreateBookingRequestApiResponse,
      BookingsCreateBookingRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/bookings/booking`,
        method: 'POST',
        body: queryArg.createBookingRequest,
      }),
    }),
    bookingsRespondBookingRequest: build.mutation<
      BookingsRespondBookingRequestApiResponse,
      BookingsRespondBookingRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/bookings/booking/${queryArg.bookingId}/respond`,
        method: 'POST',
        body: queryArg.respondBookingRequest,
      }),
    }),
    bookingsCancelBookingSession: build.mutation<
      BookingsCancelBookingSessionApiResponse,
      BookingsCancelBookingSessionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/bookings/booking/sessions/${queryArg.sessionId}/cancel`,
        method: 'POST',
      }),
    }),
    bookingsListStudentBookingRequest: build.query<
      BookingsListStudentBookingRequestApiResponse,
      BookingsListStudentBookingRequestApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/bookings/booking/student` }),
    }),
    bookingsListTeacherBookingSessions: build.query<
      BookingsListTeacherBookingSessionsApiResponse,
      BookingsListTeacherBookingSessionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/bookings/booking/sessions`,
        params: { upcoming: queryArg.upcoming },
      }),
    }),
    bookingsGetTeacherBookingSessions: build.query<
      BookingsGetTeacherBookingSessionsApiResponse,
      BookingsGetTeacherBookingSessionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/bookings/booking/sessions/${queryArg.sessionId}`,
      }),
    }),
    bookingsGetTeacherAvailabilities: build.query<
      BookingsGetTeacherAvailabilitiesApiResponse,
      BookingsGetTeacherAvailabilitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/bookings/calendar`,
        params: {
          calendar_bookings: queryArg.calendarBookings,
          languages: queryArg.languages,
          lesson_types_ids: queryArg.lessonTypesIds,
        },
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type BookingsListUserBookingRequestApiResponse =
  /** status 200 Successful Response */ ListUserBookingRequestResponse[]
export type BookingsListUserBookingRequestApiArg = void
export type BookingsCreateBookingRequestApiResponse =
  /** status 201 Successful Response */ CreateBookingRequestResponse
export type BookingsCreateBookingRequestApiArg = {
  createBookingRequest: CreateBookingRequest
}
export type BookingsRespondBookingRequestApiResponse =
  /** status 200 Successful Response */ BookingRequestRespondResponse
export type BookingsRespondBookingRequestApiArg = {
  bookingId: number
  respondBookingRequest: RespondBookingRequest
}
export type BookingsCancelBookingSessionApiResponse =
  /** status 201 Successful Response */ CancelBookingSessionResponse
export type BookingsCancelBookingSessionApiArg = {
  sessionId: number
}
export type BookingsListStudentBookingRequestApiResponse =
  /** status 200 Successful Response */ ListStudentBookingRequestResponse[]
export type BookingsListStudentBookingRequestApiArg = void
export type BookingsListTeacherBookingSessionsApiResponse =
  /** status 200 Successful Response */ ListTeacherBookingSessionResponse[]
export type BookingsListTeacherBookingSessionsApiArg = {
  upcoming?: boolean | null
}
export type BookingsGetTeacherBookingSessionsApiResponse =
  /** status 200 Successful Response */ ListTeacherBookingSessionResponse
export type BookingsGetTeacherBookingSessionsApiArg = {
  sessionId: number
}
export type BookingsGetTeacherAvailabilitiesApiResponse =
  /** status 200 Successful Response */ TeacherCalendarViewResponse
export type BookingsGetTeacherAvailabilitiesApiArg = {
  calendarBookings?: string[]
  languages?: string[]
  lessonTypesIds?: string[]
}
export type BookingRequestStudentNested = {
  id: number
  full_name: string
  image?: string | null
}
export type AvailabilityDate = {
  start_time: string
  end_time: string
}
export type ListUserBookingRequestResponse = {
  id: number
  student: BookingRequestStudentNested
  status: string
  dates: AvailabilityDate[]
}
export type CreateBookingRequestResponseNested = {
  id: number
  package_id: number
  dates: AvailabilityDate[]
  status: string
}
export type CreateBookingRequestResponse = {
  booking_request: CreateBookingRequestResponseNested
  payment_link?: string | null
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type PaymentMethodEnum = 'stripe' | 'paymee'
export type CreateBookingRequest = {
  package_id: number
  availabilities: AvailabilityDate[]
  payment_method: PaymentMethodEnum
}
export type BookingRequestRespondResponse = {
  id: number
  status: string
}
export type BookingRequestEnum = 'accepted' | 'rejected'
export type RespondBookingRequest = {
  verdict: BookingRequestEnum
  reason?: string | null
}
export type CancelBookingSessionResponse = {
  id: number
  status: string
}
export type BookingRequestTeacherNested = {
  id: number
  full_name: string
  image?: string | null
}
export type ListStudentBookingRequestResponse = {
  id: number
  teacher: BookingRequestTeacherNested
  status: string
  dates: AvailabilityDate[]
}
export type BookingStudent = {
  id: number
  full_name: string
  image?: string | null
}
export type BookingPackage = {
  id: number
  price_per_lesson: number
  duration: number
  total: number
  number_of_lessons: number
  language: string
}
export type ListTeacherBookingSessionResponse = {
  id: number
  student: BookingStudent
  package: BookingPackage
  start_time: string
  end_time: string
}
export type TeacherCalendarViewDatesResponseNested = {
  id: number
  start_time: string
  end_time: string
}
export type TeacherCalendarViewResponse = {
  booked: TeacherCalendarViewDatesResponseNested[]
  requests: TeacherCalendarViewDatesResponseNested[]
  open_slots: TeacherCalendarViewDatesResponseNested[]
}
export const {
  useBookingsListUserBookingRequestQuery,
  useLazyBookingsListUserBookingRequestQuery,
  useBookingsCreateBookingRequestMutation,
  useBookingsRespondBookingRequestMutation,
  useBookingsCancelBookingSessionMutation,
  useBookingsListStudentBookingRequestQuery,
  useLazyBookingsListStudentBookingRequestQuery,
  useBookingsListTeacherBookingSessionsQuery,
  useLazyBookingsListTeacherBookingSessionsQuery,
  useBookingsGetTeacherBookingSessionsQuery,
  useLazyBookingsGetTeacherBookingSessionsQuery,
  useBookingsGetTeacherAvailabilitiesQuery,
  useLazyBookingsGetTeacherAvailabilitiesQuery,
} = injectedRtkApi
