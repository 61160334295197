import {
  Autocomplete,
  AutocompleteProps,
  Box,
  BoxProps,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { SelectedOption } from './selected-option'

interface LanguageAutocompleteProps extends AutocompleteProps {
  value: {
    code: string
    flag_image: string
    name: string
    level: number
  }[]
  label: string
  // onChange: (arg: any, arg2: any) => void
  defaultLevel: number
  autoLevelOpen: boolean
  containerProps: BoxProps
}
const LanguageAutocomplete: React.FC<LanguageAutocompleteProps> = (props) => {
  const { value, label, onChange, defaultLevel, autoLevelOpen, containerProps, ...restProps } =
    props
  const handleRemove = (code: string) => {
    onChange(
      {},
      value.filter((elem: any) => elem.code !== code),
    )
  }

  const handleChangeLevel = (code: string, newLevel: number) => {
    onChange(
      {},
      value.map((elem: any) => {
        if (elem.code === code) {
          return { ...elem, level: newLevel }
        }
        return elem
      }),
    )
  }

  const handleChange = (
    e: any,
    newValue: {
      code: string
      flag_image: string
      name: string
      level: number
    }[],
  ) => {
    onChange(
      e,
      newValue.map((elem) => {
        if (!elem.level) {
          return { ...elem, level: defaultLevel }
        }
        return elem
      }),
    )
  }

  return (
    <Container {...containerProps}>
      {value.length > 0 && (
        <Box sx={{ mb: 2 }}>
          {value.map((language, k) => (
            <SelectedOption
              language={language}
              key={k}
              onChange={(newLevel) => handleChangeLevel(language.code, newLevel)}
              onRemove={() => handleRemove(language.code)}
              autoLevelOpen={autoLevelOpen}
              sx={{ mb: 1 }}
            />
          ))}
        </Box>
      )}
      <Autocomplete
        multiple
        value={value}
        clearIcon={null}
        renderTags={() => null}
        renderInput={(params) => <TextField {...params} label={label} />}
        renderOption={(props, option: { code: string; flag_image: string; name: string }) => (
          <Box component='li' {...props}>
            <Typography sx={{ flex: 1 }}>{option.name}</Typography>
            <img
              src={option.flag_image}
              alt={option.name}
              style={{ width: '25px', borderRadius: '2px' }}
            />
          </Box>
        )}
        getOptionLabel={(option: { code: string; flag_image: string; name: string }) => option.name}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        {...restProps}
      />
    </Container>
  )
}

const Container = styled(Box)({
  paddingRight: '40px',
})

LanguageAutocomplete.defaultProps = {
  label: 'Select a language...',
  defaultLevel: 1,
  autoLevelOpen: true,
}

export default LanguageAutocomplete
