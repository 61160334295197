import { Box, Skeleton, Stack } from '@common/components'

const ReferralLinkPlaceholder = () => {
  return (
    <Box>
      <Skeleton width='150px' height='50px' />
      <Stack
        direction={{ xs: 'column-reverse', md: 'column-reverse', lg: 'row' }}
        alignItems='center'
        spacing={{ xs: 1, lg: 3 }}
      >
        <Skeleton sx={{ width: '100%', maxWidth: '320px', height: '400px' }} />
        <Stack alignItems='center' justifyContent='center'>
          <Skeleton sx={{ width: '300px', height: '60px' }} />
          <Skeleton sx={{ width: '30px' }} />
          <Skeleton sx={{ width: '300px', height: '60px' }} />
        </Stack>
      </Stack>
    </Box>
  )
}

export default ReferralLinkPlaceholder
