import { styled } from '@common/utils/styles'
import { Box } from '@common/components'

export const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '35px',
  height: '35px',
  marginBottom: '8px',
  borderRadius: '50%',
  background: theme.palette.mode === 'dark' ? '#D1FADF20' : '#D1FADF',
  outline: theme.palette.mode === 'dark' ? '6px solid #ECFDF310' : '6px solid #ECFDF3',
}))
