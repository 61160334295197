import { Container, Box, Skeleton, Grid } from '@common/components'

const LiveboardsPlaceholder = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Skeleton height={160} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Skeleton height={160} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Skeleton height={160} />
      </Grid>
    </Grid>
  )
}

export default LiveboardsPlaceholder
