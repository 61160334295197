import { useAppSelector } from '@common/hooks'
import { useTheme as useUtilsTheme } from '@common/utils/styles'
import { modeType, selectMode } from './theme.slice'

export const useTheme = () => {
  const mode = useAppSelector(selectMode)
  const resolvedMode = resolveMode(mode)

  const isDark = resolvedMode === 'dark'
  const isLight = resolvedMode === 'light'

  const theme = useUtilsTheme()

  return { mode: resolveMode(mode), isDark, isLight, theme }
}

const resolveMode = (mode: modeType) => {
  if (mode === 'browser') {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  }
  return mode
}
