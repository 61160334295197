import { Card as MuiCard, CardActionArea as MuiCardActionArea, styled } from '@mui/material'

export const Card = styled(MuiCard)({
  width: '350px',
  height: '250px',
  maxWidth: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '20px',
  background: 'linear-gradient(90deg, #6E51FF 0%, #9D8AFE 100%)',
  backdropFilter: 'blur(115.939px)',
})

export const CardActionArea = styled(MuiCardActionArea)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
})
