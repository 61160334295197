import styled from '@emotion/styled'
import { Box, Button } from '@mui/material'

export const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})(({ theme, fullWidth, elevation }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: theme.palette.background.paper,
  borderRadius: 15,
  paddingLeft: 15,
  height: 50,
  overflow: 'hidden',
  flexWrap: 'nowrap',
  width: fullWidth && '100%',
  boxShadow: theme.shadows[elevation],
}))

export const CopyButton = styled(Button)(({ theme }) => ({
  height: '100%',
  borderRadius: '15px',
  minWidth: 105,
}))
