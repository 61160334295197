const displayConsoleMessage = () => {
  const styles = [
    'color: #0057e3',
    'font-size: 12px',
    'font-family: monospace',
    'background: #f0f8ff',
    'padding: 20px',
    'border: 1px solid #0057e3',
  ].join(';')

  console.log(
    `%c
   👋 Hey there, curious developer!
   
   We love people who explore and dig deeper.
   Try running consoleExplorer(x) where x is the answer 
   to life, the universe, and everything.
   
   Let's build something amazing together! 🚀
  `,
    styles,
  )

  // @ts-ignore
  window.consoleExplorer = (x: number) => {
    if (x === 42) {
      window.location.href = `mailto:aziz@speakduo.com?subject=Console Explorer`
    } else {
      console.log("That's not quite right! Keep trying...")
    }
  }
}

export default displayConsoleMessage
