import { EventResponse, useEventGetEventsQuery } from '@app/services'
import {
  Alert,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Countdown,
  Link,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@common/components'
import { EVENT_ROUTE, MY_CLASSROOMS_ROUTE } from '@common/constants/routes'
import { useMediaQuery } from '@common/hooks'
import { RouterLink } from '@lib/router'
import dayjs from 'dayjs'
import ParticipantButton from '../participant-button/participant-button'
import { handleGravatar } from '@common/utils/gravatar'
import { DotBlinking } from '../events-view/events-view.styles'

const NextSessionTime = () => {
  const isMatches = useMediaQuery(1050)
  const { data, isLoading, isError, isSuccess } = useEventGetEventsQuery(
    {
      isDashboard: false,
    },
    { pollingInterval: 300000, refetchOnMountOrArgChange: true },
  )
  const event = data?.events
    ? [...data.events].sort((a, b) => dayjs(a.start_time_utc).diff(dayjs(b.start_time_utc)))[0]
    : undefined

  return (
    <Paper sx={{ padding: '10px 15px', borderRadius: '10px', width: '100%' }}>
      {isLoading ? (
        <Skeleton variant='rectangular' height={70} />
      ) : isError ? (
        <Alert variant='outlined' severity='error'>
          something went wrong
        </Alert>
      ) : isSuccess && event ? (
        <Stack spacing={0.8}>
          {event.is_live ? (
            <>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <DotBlinking />
                  <Typography
                    color='secondary'
                    sx={{
                      fontWeight: 500,
                      fontSize: '18px',
                      textTransform: 'uppercase',
                    }}
                  >
                    Live
                  </Typography>
                </Stack>
                <ParticipantsList event={event} />
              </Stack>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                flexWrap={'wrap'}
                alignItems={'center'}
                gap={1}
              >
                <Typography fontWeight='bold' variant='body2'>
                  A session is happening now!
                </Typography>
                <Button
                  component={RouterLink}
                  to={`${EVENT_ROUTE}/${event.short_event_id ?? event.event_id}`}
                  floating
                  color='neutral'
                  variant='outlined'
                  sx={{
                    flex: 1,
                    mx: 'auto',
                    zIndex: 10,
                    fontWeight: 600,
                  }}
                >
                  Jump in Now
                </Button>
              </Stack>
              <Typography
                component={RouterLink}
                to={MY_CLASSROOMS_ROUTE}
                textAlign={'center'}
                sx={{ cursor: 'pointer' }}
              >
                See all events
              </Typography>
            </>
          ) : (
            <>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography fontWeight='bold'>Next event starts in:</Typography>
                <ParticipantsList event={event} />
                {/* <Typography
                  textAlign={'center'}
                  component={Link}
                  sx={{ cursor: 'pointer', display: { xs: 'initial', md: 'none' } }}
                >
                  See all events
                </Typography> */}
              </Stack>
              <Stack
                spacing={isMatches ? 1 : 0}
                direction={{ xs: 'row', md: isMatches ? 'column' : 'row' }}
                alignItems={'flex-start'}
              >
                <Countdown
                  size='small'
                  startTime={dayjs.utc(event.start_time_utc).local().format()}
                  sx={{ mx: 'auto' }}
                />
                <Stack alignItems={'center'} sx={{ width: '100%' }}>
                  {/* <Button
                    floating
                    highlighted
                    // loading={isLoadingParticipateInEvent}
                    color='neutral'
                    variant='outlined'
                    sx={{
                      zIndex: 10,
                      width: '80%',
                      fontWeight: 600,
                    }}
                  >
                    Attend
                  </Button> */}
                  <ParticipantButton event={event} width='80%' />
                </Stack>
              </Stack>
              <Typography variant='body2' fontWeight='bold' color='#738598' align='center'>
                Scheduled for {dayjs.utc(event.start_time_utc).local().format('DD/MM/YYYY HH:mm')}
              </Typography>
              <Typography
                component={RouterLink}
                to={MY_CLASSROOMS_ROUTE}
                textAlign={'center'}
                sx={{ cursor: 'pointer' }}
                // sx={{ cursor: 'pointer', display: { xs: 'none', md: 'initial' } }}
              >
                See all events
              </Typography>
            </>
          )}
        </Stack>
      ) : (
        <Typography fontWeight='bold' variant='body2'>
          No events found
        </Typography>
      )}
    </Paper>
  )
}

const ParticipantsList = (props: { event: EventResponse }) => {
  const { event } = props
  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      {event.participants.length > 0 && (
        <Typography fontWeight={'bold'} variant='caption' color='text.secondary'>
          Participants:
        </Typography>
      )}
      <AvatarGroup
        max={4}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          '.MuiAvatarGroup-avatar': {
            color: 'gray',
            border: (theme) =>
              theme.palette.mode === 'dark'
                ? '2px solid #1C1E20'
                : '2px solid rgba(230,230,230,0.8)',
            background: (theme) => (theme.palette.mode === 'dark' ? '#2C2E2F' : '#fff'),
            borderColor: 'none',
            width: 18,
            height: 18,
            fontSize: '10px',
            fontWeight: 600,
          },
        }}
      >
        {Array.from(
          new Map(
            [...(event.participants || []), ...(event.users || [])].map((user) => [
              user?.username,
              user,
            ]),
          ).values(),
        ).map((participant, index) => (
          <Avatar
            key={index}
            src={handleGravatar(participant.image ?? '')}
            alt={(participant?.display_name || participant?.username) ?? 'participant'}
          >
            {participant?.display_name?.charAt(0) || participant?.username?.charAt(0)}
          </Avatar>
        ))}
      </AvatarGroup>
    </Stack>
  )
}
export default NextSessionTime
