import { Box, Skeleton, Stack } from '@common/components'

const VideoPlaceholder = () => {
  return (
    <Box>
      <Skeleton width='150px' height='30px' />
      <Skeleton width='100%' height='90px' />
      <Box
        sx={{
          mt: -5,
          width: '480px',
          maxWidth: '100%',
          height: '270px',
          mx: 'auto',
        }}
      >
        <Skeleton width='100%' height='100%' />
      </Box>
      <Box
        sx={{
          mt: -5,
          width: '230px',
          maxWidth: '100%',
          mx: 'auto',
        }}
      >
        <Skeleton width='100%' height='50px' />
      </Box>
      <Stack direction='row' spacing={5} justifyContent='center' mt={3}>
        <Skeleton width='130px' height='40px' />
        <Skeleton width='130px' height='40px' />
      </Stack>
    </Box>
  )
}

export default VideoPlaceholder
