import { Card, Box, Chip, styled, Typography } from '@mui/material'

export const CardWrapper = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'backgroundColor',
})<{ background: string; fullWidth?: boolean }>(({ fullWidth, background }) => ({
  background: background,
  borderRadius: '20px',
  width: fullWidth ? '100%' : '350px',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '250px',
}))

export const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
})

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background: string }>(({ background }) => ({
  color: 'white',
  background,
  minWidth: 60,
  verticalAlign: 'baseline',
}))
