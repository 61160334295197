import {
  IconButton,
  Modal,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@common/components'
import { useTogglePopover } from '@common/hooks'
import { CheckIcon, CloseIcon, HelpOutlineIcon } from '@common/icons'
import React from 'react'

const SocialFeaturesModal = (props: { open: boolean; onClose: () => void }) => {
  const { open, anchorEl, handleClose, handleOpen } = useTogglePopover()
  const {
    open: open2,
    anchorEl: anchorEl2,
    handleClose: handleClose2,
    handleOpen: handleOpen2,
  } = useTogglePopover()

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      paperProps={{
        sx: { py: 4, px: 2, width: '550px', minHeight: '300px', borderRadius: '10px' },
      }}
    >
      <Stack sx={{ height: '100%' }}>
        <Typography sx={{ fontWeight: 600, fontSize: '20px', pl: 2 }}>Social Features</Typography>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell sx={{ fontWeight: 600, fontSize: '18px' }}>Free</TableCell>
              <TableCell sx={{ fontWeight: 600, fontSize: '18px' }}>Basic</TableCell>
              <TableCell sx={{ fontWeight: 600, fontSize: '18px' }}>Pro</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component='th' scope='row'>
                Members you can follow{' '}
                <IconButton onClick={handleOpen}>
                  <HelpOutlineIcon sx={{ color: 'text.secondary' }} />
                </IconButton>
                <Popover
                  id='help-popover'
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  slotProps={{
                    paper: {
                      sx: { width: '300px', maxWidth: '90%', bgcolor: '#F2F2F2', p: 1 },
                    },
                  }}
                >
                  <Typography variant='caption' sx={{ color: 'black' }}>
                    Following increases match frequency, but both members must follow each other.
                  </Typography>
                </Popover>
              </TableCell>
              <TableCell component='th' scope='row'>
                5
              </TableCell>
              <TableCell component='th' scope='row'>
                20
              </TableCell>
              <TableCell component='th' scope='row'>
                unlimited
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component='th' scope='row'>
                Members you can avoid{' '}
                <IconButton onClick={handleOpen2}>
                  <HelpOutlineIcon sx={{ color: 'text.secondary' }} />
                </IconButton>
                <Popover
                  id='help-popover'
                  open={open2}
                  anchorEl={anchorEl2}
                  onClose={handleClose2}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  slotProps={{
                    paper: {
                      sx: { width: '300px', maxWidth: '90%', bgcolor: '#F2F2F2', p: 1 },
                    },
                  }}
                >
                  <Typography variant='caption' sx={{ color: 'black' }}>
                    Avoiding significantly reduces match frequency.
                  </Typography>
                </Popover>
              </TableCell>
              <TableCell component='th' scope='row'>
                0
              </TableCell>
              <TableCell component='th' scope='row'>
                5
              </TableCell>
              <TableCell component='th' scope='row'>
                15
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                See who follows you
              </TableCell>
              <TableCell component='th' scope='row'>
                <CloseIcon />
              </TableCell>
              <TableCell component='th' scope='row'>
                <CloseIcon />
              </TableCell>
              <TableCell component='th' scope='row'>
                <CheckIcon />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </Modal>
  )
}

export default SocialFeaturesModal
