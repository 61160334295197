import styled from '@emotion/styled'
import { Typography, Box } from '@mui/material'

export const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fullScreen',
})<{ fullScreen?: boolean }>(({ fullScreen }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  // background: 'linear-gradient(180deg,#291760,#141943)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  ...(fullScreen && {
    height: '100vh',
  }),
}))

export const TopicTypography = styled(Typography)({
  fontFamily: "'Montserrat'",
  // fontStyle: 'normal',
  fontWeight: 600,
  // lineHeight: '43px',
  // maxWidth: '500px',
})
