import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    agoraIssueRtcToken: build.query<AgoraIssueRtcTokenApiResponse, AgoraIssueRtcTokenApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/agora/rtc/${queryArg.roomId}`,
        params: { screen_share: queryArg.screenShare },
      }),
    }),
    agoraCreateNewRtcRoom: build.mutation<
      AgoraCreateNewRtcRoomApiResponse,
      AgoraCreateNewRtcRoomApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/agora/rtc/${queryArg.eventId}`,
        method: 'POST',
      }),
    }),
    agoraGetRtcRooms: build.query<AgoraGetRtcRoomsApiResponse, AgoraGetRtcRoomsApiArg>({
      query: (queryArg) => ({ url: `/api/v1/extensions/agora/rtc/${queryArg.eventId}/rooms` }),
    }),
    agoraGetRtcConfig: build.query<AgoraGetRtcConfigApiResponse, AgoraGetRtcConfigApiArg>({
      query: (queryArg) => ({ url: `/api/v1/extensions/agora/rtc/${queryArg.eventId}/config` }),
    }),
    agoraIssueRtmToken: build.query<AgoraIssueRtmTokenApiResponse, AgoraIssueRtmTokenApiArg>({
      query: () => ({ url: `/api/v1/extensions/agora/rtm` }),
    }),
    agoraCreateNewRoom: build.mutation<AgoraCreateNewRoomApiResponse, AgoraCreateNewRoomApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/agora/room/${queryArg.eventId}`,
        method: 'POST',
        params: { activity_type: queryArg.activityType },
      }),
    }),
    agoraGetRoom: build.query<AgoraGetRoomApiResponse, AgoraGetRoomApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/agora/room/${queryArg.roomId}`,
        params: { activity_type: queryArg.activityType },
      }),
    }),
    agoraGetRooms: build.query<AgoraGetRoomsApiResponse, AgoraGetRoomsApiArg>({
      query: () => ({ url: `/api/v1/extensions/agora/rooms` }),
    }),
    agoraSetEventCurrentRoom: build.mutation<
      AgoraSetEventCurrentRoomApiResponse,
      AgoraSetEventCurrentRoomApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/agora/set_current_room`,
        method: 'POST',
        body: queryArg.roomRequest,
      }),
    }),
    agoraSetUserCurrentRoom: build.mutation<
      AgoraSetUserCurrentRoomApiResponse,
      AgoraSetUserCurrentRoomApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/agora/set/user/current_room`,
        method: 'POST',
        body: queryArg.setCurrentRoomRequest,
      }),
    }),
    agoraSendUserRoom: build.mutation<AgoraSendUserRoomApiResponse, AgoraSendUserRoomApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/agora/send/user/room`,
        method: 'POST',
        body: queryArg.sendUserToRoomRequest,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type AgoraIssueRtcTokenApiResponse = /** status 200 Successful Response */ Token
export type AgoraIssueRtcTokenApiArg = {
  roomId: string
  screenShare?: boolean
}
export type AgoraCreateNewRtcRoomApiResponse = /** status 201 Successful Response */ RtcRoomDbModel
export type AgoraCreateNewRtcRoomApiArg = {
  eventId: string
}
export type AgoraGetRtcRoomsApiResponse = /** status 200 Successful Response */ RtcRoomDbModel[]
export type AgoraGetRtcRoomsApiArg = {
  eventId: string
}
export type AgoraGetRtcConfigApiResponse = /** status 200 Successful Response */ any
export type AgoraGetRtcConfigApiArg = {
  eventId: string
}
export type AgoraIssueRtmTokenApiResponse = /** status 200 Successful Response */ Token
export type AgoraIssueRtmTokenApiArg = void
export type AgoraCreateNewRoomApiResponse =
  /** status 201 Successful Response */ CreateEventRoomResponse
export type AgoraCreateNewRoomApiArg = {
  eventId: string
  activityType?: GameType
}
export type AgoraGetRoomApiResponse =
  /** status 200 Successful Response */ AgoraWhiteBoardRoomResponse
export type AgoraGetRoomApiArg = {
  roomId: string
  activityType?: GameType
}
export type AgoraGetRoomsApiResponse = /** status 200 Successful Response */ any
export type AgoraGetRoomsApiArg = void
export type AgoraSetEventCurrentRoomApiResponse =
  /** status 201 Successful Response */ ChangeEventRoomResponse
export type AgoraSetEventCurrentRoomApiArg = {
  roomRequest: RoomRequest
}
export type AgoraSetUserCurrentRoomApiResponse = /** status 201 Successful Response */ any
export type AgoraSetUserCurrentRoomApiArg = {
  setCurrentRoomRequest: SetCurrentRoomRequest
}
export type AgoraSendUserRoomApiResponse = /** status 201 Successful Response */ any
export type AgoraSendUserRoomApiArg = {
  sendUserToRoomRequest: SendUserToRoomRequest
}
export type Token = {
  token: string
  expiration_time?: string | null
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type RtcRoomDbModel = {
  id: number
  room_uuid?: string | null
  event_id?: number | null
  creator_id?: number | null
  created_at?: string
  updated_at?: string
  is_main?: boolean
}
export type CreateEventRoomResponse = {
  room_id: string
}
export type GameType =
  | 'NONE'
  | 'GEO_GAME'
  | 'CAPTION_THIS'
  | 'HYPERBEAM'
  | 'PICTIONARY'
  | 'JUST_CHAT'
  | 'JKLM_FUN'
  | 'DISCUSSION'
  | 'WHITEBOARD'
  | 'CHAT'
  | 'GROUP_LESSON'
export type AgoraWhiteBoardRoomResponse = {
  room_uuid: string
  room_token?: Token | null
}
export type ChangeEventRoomResponse = {
  id: number
  current_room_id: string
  event_id: string
}
export type RoomRequest = {
  room_id: string
}
export type SetCurrentRoomRequest = {
  room_id: string
  event_id: string
}
export type SendUserToRoomRequest = {
  room_id: number
  user_id?: number | null
}
export const {
  useAgoraIssueRtcTokenQuery,
  useLazyAgoraIssueRtcTokenQuery,
  useAgoraCreateNewRtcRoomMutation,
  useAgoraGetRtcRoomsQuery,
  useLazyAgoraGetRtcRoomsQuery,
  useAgoraGetRtcConfigQuery,
  useLazyAgoraGetRtcConfigQuery,
  useAgoraIssueRtmTokenQuery,
  useLazyAgoraIssueRtmTokenQuery,
  useAgoraCreateNewRoomMutation,
  useAgoraGetRoomQuery,
  useLazyAgoraGetRoomQuery,
  useAgoraGetRoomsQuery,
  useLazyAgoraGetRoomsQuery,
  useAgoraSetEventCurrentRoomMutation,
  useAgoraSetUserCurrentRoomMutation,
  useAgoraSendUserRoomMutation,
} = injectedRtkApi
