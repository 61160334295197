import { SvgIcon } from '@mui/material'
import React from 'react'

const GoogleIcon = () => {
  return (
    <SvgIcon viewBox='0 0 29 28'>
      <g clipPath='url(#a)'>
        <path
          d='M6.65 11.214a8.127 8.127 0 0 1 7.734-5.577c1.94 0 3.695.69 5.074 1.817l4.01-4.009C21.024 1.315 17.892 0 14.384 0A13.744 13.744 0 0 0 2.027 7.637l4.624 3.577Z'
          fill='#EA4335'
        />
        <path
          d='M19.023 20.686c-1.252.807-2.841 1.237-4.64 1.237a8.127 8.127 0 0 1-7.72-5.538l-4.64 3.522a13.74 13.74 0 0 0 12.36 7.654c3.369 0 6.587-1.198 8.997-3.445l-4.356-3.43h-.001Z'
          fill='#34A853'
        />
        <path
          d='M23.38 24.117c2.52-2.352 4.156-5.852 4.156-10.336 0-.815-.125-1.691-.312-2.506H14.383V16.6h7.39c-.363 1.79-1.343 3.177-2.75 4.086l4.356 3.43Z'
          fill='#4A90E2'
        />
        <path
          d='M6.662 16.385a8.176 8.176 0 0 1-.423-2.604c0-.898.144-1.76.41-2.567L2.026 7.637A13.705 13.705 0 0 0 .602 13.78c0 2.204.51 4.283 1.42 6.126l4.64-3.522Z'
          fill='#FBBC05'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' transform='translate(.602)' d='M0 0h27.561v27.561H0z' />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default GoogleIcon
