import useCookieConsent from '@common/hooks/use-cookieconsent'
import {
  ENABLE_ANALYTICS,
  HOTJAR_ID,
  HOTJAR_SNIPPET_VERSION,
  IS_UNSW,
  POSTHOG_HOST,
  POSTHOG_KEY,
} from '@config'
import { HelmetProvider } from '@lib/helmet'
import { RouterProvider } from '@lib/router'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import router from '@routes/router-config'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import utc from 'dayjs/plugin/utc'
import { PostHogProvider } from 'posthog-js/react'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { hotjar } from 'react-hotjar'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { store } from './store'

const App: React.FC = () => {
  dayjs.extend(utc)
  dayjs.extend(isoWeek)
  const persistor = persistStore(store)
  useCookieConsent()

  if (ENABLE_ANALYTICS) {
    hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION)
  }

  useEffect(() => {
    if (ENABLE_ANALYTICS) {
      if (IS_UNSW) {
        ReactGA.initialize('G-DFCRDWY10X')
      } else {
        ReactGA.initialize('G-8VKW2511DS')
      }
    }
  }, [])

  return (
    <Provider store={store}>
      {ENABLE_ANALYTICS ? (
        <PostHogProvider
          apiKey={POSTHOG_KEY}
          options={{
            api_host: POSTHOG_HOST,
          }}
        >
          <PersistGate loading={null} persistor={persistor}>
            <HelmetProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <RouterProvider router={router} />
              </LocalizationProvider>
            </HelmetProvider>
          </PersistGate>
        </PostHogProvider>
      ) : (
        <PersistGate loading={null} persistor={persistor}>
          <HelmetProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <RouterProvider router={router} />
            </LocalizationProvider>
          </HelmetProvider>
        </PersistGate>
      )}
    </Provider>
  )
}

export default App
