import { DisplayHomeTeacher } from '@features/teachers/components/display-home-teacher'
import { Await, useLoaderData } from '@lib/router'
import NotFound from '@pages/not-found'

export const HomePageTeacher = () => {
  const { homeTeacher } = useLoaderData()
  return (
    <Await resolve={homeTeacher} errorElement={<NotFound />}>
      {(homeTeacher) => <DisplayHomeTeacher homeTeacher={homeTeacher.data} />}
    </Await>
  )
}
