import { Skeleton, Grid, Stack } from '@common/components'

const FeedbackPlaceholder = () => {
  return (
    <Grid container spacing={3} sx={{ p: 3 }}>
      <Grid item xs={12}>
        <Skeleton variant='rectangular' height={60} />
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={2}>
          <Skeleton variant='rectangular' height={20} />
          <Skeleton variant='rectangular' height={20} />
          <Skeleton variant='rectangular' height={20} />
          <Skeleton variant='rectangular' height={20} />
          <Skeleton variant='rectangular' height={20} />
        </Stack>
      </Grid>
      <Grid item xs={6} sx={{ overflow: 'auto' }}>
        <Stack spacing={2}>
          <Skeleton variant='rectangular' height={90} />
          <Skeleton variant='rectangular' height={90} />
          <Skeleton variant='rectangular' height={90} />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default FeedbackPlaceholder
