import { Box, Skeleton } from '@common/components'

const SettingsPlaceholder = () => {
  return (
    <Box>
      <Skeleton width={170} height={60} />
      <Skeleton width={200} height={60} />
      <Skeleton width={170} height={60} />
      <Skeleton width={200} height={60} />
    </Box>
  )
}

export default SettingsPlaceholder
