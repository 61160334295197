import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    teacherFilterTeachers: build.query<
      TeacherFilterTeachersApiResponse,
      TeacherFilterTeachersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/teacher/teacher`,
        params: {
          query: queryArg.query,
          price_from: queryArg.priceFrom,
          price_to: queryArg.priceTo,
          languages: queryArg.languages,
          order_by: queryArg.orderBy,
          offset: queryArg.offset,
          date_from: queryArg.dateFrom,
          date_to: queryArg.dateTo,
          lesson_duration: queryArg.lessonDuration,
          teacher_from: queryArg.teacherFrom,
          is_local: queryArg.isLocal,
          is_pro: queryArg.isPro,
          is_partner: queryArg.isPartner,
          limit: queryArg.limit,
          is_reviewer: queryArg.isReviewer,
        },
      }),
    }),
    teacherCreateLesson: build.mutation<TeacherCreateLessonApiResponse, TeacherCreateLessonApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/teacher/lessons`,
        method: 'POST',
        body: queryArg.teacherLessonCreateRequest,
      }),
    }),
    teacherListLesson: build.query<TeacherListLessonApiResponse, TeacherListLessonApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/teacher/lessons`,
        params: { teacher_id: queryArg.teacherId, language_code: queryArg.languageCode },
      }),
    }),
    teacherUpdateLesson: build.mutation<TeacherUpdateLessonApiResponse, TeacherUpdateLessonApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/teacher/lessons/${queryArg.lessonId}`,
        method: 'PUT',
        body: queryArg.teacherLessonUpdateRequest,
      }),
    }),
    teacherGetLesson: build.query<TeacherGetLessonApiResponse, TeacherGetLessonApiArg>({
      query: (queryArg) => ({ url: `/api/v1/extensions/teacher/lessons/${queryArg.lessonId}` }),
    }),
    teacherGetTeacherAvailabilities: build.query<
      TeacherGetTeacherAvailabilitiesApiResponse,
      TeacherGetTeacherAvailabilitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/teacher/teacher/availability/${queryArg.userId}`,
      }),
    }),
    teacherGetCategories: build.query<TeacherGetCategoriesApiResponse, TeacherGetCategoriesApiArg>({
      query: () => ({ url: `/api/v1/extensions/teacher/categories` }),
    }),
    teacherCreateGroupLesson: build.mutation<
      TeacherCreateGroupLessonApiResponse,
      TeacherCreateGroupLessonApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/teacher/teacher/group_lesson`,
        method: 'POST',
        body: queryArg.createGroupLessonRequest,
      }),
    }),
    teacherGetGroupLessons: build.query<
      TeacherGetGroupLessonsApiResponse,
      TeacherGetGroupLessonsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/teacher/teacher/group_lesson`,
        params: {
          teacher_id: queryArg.teacherId,
          language_code: queryArg.languageCode,
          start_time: queryArg.startTime,
          end_time: queryArg.endTime,
          offset: queryArg.offset,
          limit: queryArg.limit,
          is_upcoming: queryArg.isUpcoming,
          max_reserved_seats: queryArg.maxReservedSeats,
          min_reserved_seats: queryArg.minReservedSeats,
          max_price: queryArg.maxPrice,
          min_price: queryArg.minPrice,
        },
      }),
    }),
    teacherUpdateGroupLesson: build.mutation<
      TeacherUpdateGroupLessonApiResponse,
      TeacherUpdateGroupLessonApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/teacher/teacher/group_lesson/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateGroupLessonRequest,
      }),
    }),
    teacherGetGroupLesson: build.query<
      TeacherGetGroupLessonApiResponse,
      TeacherGetGroupLessonApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/teacher/teacher/group_lesson/${queryArg.id}`,
      }),
    }),
    teacherCreateGroupLessonBooking: build.mutation<
      TeacherCreateGroupLessonBookingApiResponse,
      TeacherCreateGroupLessonBookingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/teacher/teacher/group_lesson_booking`,
        method: 'POST',
        body: queryArg.bookGroupLessonRequest,
      }),
    }),
    teacherListGroupLessonBookings: build.query<
      TeacherListGroupLessonBookingsApiResponse,
      TeacherListGroupLessonBookingsApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/teacher/teacher/group_lesson_bookings` }),
    }),
    teacherDeleteGroupLessonBooking: build.mutation<
      TeacherDeleteGroupLessonBookingApiResponse,
      TeacherDeleteGroupLessonBookingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/teacher/teacher/group_lesson_bookings/${queryArg.bookId}`,
        method: 'DELETE',
      }),
    }),
    teacherUpdateTeacherHomepage: build.mutation<
      TeacherUpdateTeacherHomepageApiResponse,
      TeacherUpdateTeacherHomepageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/teacher/teacher/homepage`,
        method: 'PUT',
        body: queryArg.teacherUpdateHomepageRequest,
      }),
    }),
    teacherCreateTeacherHomepage: build.mutation<
      TeacherCreateTeacherHomepageApiResponse,
      TeacherCreateTeacherHomepageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/teacher/teacher/homepage`,
        method: 'POST',
        body: queryArg.createTeacherHomepageRequest,
      }),
    }),
    teacherGetTeacherHomepage: build.query<
      TeacherGetTeacherHomepageApiResponse,
      TeacherGetTeacherHomepageApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/teacher/teacher/get_homepage` }),
    }),
    teacherGetTeacherHomepageByUrl: build.query<
      TeacherGetTeacherHomepageByUrlApiResponse,
      TeacherGetTeacherHomepageByUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/teacher/teacher/get_homepage_by_url/${queryArg.url}`,
      }),
    }),
    teacherCloseTeacherHomepage: build.mutation<
      TeacherCloseTeacherHomepageApiResponse,
      TeacherCloseTeacherHomepageApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/teacher/teacher/close_homepage`, method: 'POST' }),
    }),
    teacherCheckHomepageUrlAvailability: build.mutation<
      TeacherCheckHomepageUrlAvailabilityApiResponse,
      TeacherCheckHomepageUrlAvailabilityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/teacher/teacher/check_url_availability`,
        method: 'POST',
        body: queryArg.teacherHomepageUrlAvailabilityRequest,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type TeacherFilterTeachersApiResponse =
  /** status 200 Successful Response */ ListTeacherProfileWithLessonsResponse
export type TeacherFilterTeachersApiArg = {
  query?: string | null
  priceFrom?: number | null
  priceTo?: number | null
  languages?: string[]
  orderBy?: string | null
  offset?: number | null
  dateFrom?: string | null
  dateTo?: string | null
  lessonDuration?: number[]
  teacherFrom?: string | null
  isLocal?: boolean | null
  isPro?: boolean | null
  isPartner?: boolean | null
  limit?: number
  isReviewer?: boolean | null
}
export type TeacherCreateLessonApiResponse =
  /** status 201 Successful Response */ TeacherLessonCreateResponse
export type TeacherCreateLessonApiArg = {
  teacherLessonCreateRequest: TeacherLessonCreateRequest
}
export type TeacherListLessonApiResponse =
  /** status 200 Successful Response */ TeacherLessonGetResponse[]
export type TeacherListLessonApiArg = {
  teacherId: number
  languageCode?: string | null
}
export type TeacherUpdateLessonApiResponse =
  /** status 200 Successful Response */ TeacherLessonUpdateResponse
export type TeacherUpdateLessonApiArg = {
  lessonId: number
  teacherLessonUpdateRequest: TeacherLessonUpdateRequest
}
export type TeacherGetLessonApiResponse =
  /** status 200 Successful Response */ TeacherLessonGetResponse
export type TeacherGetLessonApiArg = {
  lessonId: number
}
export type TeacherGetTeacherAvailabilitiesApiResponse =
  /** status 200 Successful Response */ GetTeacherAvailabilityResponse[]
export type TeacherGetTeacherAvailabilitiesApiArg = {
  userId: number
}
export type TeacherGetCategoriesApiResponse = /** status 200 Successful Response */ any[]
export type TeacherGetCategoriesApiArg = void
export type TeacherCreateGroupLessonApiResponse =
  /** status 201 Successful Response */ CreateGroupLessonResponse
export type TeacherCreateGroupLessonApiArg = {
  createGroupLessonRequest: CreateGroupLessonRequest
}
export type TeacherGetGroupLessonsApiResponse =
  /** status 200 Successful Response */ ListGroupLessonResponse
export type TeacherGetGroupLessonsApiArg = {
  teacherId?: number | null
  languageCode?: string | null
  startTime?: string | null
  endTime?: string | null
  offset?: number
  limit?: number
  isUpcoming?: boolean | null
  maxReservedSeats?: number | null
  minReservedSeats?: number | null
  maxPrice?: number | null
  minPrice?: number | null
}
export type TeacherUpdateGroupLessonApiResponse = /** status 200 Successful Response */ any
export type TeacherUpdateGroupLessonApiArg = {
  id: number
  updateGroupLessonRequest: UpdateGroupLessonRequest
}
export type TeacherGetGroupLessonApiResponse =
  /** status 200 Successful Response */ GroupLessonGetResponse
export type TeacherGetGroupLessonApiArg = {
  id: number
}
export type TeacherCreateGroupLessonBookingApiResponse = /** status 200 Successful Response */ any
export type TeacherCreateGroupLessonBookingApiArg = {
  bookGroupLessonRequest: BookGroupLessonRequest
}
export type TeacherListGroupLessonBookingsApiResponse =
  /** status 200 Successful Response */ GroupLessonsBookingsResponse[]
export type TeacherListGroupLessonBookingsApiArg = void
export type TeacherDeleteGroupLessonBookingApiResponse = /** status 200 Successful Response */ any
export type TeacherDeleteGroupLessonBookingApiArg = {
  bookId: number
}
export type TeacherUpdateTeacherHomepageApiResponse = /** status 200 Successful Response */ any
export type TeacherUpdateTeacherHomepageApiArg = {
  teacherUpdateHomepageRequest: TeacherUpdateHomepageRequest
}
export type TeacherCreateTeacherHomepageApiResponse = /** status 200 Successful Response */ any
export type TeacherCreateTeacherHomepageApiArg = {
  createTeacherHomepageRequest: CreateTeacherHomepageRequest
}
export type TeacherGetTeacherHomepageApiResponse =
  /** status 200 Successful Response */ TeacherHomepageGetResponse
export type TeacherGetTeacherHomepageApiArg = void
export type TeacherGetTeacherHomepageByUrlApiResponse =
  /** status 200 Successful Response */ TeacherHomepageGetResponse
export type TeacherGetTeacherHomepageByUrlApiArg = {
  url: string
}
export type TeacherCloseTeacherHomepageApiResponse = /** status 200 Successful Response */ any
export type TeacherCloseTeacherHomepageApiArg = void
export type TeacherCheckHomepageUrlAvailabilityApiResponse =
  /** status 200 Successful Response */ TeacherHomepageUrlAvailabilityResponse
export type TeacherCheckHomepageUrlAvailabilityApiArg = {
  teacherHomepageUrlAvailabilityRequest: TeacherHomepageUrlAvailabilityRequest
}
export type UserStatus = 'STUDENT' | 'TEACHER'
export type UserAddressResponse = {
  country?: string | null
  city?: string | null
  state?: string | null
  zip_code?: string | null
}
export type LanguageExperience = 0 | 1 | 2 | 3 | 4
export type LanguageProficiency = {
  language: string
  proficiency: LanguageExperience
}
export type TeacherProfileRatingResponse = {
  rating_average: number
  reviews_count: number
}
export type TeacherProfileResponse = {
  bio?: string | null
  experience?: string | null
  tags?: string[] | null
  video_link?: string | null
  teaching_style?: string | null
  price_per_hour?: number | null
  partner?: boolean | null
  is_reviewer?: boolean | null
}
export type TeacherProfileWithLessonsResponse = {
  id?: number | null
  username: string
  email?: string | null
  bio?: string | null
  image?: string | null
  full_name?: string | null
  date_of_birth?: string | null
  status?: UserStatus
  address?: UserAddressResponse | null
  is_pro?: boolean
  learning?: LanguageProficiency[] | null
  speaking?: LanguageProficiency[] | null
  rating_summary: TeacherProfileRatingResponse
  profile?: TeacherProfileResponse | null
}
export type ListTeacherProfileWithLessonsResponse = {
  data: TeacherProfileWithLessonsResponse[]
  count: number
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type TeacherLessonCreateResponseNested = {
  id: number
  duration: number
  number_of_lessons: number
  price_per_lesson: number
  total: number
}
export type TeacherLessonCreateResponse = {
  id: number
  title: string
  description: string
  language_level_from: string
  language_level_to: string
  student_tag: string
  category: string
  language_id: number
  packages: TeacherLessonCreateResponseNested[]
}
export type LessonPackageCreateRequestNested = {
  duration: number
  number_of_lessons: number
  price_per_lesson: number
  total: number
}
export type TeacherLessonCreateRequest = {
  title: string
  description: string
  language_level_from: string
  language_level_to: string
  student_tag: string
  category: string
  language_code: string
  packages: LessonPackageCreateRequestNested[]
}
export type TeacherLessonGetResponse = {
  id: number
  title: string
  description: string
  language_level_from: string
  language_level_to: string
  student_tag: string
  category: string
  language_id: number
  packages: TeacherLessonCreateResponseNested[]
}
export type TeacherLessonUpdateResponse = {
  id: number
  title: string
  description: string
  language_level_from: string
  language_level_to: string
  student_tag: string
  category: string
  language_id: number
}
export type TeacherLessonUpdateRequest = {
  title?: string | null
  description?: string | null
  language_level_from?: string | null
  language_level_to?: string | null
  student_tag?: string | null
  category?: string | null
  language_code?: string | null
}
export type GetTeacherAvailabilityResponse = {
  user_id: number
  start_time: string
  end_time: string
}
export type GroupLessonDateResponse = {
  id: number
  start_time: string
  end_time?: string | null
  event_id: number
  event_uuid: string
  is_live: boolean
}
export type CreateGroupLessonResponse = {
  id: number
  title: string
  description: string
  tags: string[]
  language_code: string
  class_type: string
  price_with_cash: number
  max_students: number
  reserved_seats?: number
  video_link?: string | null
  thumbnail_link?: string | null
  is_private?: boolean | null
  show_profile_picture?: boolean | null
  dates: GroupLessonDateResponse[]
}
export type GroupLessonEnum = 'SINGLE_LESSON' | 'ONE_TO_ONE'
export type LanguageLevelEnum = 'ANY_LEVEL' | 'BEGINNER' | 'INTERMEDIATE' | 'ADVANCED'
export type CreateGroupLessonRequest = {
  title: string
  description: string
  class_type: GroupLessonEnum
  tags: string[]
  language_code: string
  price_with_cash: number
  max_students: number
  reserved_seats: number
  video_link?: string | null
  thumbnail_link?: string | null
  start_time: string
  weeks?: number
  repeat_days?: number[]
  is_private?: boolean | null
  show_profile_picture?: boolean | null
  lesson_rules?: string | null
  language_level?: LanguageLevelEnum[]
}
export type GroupLessonGetResponse = {
  id: number
  title: string
  description: string
  tags: string[]
  class_type: string
  language_code: string
  price_with_cash: number
  max_students: number
  reserved_seats?: number
  video_link?: string | null
  thumbnail_link?: string | null
  user_id: number
  is_private?: boolean | null
  show_profile_picture?: boolean | null
  dates: GroupLessonDateResponse[]
}
export type ListGroupLessonResponse = {
  data: GroupLessonGetResponse[]
  count: number
}
export type UpdateGroupLessonRequest = {
  title?: string | null
  description?: string | null
  tags?: string[] | null
  video_link?: string | null
  thumbnail_link?: string | null
  show_profile_picture?: boolean | null
}
export type BookGroupLessonRequest = {
  group_lesson_date_id: number
}
export type GroupLessonsBookingsResponse = {
  id: number
  user_id: number
  group_lesson_date_id: number
  created_at: string
}
export type Feature = {
  title: string
  description: string
  url: string
  is_video?: boolean
}
export type TeacherUpdateHomepageRequest = {
  name?: string | null
  url?: string | null
  profile_pic?: string | null
  thumbnail?: string | null
  video_url?: string | null
  welcome_message?: string | null
  price?: number | null
  currency?: string | null
  visible_prices?: number[] | null
  expected_days?: number | null
  color?: string | null
  faq_color?: string | null
  features?: Feature[] | null
  ai_price?: number | null
  partner?: boolean | null
}
export type CreateTeacherHomepageRequest = {
  name: string
  url: string
  profile_pic?: string | null
  thumbnail?: string | null
  video_url?: string | null
  welcome_message?: string | null
  price?: number | null
  currency?: string | null
  visible_prices?: number[] | null
  expected_days?: number | null
  color?: string | null
  faq_color?: string | null
  features?: Feature[] | null
}
export type TeacherHomepageGetResponse = {
  id?: number | null
  name?: string | null
  url?: string | null
  profile_pic?: string | null
  thumbnail?: string | null
  video_url?: string | null
  welcome_message?: string | null
  price?: number | null
  currency?: string | null
  visible_prices?: number[] | null
  expected_days?: number | null
  max_subscribers?: number | null
  user_id?: number | null
  color?: string | null
  faq_color?: string | null
  features?: Feature[] | null
  ai_price?: number | null
  partner?: boolean | null
}
export type TeacherHomepageUrlAvailabilityResponse = {
  is_available: boolean
}
export type TeacherHomepageUrlAvailabilityRequest = {
  url: string
}
export const {
  useTeacherFilterTeachersQuery,
  useLazyTeacherFilterTeachersQuery,
  useTeacherCreateLessonMutation,
  useTeacherListLessonQuery,
  useLazyTeacherListLessonQuery,
  useTeacherUpdateLessonMutation,
  useTeacherGetLessonQuery,
  useLazyTeacherGetLessonQuery,
  useTeacherGetTeacherAvailabilitiesQuery,
  useLazyTeacherGetTeacherAvailabilitiesQuery,
  useTeacherGetCategoriesQuery,
  useLazyTeacherGetCategoriesQuery,
  useTeacherCreateGroupLessonMutation,
  useTeacherGetGroupLessonsQuery,
  useLazyTeacherGetGroupLessonsQuery,
  useTeacherUpdateGroupLessonMutation,
  useTeacherGetGroupLessonQuery,
  useLazyTeacherGetGroupLessonQuery,
  useTeacherCreateGroupLessonBookingMutation,
  useTeacherListGroupLessonBookingsQuery,
  useLazyTeacherListGroupLessonBookingsQuery,
  useTeacherDeleteGroupLessonBookingMutation,
  useTeacherUpdateTeacherHomepageMutation,
  useTeacherCreateTeacherHomepageMutation,
  useTeacherGetTeacherHomepageQuery,
  useLazyTeacherGetTeacherHomepageQuery,
  useTeacherGetTeacherHomepageByUrlQuery,
  useLazyTeacherGetTeacherHomepageByUrlQuery,
  useTeacherCloseTeacherHomepageMutation,
  useTeacherCheckHomepageUrlAvailabilityMutation,
} = injectedRtkApi
