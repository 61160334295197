import { Box, Button, Modal, Typography } from '@common/components'
import { TimeIcon } from '@common/icons'

const SoldOutModal = (props) => {
  const { open, onClose, teacher_name } = props
  const handleClick = () => {
    window.open('https://airtable.com/appLt40pqPhL2gIkf/shrf2oe9mugP1SYjb', '_blank')
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ padding: '20px' }}>
        <TimeIcon />
        <Typography sx={{ fontWeight: '600', marginTop: '10px' }}>SOLD OUT!</Typography>
        <Typography sx={{ marginTop: '5px' }}>
          {`Please take 2 minutes to join the waitlist to receive updates on the status of membership with ${teacher_name}.`}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            paddingLeft: '20px',
            paddingRight: '20px',
            marginTop: '20px',
          }}
        >
          <Button variant='outlined' sx={{ width: '150px' }} onClick={onClose}>
            Maybe Later
          </Button>
          <Button variant='contained' sx={{ width: '150px' }} onClick={handleClick}>
            Join the Waitlist
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default SoldOutModal
