import { defer } from '@lib/router'
import { modulesApi } from '@app/services/modules'
import { store } from '@app/store'

const languagesLoader = async () => {
  const languages = promise()
  return defer({ languages })
}

const promise = async () => {
  const result = await Promise.all([
    store.dispatch(
      modulesApi.endpoints.modulesGetModuleByModuleId.initiate({
        moduleId: 'Languages',
      }),
    ),
  ])

  // then get the agora RTC + RTM (in parralel)

  return result
}

export default languagesLoader
