import { Box } from '@common/components'
import { CssBaseline, ThemeProvider, GlobalStyles } from '@common/utils/styles'
import { useTheme } from '@features/theme/theme.hooks'
import { Outlet } from '@lib/router'
import darkTheme from '@styles/themes/dark-theme'
import lightTheme from '@styles/themes/light-theme'

const EventLayout: React.FC = () => {
  const { isDark } = useTheme()

  return (
    <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
      <GlobalStyles
        styles={{
          body: {
            overflowY: 'scroll',
          },
        }}
      />
      <CssBaseline />
      <Box width='100%' height='100vh'>
        <Outlet />
      </Box>
    </ThemeProvider>
  )
}

export default EventLayout
