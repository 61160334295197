import React from 'react'
import { FormControlLabel as MuiFormControlLabel, useRadioGroup } from '@mui/material'
import { FormControlCard } from './form-control-label.styles'

const FormControlLabel = (props) => {
  const { card, ...restProps } = props
  const radioGroup = useRadioGroup()
  let checked = false
  if (radioGroup) {
    checked = radioGroup.value === props.value
  }
  if (card) {
    return <FormControlCard labelPlacement='start' checked={checked} {...restProps} />
  }
  return <MuiFormControlLabel {...restProps} />
}

export default FormControlLabel
