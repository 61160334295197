import {
  Box,
  Fade,
  Grow,
  Modal as MuiModal,
  Slide,
  Zoom,
  PaperProps,
  ModalProps as MuiModalProps,
  Backdrop,
  IconButton,
} from '@mui/material'
import { TransitionProps as MuiTransitionProps } from '@mui/material/transitions'
import CloseIcon from '@mui/icons-material/Close'
import { CloseButton, Container } from './modal.styles'
import React from 'react'

export type ModalProps = MuiModalProps & {
  paperProps?: PaperProps
  showCloseBtn?: boolean
  transition?: string | undefined
  onClose: React.MouseEventHandler<HTMLButtonElement>
}

const Modal: React.FC<ModalProps> = (props) => {
  const {
    open,
    onClose,
    children,
    paperProps,
    showCloseBtn = true,
    transition = 'fade',
    ...restProps
  } = props
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...restProps}
    >
      <Transition transition={transition} in={open}>
        <Container elevation={5} {...paperProps}>
          {showCloseBtn && (
            <IconButton
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                fontSize: 'small',
                zIndex: 10,
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
          {children}
        </Container>
      </Transition>
    </MuiModal>
  )
}

interface TransitionProps extends MuiTransitionProps {
  transition: string | undefined
  children: React.ReactNode
  in: boolean
}

const Transition = React.forwardRef<HTMLDivElement, TransitionProps>((props, ref) => {
  const { transition, children, in: inProp, ...restProps } = props
  if (transition === 'fade') {
    return (
      <Fade ref={ref} in={inProp} {...restProps}>
        <Box sx={{ width: '100%', height: '100%' }}>{children}</Box>
      </Fade>
    )
  }
  if (transition === 'grow') {
    return (
      <Grow ref={ref} in={inProp} {...restProps}>
        <Box sx={{ width: '100%', height: '100%' }}>{children}</Box>
      </Grow>
    )
  }
  if (transition === 'zoom') {
    return (
      <Zoom ref={ref} in={inProp} {...restProps}>
        <Box sx={{ width: '100%', height: '100%' }}>{children}</Box>
      </Zoom>
    )
  }
  if (transition === 'slide') {
    return (
      <Slide ref={ref} direction='up' in={inProp} {...restProps}>
        <Box sx={{ width: '100%', height: '100%' }}>{children}</Box>
      </Slide>
    )
  }
  return <Box sx={{ width: '100%', height: '100%' }}> {children}</Box>
})

Modal.displayName = 'Modal'
Transition.displayName = 'Transition'

export default Modal
export * from '@mui/material/Modal'
