import { Box, Container, Grid } from '@common/components'
import { styled } from '@common/utils/styles'
import CenterContainer from './center-container'

interface AuthContainerProps {
  children: React.ReactNode
  illustration?: string
}

const AuthContainer: React.FC<AuthContainerProps> = (props) => {
  const { children, illustration } = props

  if (illustration === undefined) {
    return <CenterContainer>{children}</CenterContainer>
  }

  return (
    <Container maxWidth='lg'>
      <Grid container sx={{ height: '100vh' }}>
        <GridItem item xs={12} md={6} sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Box
            component={'img'}
            src={illustration}
            sx={{
              width: '100%',
              maxWidth: { xs: '200px', md: '350px' },
            }}
          />
        </GridItem>
        <GridItem item xs={12} md={6}>
          {children}
        </GridItem>
      </Grid>
    </Container>
  )
}

const GridItem = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export default AuthContainer
