import * as React from 'react'
const TimeIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} fill='none' {...props}>
    <path
      fill='#D92D20'
      d='M21.75 10.875a10.875 10.875 0 0 1-21.75 0 .906.906 0 1 1 1.813 0 9.063 9.063 0 1 0 9.062-9.063.906.906 0 1 1 0-1.812A10.887 10.887 0 0 1 21.75 10.875ZM9.314 9.969H7.25a.906.906 0 1 0 0 1.812h2.064a1.807 1.807 0 1 0 2.467-2.467v-2.97a.906.906 0 1 0-1.812 0v2.97a1.813 1.813 0 0 0-.655.655ZM1.656 7.96a.906.906 0 1 0 0-1.813.906.906 0 0 0 0 1.813Zm2.17-3.242a.906.906 0 1 0 0-1.813.906.906 0 0 0 0 1.813ZM7.05 2.575a.906.906 0 1 0 0-1.813.906.906 0 0 0 0 1.813Z'
    />
  </svg>
)
export default TimeIcon
