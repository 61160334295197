import { Box, styled } from '@mui/material'

export const Bar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'background',
})(({ theme, background }) => ({
  width: '6px',
  height: '100%',
  backgroundColor: background ? theme.palette.primary.main : 'rgb(230,230,230)',
  boxShadow: theme.shadows[4],
  borderRadius: '3px',
}))
