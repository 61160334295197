import { styled } from '@common/utils/styles'
import { Box } from '@common/components'

export const TaskContainer = styled(Box)(({ theme }) => ({
  padding: '8px',
  overflowY: 'auto',
  fontSize: '12px',
  color: '#000',
  width: '100%',
  height: '350px',
  borderRadius: '10px',
  '::-webkit-scrollbar': {
    height: '10px',
    width: '6px',
    backgroundColor: theme.palette.background.paper,
  },
  '::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(80, 80, 80, 0.1)',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '5px',
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
  },
}))

export const PlayerWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'direction',
})(({ direction }) => ({
  borderRadius: '10px',
  overflow: 'hidden',
  position: 'relative',
  marginRight: '10px',
  width: '100%',
  aspectRatio: direction === 'vertical' ? '2/3' : '16/9',
  maxWidth: direction === 'vertical' ? '200px' : '550px',
}))
