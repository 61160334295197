import { CenterContainer } from '@common/containers'
import { Skeleton } from '@common/components'

const BubblePlaceholder = () => {
  return (
    <CenterContainer>
      <Skeleton variant='rectangular' height={300} sx={{ width: '100%', maxWidth: 550 }} />
    </CenterContainer>
  )
}

export default BubblePlaceholder
