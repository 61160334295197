import React from 'react'
import { TaskContainer } from './task.styles'
import { Box, Typography } from '@common/components'
import dayjs from 'dayjs'
import parse from 'html-react-parser'
import { isUNSW } from '@utils'

const Task = (props: any) => {
  const { note } = props
  return (
    <TaskContainer
      sx={{
        background: note?.color ?? '#FFF0C8',
        flex: 1,
      }}
    >
      <Box sx={{ flex: 1 }}>
        {note?.questions.map((question, index) => (
          <Typography key={index} variant='body2'>
            {parse(question.text)}
          </Typography>
        ))}
      </Box>
      {!isUNSW() ? (
        <Typography
          textAlign='end'
          variant='caption'
          color='text.secondary'
          sx={{ position: 'absolute', bottom: 10, right: 10, color: 'rgba(0, 0, 0, 0.6)' }}
        >
          {dayjs(note.created_at).format('DD-MM-YY')}
        </Typography>
      ) : null}
    </TaskContainer>
  )
}

export default Task
