import { RootState } from '@app/store'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  room: null,
}

const whiteboardSlice = createSlice({
  name: 'whiteboard',
  initialState,
  reducers: {
    setRoom: (state, action) => {
      state.room = action.payload
    },
  },
})

export const { setRoom } = whiteboardSlice.actions

export const selectRoom = (state: RootState) => state.whiteboard.room

export default whiteboardSlice.reducer
