import { styled, Typography, Box, Paper } from '@mui/material'

export const ChatContainer = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'fullScreen',
})(({ fullScreen }) => ({
  borderRadius: fullScreen ? 0 : '10px 0 0 10px',
  padding: '10px 6px',
  width: fullScreen ? '100%' : '300px',
  height: fullScreen ? '100%' : '400px',
  display: 'flex',
  flexDirection: 'column',
}))

export const ChatHistory = styled(Box)({
  padding: '0 8px',
  overflowY: 'scroll',
  flex: 1,
})

export const ChatMessageContainer = styled(Box)(({ position }) => ({
  display: 'flex',
  // alignItems: 'center',
  justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
}))

export const MessageTypoContainer = styled(Box)(({ backgroundColor }) => ({
  backgroundColor,
  width: '170px',
  borderRadius: '5px',
  padding: '8px 4px',
  display: 'block',
  height: '100%',
}))

export const MessageTypo = styled(Typography)({
  color: 'white',
  fontWeight: 400,
  wordBreak: 'break-word',
})

export const LoadingOrErrorContainer = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
})
