import React from 'react'

type ObjectType = {
  [key: string]: number
}

interface transcriptionContextType {
  parentRef: React.MutableRefObject<null> | null
  element: ObjectType
  setElements: () => void
}

const transcriptionContext = React.createContext<transcriptionContextType | null>({
  parentRef: null,
  element: {},
  setElements: () => {},
})

export default transcriptionContext
