import { Button as ButtonMui } from '@common/components'
import { styled } from '@common/utils/styles'

export const FlotingButton = styled(ButtonMui, {
  shouldForwardProp: (prop) => prop !== 'highlighted',
})(({ highlighted }) => ({
  textTransform: 'none',
  margin: '0 auto',
  display: 'flex',
  width: '200px',
  maxWidth: '100%',
  fontWeight: 'bold',
  padding: '10px 18px',
  fontSize: '16px',
  borderRadius: '30px',
  color: 'black',
  background: highlighted ? '#FED363' : 'white',
  transition: 'all .2s ease-in-out',
  transform: 'translate(5px, -5px)',
  border: 'none',
  boxShadow: highlighted
    ? 'inset 0px 0px 0px 0px #665428, -4px 4px 0 0 #665428'
    : 'inset 0px 0px 0px 1px #002765, -4px 4px 0 0 #002765',
  ':hover ': {
    transform: 'translate(0px, 0px)',
    boxShadow: 'inset 0px 0px 0px 0px #002765, 0px 0px 3px 0 #002765',
    background: '#528be7',
  },
}))
