import styled from '@emotion/styled'
import { Box, LinearProgress, linearProgressClasses } from '@mui/material'

export const StyledLinearProgress = styled(LinearProgress)(({ value }) => ({
  height: 20,
  backgroundColor: 'transparent',
  [`& .${linearProgressClasses.bar}`]: {
    transition: '1s',
    borderTopRightRadius: value === 100 ? 0 : 10,
    borderBottomRightRadius: value === 100 ? 0 : 10,
  },
}))

export const Container = styled(Box)(({ height }) => ({
  height: '20px',
  width: '100%',
}))
