import { Stack } from '@common/components'
import { styled } from '@mui/material'

export const IconContainer = styled(Stack)(({ theme }) => ({
  width: '35px',
  height: '35px',
  background: theme.palette.mode === 'light' ? '#0061FD1A' : 'rgba(230,230,230,0.1)',
  borderRadius: '50%',
  marginRight: '6px',
}))
