import { FormControlLabel, styled } from '@mui/material'

export const FormControlCard = styled(FormControlLabel)(({ theme, checked }) => ({
  justifyContent: 'space-between',
  background: checked ? theme.palette.primary.main : theme.palette.background.paper,
  color: checked ? theme.palette.primary.contrastText : theme.palette.text.primary,
  borderRadius: '10px',
  boxShadow: theme.shadows[3],
  padding: '15px',
  margin: 0,
}))
