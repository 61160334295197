import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  inventoryFirstTimeOpen: true,
  kickedFromEvent: false,
}

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setInventoryFirstTimeOpen: (state, action) => {
      state.inventoryFirstTimeOpen = action.payload
    },
    setKickedFromEvent: (state, action) => {
      state.kickedFromEvent = action.payload
    },
  },
})

export const { setInventoryFirstTimeOpen, setKickedFromEvent } = inventorySlice.actions

export default inventorySlice.reducer
