import { Container, ContainerProps } from '@common/components'
import { styled } from '@common/utils/styles'

interface ExpandableFormContainerProps extends ContainerProps {
  isMobile?: boolean
  elevation: number
}

export const FormContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<ExpandableFormContainerProps>(({ isMobile, elevation, theme }) => ({
  minHeight: '60vh',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: 10,
  boxShadow: elevation && !isMobile ? theme.shadows[elevation] : theme.shadows[0],
  background:
    elevation && !isMobile
      ? `linear-gradient(180deg, rgba(237, 244, 254,${
          theme.palette.mode === 'dark' ? '0.1' : '0.9'
        }) 0%, rgba(254, 255, 255, 0.0364583) 99.99%, rgba(255, 255, 255, 0) 100%)`
      : 'none',
  padding: elevation && !isMobile ? '30px 25px 30px 33px' : 0,
  textAlign: 'center',
  minWidth: '370px',
}))
