import { styled, SwitchProps, Switch as SwitchMui } from '@mui/material'
import React from 'react'
import { Controller } from '@lib/form'

type ControllerSwitchProps = SwitchProps & {
  control?: any
}

const Switch = styled(SwitchMui)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))

const ControllerSwitch: React.FC<ControllerSwitchProps> = React.forwardRef((props, ref) => {
  const { name, control, ...restProps } = props
  if (Boolean(control) && typeof name === 'string') {
    return (
      <Controller
        name={name}
        control={control}
        render={(args) => {
          return <Switch {...restProps} {...args.field} ref={ref} />
        }}
      />
    )
  }
  return <Switch name={name} {...restProps} ref={ref} />
})

ControllerSwitch.displayName = 'ControllerSwitch'

export default ControllerSwitch
export * from '@mui/material/Switch'
