import { GlobalStyles as GlobalStylesComponent } from '@common/utils/styles'

const GlobalStyles: React.FC = () => (
  <GlobalStylesComponent
    styles={{
      a: {
        color: 'inherit',
        textDecoration: 'none',
      },
    }}
  />
)

export default GlobalStyles
