/* eslint-disable @typescript-eslint/no-empty-function */
import { PublicUserProfile } from '@app/services'
import React from 'react'

interface videoCallContextType {
  mainRoomSettings: { audio: boolean; video: boolean; lock: boolean }
  handleChangeMainSettings: ({
    audio,
    video,
  }: {
    audio: boolean
    video: boolean
    lock: boolean
  }) => void
  openReport: boolean
  handleOpenReport: (userToReport: PublicUserProfile) => void
}

const videoCallContext = React.createContext<videoCallContextType>({
  mainRoomSettings: { audio: true, video: true, lock: false },
  handleChangeMainSettings: () => {},
  openReport: false,
  handleOpenReport: () => {},
})

export default videoCallContext
