import React from 'react'
import { StepsContainer, StepsWrapper, StepTypography } from './Steps.styles'

const Steps = ({ currentStep, numberOfSteps }: { currentStep: number; numberOfSteps: number }) => {
  return (
    <StepsWrapper>
      <StepsContainer>
        {[...Array(numberOfSteps).keys()].map((i) => (
          <StepTypography key={i} active={i === currentStep}>
            {i + 1}
          </StepTypography>
        ))}
      </StepsContainer>
    </StepsWrapper>
  )
}

export { Steps }
